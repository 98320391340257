import React from "react";
import StarIcon from "@mui/icons-material/Star";
import EmergencyIcon from '@mui/icons-material/Emergency';
const ProjectName = ({
  projectName,
  setProjectName,
}: {
  projectName: string;
  setProjectName: (newState: string) => void;
}) => {
  return (
    <div style={{position:'relative'}}>
      {window.location.pathname === "/teammates" ||
      window.location.pathname === "/projects" ? (
        ""
      ) : (
        <div style={{ position: "absolute", left: "-0.7em", top:".5em" , color: "red", fontSize: "30px" }}>
          *
        </div>
      )}
      <div className="">
        <input
          placeholder="Project Name"
          type="text"
          className="search_input1"
          value={projectName}
          onChange={(event) => {
            setProjectName(event.target.value);
          }}
        />
      </div>
    </div>
  );
};
export default ProjectName;
