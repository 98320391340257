import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import Page from "../../components/Page";

const Feedback = () => {
  return (
    <Page>
      <div className="section-feedback">
        <FeedbackForm/>
      </div>
    </Page>
  )  
};

export default Feedback