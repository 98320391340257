import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import Page from "../../components/Page";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ProjectBox from "../../components/Boxs/ProjectBox";
import AddedSkillers from "../../components/InputFields/AddedSkillers";
import AddedSkillersSidebar from "../../components/InputFields/AddedSkillersSiderbar";
import ProjectFilters from "../../components/ProjectFilters/ProjectFilters";
import { projectsAtom } from "./atoms/ProjectAtomGlobal";
import axios from "axios";
import { userProjectsAtom } from "../Projects/atoms/UserProjectAtomGlobal";
import { url } from "../../url";
import { useAtom } from "jotai";
import { isVisible } from "@testing-library/user-event/dist/utils";
import CreatedProjectBox from "../../components/Boxs/CreatedProjectBox";
import Pagination from "@mui/material/Pagination";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from "react-router-dom";
import { useDataFromProjectFilters } from "../../utils/useDataFromProjectFilters";
import { hasMoreProjectsAtom, indexAtom } from "./atoms/ProjectAtomGlobal";

const Projects = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [showSkillers, setShowSkillers] = useState(false);
  const [projects, setProjects] = useAtom<any[]>(projectsAtom);
  const [userProjects, setUserProjects] = useAtom(userProjectsAtom);
  const [userCreatedProjectsIds, setUserCreatedProjectsIds] = useState("");
  const [projectsAdded, setProjectsAdded] = useState<any>({});
  const [loading, setLoading] = useState(true);
  // const [pages, setPages] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  // Infinite scroll 
  const [hasMore, setHasMore] = useAtom<boolean>(hasMoreProjectsAtom);
  const [index, setIndex] = useAtom(indexAtom);
  const [error, setError] = useState(false);
  const query = useDataFromProjectFilters();

  useEffect(() => {
    const fetchData = async () => {
      let projectsRes = null;
      try { 
      projectsRes = await axios.get(
          url + `/project?page=1`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
      );
      } catch (err) {
        setError(true);
        return;
      }
      const projectsArr = projectsRes.data.projects
      setProjects(projectsArr);

      const morePages = projectsRes.data.projectsPages == 1 ? false : true
      if(!morePages){ 
        console.log("No more pages");
        setHasMore(false);
      }
      // setPages(projectsRes.data.projectsPages);

      const token = localStorage.getItem("token");
      if (token) {
        const savedProjects = await axios.get(url + "/user/projects/found", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const savedProjectsArr = savedProjects.data.projects;
        setUserProjects(savedProjectsArr);
        
        projectsArr.forEach((project: any) => {
          if (savedProjectsArr.includes(project._id)){
            setProjectsAdded((prevState: any) => ({
              ...prevState,
              [project._id]: true, 
            }));
          }
          else {
            setProjectsAdded((prevState: any) => ({
             ...prevState,
              [project._id]: false, 
            }));
          }
        });

        

        const userCreatedProjects = await axios.get(
          url + "/user/myProjectsIds",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setUserCreatedProjectsIds(userCreatedProjects.data.myProjectsIds);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const fetchMoreProjects = async () => {
    await axios.get(url + `/project/?page=${index}&${query}`)
    .then((res) => {
      const projectsArr = res.data.projects;
      setProjects((prevProjects: any[]) => [...prevProjects, ...projectsArr]);

      projectsArr.forEach((project: any) => {
        if (userProjects.includes(project._id)){
          setProjectsAdded((prevState: any) => ({
            ...prevState,
            [project._id]: true, 
          }));
        }
        else {
          setProjectsAdded((prevState: any) => ({
           ...prevState,
            [project._id]: false, 
          }));
        }
      });

      projectsArr.length > 0 ? setHasMore(true) : setHasMore(false);
    }).catch((err) => console.error(err));

    setIndex((prevIndex) => prevIndex + 1)
  }

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  const handleShowFilters = () => {
    if (showFilters === false) {
      setShowFilters(true);
    } else {
      setShowFilters(false);
    }
  };
  const handleShowSkillers = () => {
    if (showSkillers === false) {
      setShowSkillers(true);
    } else {
      setShowSkillers(false);
    }
  };

  const tokenAvailable = () => {
    const token = localStorage.getItem("token");
    if (token) {
      return true;
    }
    return false;
  };

  const addUserProject = async (project: any): Promise<void> => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await axios.post(
          url + `/user/projects/${project._id}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setUserProjects([...userProjects, project._id]);
      } catch (err) {
        console.log(err);
      }
    }
    setProjectsAdded((prevState: any) => ({
      ...prevState,
       [project._id]: true, 
     }));
  };

  const deleteUserProject = async (project: any): Promise<void> => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await axios.delete(url + `/user/projects/${project._id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setUserProjects(
          userProjects.filter(function (p: any) {
            return p !== project._id;
          }),
        );
      } catch (err) {
        console.log(err);
      }
    }
    setProjectsAdded((prevState: any) => ({
      ...prevState,
       [project._id]: false, 
     }));
  };
  if (error) {
    return <Navigate to="/error" />
  }
  return (
    <Page>
    <section className='projects'>
      <header className='container projects__header'>
        <div className='projects__header__left'>
        </div>
        <div className='projects__header__right'>
          <div
            className="projects__header__right__skillerButton">
          </div>
          <Button onClick={handleShowFilters}
            variant='contained' size='small'
            className={`projects__header__right__filterButton ${showFilters ? "clicked-button" : ""}`}>
            <FilterAltIcon className='projects__header__right__filterButton__icon' />
            Apply Filter
          </Button>
        </div>
      </header>
      {
        showFilters ? <ProjectFilters/> : null
      }
      <div className='projects__body'>
        <div className='projects__body__filterTabs'>
          {/* {showFilters &&
            <ProjectFilterTabs index={0} />
          } */}
        </div>
        
        <div className='projects__body__container'>
        
          <div className='projects__body__container__left'> 
            <InfiniteScroll
              dataLength={projects.length}
              next={fetchMoreProjects}
              hasMore={hasMore}
              loader={<CircularProgress />}
            >
            {projects.map((project: any)=>{
              if (userCreatedProjectsIds.includes(project._id)) {
                return (
                  <ProjectBox 
                  key={project._id} 
                  project={project} 
                  tokenAvailable={false}
                  loading={loading} 
                  myProject={true}
                  />
                );
              } else {
              return (<ProjectBox 
                key={project._id}
                project={project}
                tokenAvailable={tokenAvailable()}
                addProject={() => addUserProject(project)}
                deleteProject={() => deleteUserProject(project)}
                projectAdded={projectsAdded[project._id]}
                loading={loading}
                myProject={false}
              />);
            }})}
            </InfiniteScroll>
          </div>
          <div className='projects__body__container__right'>
            {/* <AddedSkillers /> */}
          </div>
          
        </div>
      </div>
    </section >
  </Page >
  );
};

export default Projects;
