import React from 'react';
import Page from '../../components/Page';

const ErrorPage: React.FC = () => {
    return (
        <Page>
            <div className="error-page">
                <div className="error-code">404</div>
                <div className="error-message">Oops! Something went wrong.</div>
                <div className="error-description">Issues happened, we are working on it.</div>
                <a href="/" className="back-button">Go to Homepage</a>
            </div>
        </Page>
    );
};

export default ErrorPage;