import { atom } from "jotai";
import tools_tools from "../../../data/projects/enums/projects_tools_tools";

const projectNameAtom = atom("");
projectNameAtom.debugLabel = "Project Name";

const toolsExperienceAtom = atom<string[]>([]);
toolsExperienceAtom.debugLabel = "Tools Experience";

const toolsExperienceOptionViewsAtom = atom(
  tools_tools.reduce((acc: any, option: string) => {
    acc[option] = 0; // Initialize views for each option to 1
    return acc;
  }, {}),
);
toolsExperienceOptionViewsAtom.debugLabel = "Tools Experience Option Views";

const projectTypeAtom = atom([]);
projectTypeAtom.debugLabel = "Project Type";

// const productTypeAtom = atom([]);
// productTypeAtom.debugLabel = "Product Type";

const offeredGratificationAtom = atom([]);
offeredGratificationAtom.debugLabel = "Offered Gratification";

const forGratificationAtom = atom([]);
forGratificationAtom.debugLabel = "For Gratification";

const softSkillsAtom = atom([]);
softSkillsAtom.debugLabel = "Soft Skills";

const requiredAreaOfExpertiseAtom = atom([]);
requiredAreaOfExpertiseAtom.debugLabel = "Required Area of Expertise";

const projectDeadlineAtom = atom(new Date());
projectDeadlineAtom.debugLabel = "Project Deadline";

const hardSkillsAtom = atom([]);
hardSkillsAtom.debugLabel = "Hard Skills";

const offeredWorkingTypeAtomNewArray = atom<string[]>([]);
offeredWorkingTypeAtomNewArray.debugLabel = "Offered Working Type";

const offeredWorkingTypeActiveOptionsAtom = atom<boolean[]>([]);
offeredWorkingTypeActiveOptionsAtom.debugLabel =
  "Offered Working Type Active Options";

const usedLanguagesAtom = atom([]);
usedLanguagesAtom.debugLabel = "Used Languages";

const locationsAtom = atom([]);
locationsAtom.debugLabel = "Locations";

const bizOrTechRoleAtomLeftValue = atom("");
bizOrTechRoleAtomLeftValue.debugLabel = "Required Biz Or Tech Role Left Value";

const bizOrTechRoleAtomRightValue = atom("");
bizOrTechRoleAtomRightValue.debugLabel =
"Required Biz Or Tech Role Right Value";

const requiredAvailabilityAtomStartValue = atom(10);
requiredAvailabilityAtomStartValue.debugLabel =
"Required Availability Start Value";

const requiredAvailabilityAtomEndValue = atom(15);
requiredAvailabilityAtomEndValue.debugLabel = "Required Availability End Value";

const requiredAvailabilityAtomOption = atom("Monthly");
requiredAvailabilityAtomOption.debugLabel = "Required Availability Option";

const descriptionAtom = atom("");
descriptionAtom.debugLabel = "Description";

const pageAtom = atom(0);
pageAtom.debugLabel = "Page";

const openAtom = atom({
  toolsExperience: false,
  projectType: false,
  offeredGratification: false,
  forGratification: false,
  softSkills: false,
  requiredAreaOfExpertise: false,
  projectDeadline: false,
  hardSkills: false,
  offeredWorkingType: false,
  usedLanguages: false,
  bizOrTech: false,
  requiredAvailability: false,
  locations: false,
});
openAtom.debugLabel="Edit Project Open Filters";

export {
    projectNameAtom,
    toolsExperienceAtom,
    toolsExperienceOptionViewsAtom,
    projectTypeAtom,
    offeredGratificationAtom,
    softSkillsAtom,
    requiredAreaOfExpertiseAtom,
    forGratificationAtom,
    projectDeadlineAtom,
    hardSkillsAtom,
    offeredWorkingTypeAtomNewArray,
    offeredWorkingTypeActiveOptionsAtom,
    usedLanguagesAtom,
    bizOrTechRoleAtomLeftValue,
    bizOrTechRoleAtomRightValue,
    requiredAvailabilityAtomStartValue,
    requiredAvailabilityAtomEndValue,
    requiredAvailabilityAtomOption,
    locationsAtom,
    descriptionAtom,
    pageAtom,
    openAtom
  };
