import { atom } from "jotai";

// i dont think deadline is neccessary

const hardSkillsAtom = atom([]);
hardSkillsAtom.debugLabel = "Hard Skills";

const offeredWorkingTypeAtomNewArray = atom([]);
offeredWorkingTypeAtomNewArray.debugLabel = "Offered Working Type";

const offeredWorkingTypeActiveOptionsAtom = atom([]);
offeredWorkingTypeActiveOptionsAtom.debugLabel =
  "Offered Working Type Active Options";

const descriptionAtom = atom("");
descriptionAtom.debugLabel = "Description";

export {
  hardSkillsAtom,
  offeredWorkingTypeAtomNewArray,
  offeredWorkingTypeActiveOptionsAtom,
  descriptionAtom
};
