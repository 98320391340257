import React from "react";
import { useLocation, useNavigate } from "react-router";
import Page from "../../../components/Page";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IconButton } from "@mui/material";
import ProjectCard from "../Components/ProjectCard";
import SkillCard from "../Components/SkillCard";
import AccountCard from "../Components/AccountCard";
import AddProjectCard from "../Components/AddProjectCard";
import AddTeammateCard from "../Components/AddTeammateCard";
import FindProjectCard from "../Components/FindProjectCard";
import FindTeammateCard from "../Components/FindTeammateCard";

const HomeCardsView = () => {
  const location = useLocation();
  let splittedLoc = location.pathname.split("/");
  let title = splittedLoc[splittedLoc.length - 1];

  const navigate = useNavigate();

  return (
    <Page>
      <section className="homeCardsView">
        <header className="homeCardsView__header">
          {title === "profileCompletion" ? (
            <div className="homeCardsView__header__headingAccount">
              <h4>Great job!</h4>
              <span>
                You have just set up profile. Where do you want to go now from
                here?
              </span>
            </div>
          ) : (
            <IconButton
              onClick={() => navigate("/")}
              className="homeCardsView__header__backIcon"
            >
              <KeyboardBackspaceIcon className="homeCardsView__header__backIcon__icon" />
            </IconButton>
          )}
          {/* {title === "Teammate" && (
            <p>
              Great, so you have some Skills or Assets... Look around for open
              Projects or add your profile to be found!
            </p>
          )}
          {title === "Leader" && (
            <p>
              Awesome, so you have a Project or an Idea... Look for your
              Teammates or add your Project to be findable!
            </p>
          )} */}
          {/* {title === "Skiller" &&
            <p>Awesome, so you have an idea... Let's look for your Teammates!</p>
          }
          {title === "Intern" &&
            <p>Awesome, you have an idea!</p>
          } */}
        </header>
        <div className="homeCardsView__container">
          <div className="homeCardsView__container__projectCards">
            {/* {title === "Teammate" &&
              <>
                <SkillCard title={"Find Skills"} />
                <ProjectCard title={"Add Project"} />
              </>
            }
            {title === "Leader" &&
              <>
                <SkillCard title={"Add Skill"} />
                <ProjectCard title={"Find Project"} />
              </>
            } */}
            {title === "Teammate" && (
              <>
                {/* <SkillCard title={"Find Skills"} />
                <ProjectCard title={"Add Project"} /> */}
                <AddTeammateCard
                  title={"Add Teammate"}
                />
                <FindTeammateCard title={"Find Teammates"} />

              </>
            )}
            {title === "Leader" && (
              <>
                {/* <SkillCard title={"Add Skill"} />
                <ProjectCard title={"Find Project"} /> */}
                <AddProjectCard
                  title={"Add Project Profile"}
                />
                <FindProjectCard title={"Find Projects"} />

              </>
            )}
            {/* {title === "Skiller" &&
              <>
                <ProjectCard title={"Add Project"} description={"You can add project here"} />
                <SkillCard title={"Find Skill"} description={"You can find skills here"} />
              </>
            }
            {title === "Intern" &&
              <>
                <ProjectCard title={"Add Project"} description={"You can add project here"} />
                <SkillCard title={"Find Skill"} description={"You can find skills here"} />
              </>
            } */}
            {title === "profileCompletion" && (
              <>
                <ProjectCard
                  title={"Projects"}
                  description={"Explore projects here"}
                />
                <SkillCard
                  title={"Skills"}
                  description={"Explore skills here"}
                />
                <AccountCard
                  title={"My Account"}
                  description={"Go to your account"}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </Page>
  );
};

export default HomeCardsView;
