import { atom } from "jotai";

const projectsAtom = atom<any[]>([]);
projectsAtom.debugLabel = "Projects";

const hasMoreProjectsAtom = atom<boolean>(true);
hasMoreProjectsAtom.debugLabel = "HasMoreProjects";

const indexAtom = atom<number>(2);
indexAtom.debugLabel = "IndexForInfiniteScroll";

export { projectsAtom, hasMoreProjectsAtom, indexAtom };
