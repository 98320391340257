import React from "react";
import StarIcon from '@mui/icons-material/Star';
const TeammateNickname = ({
  teammateNickname,
  setTeammateNickname,
}: {
  teammateNickname: string;
  setTeammateNickname: (newState: string) => void;
}) => {
  return (
    <div style={{position:'relative'}}>
        { window.location.pathname === "/teammates" || window.location.pathname === "/projects" ? "":(
          <div style={{ position: "absolute", left: "-0.7em", top:".5em" , color: "red", fontSize: "30px" }}>
          *
        </div>
          )
        }
      <div className="">
        <input
          placeholder="Nickname"
          type="text"
          className="search_input1"
          value={teammateNickname}
          onChange={(event) => {
            setTeammateNickname(event.target.value);
          }}
          />
      </div>
    </div>
  );
};
export default TeammateNickname;
