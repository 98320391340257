import { useAtom } from "jotai";
import {openAtom} from "../pages/Projects/atoms/AddProjectAtomGlobal";

export const useOpenCloseFiltersAddProject = ()=>{
    const [openFilters,setOpenFilters] = useAtom(openAtom);

    const openCloseFilters = (key: string)=>{
        let tempOpenFilters = openFilters;

        if (openFilters[key as keyof typeof openFilters] == true){
            tempOpenFilters = {
                ...openFilters, 
                [key]: false
            }
        }
        else { 
            for (const key of Object.keys(tempOpenFilters)){ 
                tempOpenFilters[key as keyof typeof openFilters] = false;
            }
            tempOpenFilters = {
                ...tempOpenFilters, 
                [key]: true
            }   
        }
        
        setOpenFilters(tempOpenFilters);
    };

    return openCloseFilters;
};