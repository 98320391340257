import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styles from "./navbar.module.scss";

export default function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<HTMLElement | null>();
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [buttonClicked, setButtonClicked] = useState<string>();

  const [showDropdownProject, setShowDropdownProject] =
    useState<boolean>(false);
  const [showDropdownTeammate, setShowDropdownTeammate] =
    useState<boolean>(false);
  const handleLinkHover = (name: string) => {
    name === "project"
      ? setShowDropdownProject(true)
      : setShowDropdownTeammate(true);
  };

  const handleMouseLeave = (name: string) => {
    name === "project"
      ? setShowDropdownProject(false)
      : setShowDropdownTeammate(false);
  };

  const handleButtonClicked = (linkId: string) => {
    setButtonClicked(linkId);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const tokenAvailable = () => {
    const token = localStorage.getItem("token");
    if (token) {
      return true;
    }
    return false;
  };

  const handleMobileMenuOpen = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const currentPath = window.location.pathname as string;
    setButtonClicked(currentPath);
  }, []);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem className="menuLinks">
        <Link to="/feedback">Feedback</Link>
      </MenuItem>
      <MenuItem className="menuLinks">
        <Link to="/projects">Projects</Link>
      </MenuItem>
      <MenuItem className="menuLinks">
        <Link to="/teammates">Teammates</Link>
      </MenuItem>
      {tokenAvailable() && (
        <MenuItem className="menuLinks">
          <Link to="/conversations">My Account</Link>
        </MenuItem>
      )}
      {!tokenAvailable() && (
        <MenuItem className="menuLinks">
          <Link to="/login">Sign in</Link>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" className="navbar">
          <Toolbar>
            <p onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              Slinge<b>rr</b>
            </p>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{ display: { xs: "none", md: "flex" } }}
              className="navbar__linksBox"
            >
              
              <div className={styles.navLinkBox}>
                <Link
                  style={{
                    fontWeight:
                      buttonClicked === "/projects" ||
                      buttonClicked === "/project/add"
                        ? "bold"
                        : "",
                    padding: "1em",
                    fontSize: "1rem",
                  }}
                  onClick={() => handleButtonClicked("/projects")}
                  to="/projects"
                  onMouseEnter={() => handleLinkHover("project")}
                  onMouseLeave={() => handleMouseLeave("project")}
                >
                  Projects
                  <ArrowDropDownIcon
                    style={{ position: 'absolute',translate: "0 -10%" }}
                  ></ArrowDropDownIcon>
                </Link>
                <div
                  className={styles.dropdown}
                  onMouseEnter={() => handleLinkHover("project")}
                  onMouseLeave={() => handleMouseLeave("project")}
                  style={{ display: showDropdownProject ? "flex" : "none" }}
                >
                  <Link className={styles.dropdown__link} to={"/project/add"}>
                    Add Project
                  </Link>
                  <Link className={styles.dropdown__link} to={"/projects"}>
                    Find Project
                  </Link>
                </div>
              </div>
              <div className={styles.navLinkBox}>
                <Link
                  style={{
                    fontWeight:
                      buttonClicked === "/teammates" ||
                      buttonClicked === "/teammates/add"
                        ? "bold"
                        : "",
                    padding: "1em",
                    fontSize: "1rem",
                  }}
                  onClick={() => handleButtonClicked("/teammates")}
                  to="/teammates"
                  onMouseEnter={() => handleLinkHover("teammate")}
                  onMouseLeave={() => handleMouseLeave("teammate")}
                >
                  Teammates
                  <ArrowDropDownIcon
                    style={{ position: 'absolute', translate: "0 -10%" }}
                  ></ArrowDropDownIcon>
                </Link>
                <div
                  className={styles.dropdown}
                  onMouseEnter={() => handleLinkHover("teammate")}
                  onMouseLeave={() => handleMouseLeave("teammate")}
                  style={{ display: showDropdownTeammate ? "flex" : "none" }}
                >
                  <Link className={styles.dropdown__link} to={"/teammates/add"}>
                    Add Teammate
                  </Link>
                  <Link className={styles.dropdown__link} to={"/teammates"}>
                    Find Teammate
                  </Link>
                </div>
              </div>
              <div className={styles.navLinkBox}>
                <Link
                  style={{
                    fontWeight: buttonClicked === "/feedback" ? "bold" : "",
                    padding: "1em",
                    fontSize: "1rem",
                  }}
                  to="/feedback"
                >
                  Feedback
                </Link>
              </div>
              {tokenAvailable() && (
                <Link
                  style={{
                    fontWeight:
                      buttonClicked === "/conversations" ? "bold" : "",
                  }}
                  onClick={() => handleButtonClicked("/conversations")}
                  to="/conversations"
                >
                  My Account
                </Link>
              )}
              {!tokenAvailable() && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => navigate("/login")}
                >
                  Sign in
                </Button>
              )}
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" }, mr: "-1rem" }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen as () => {}}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    </>
  );
}
