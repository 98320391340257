import React, { useState } from "react";
import "../../sass/components/Filters/ProjectDeadlineFilter.scss";
import "react-calendar/dist/Calendar.css";
import Calender from "../Calender/Calender";
import { Value } from "react-calendar/dist/cjs/shared/types";
export default function ProjectDeadlineFilter({
  dateState,
  setDateState,
  open,
  setOpen
}: {
  dateState: Date;
  setDateState: (newState: Date) => void;
  open: boolean;
  setOpen: (key: string)=>void;
}) {

  // const [open, setOpen] = useState<boolean>(false);
  const [date, setDate] = useState<boolean | null>(null);
  // const [dateState, setDateState] = useState<Date | null>(null);
  const [titleCount, setTitleCount] = useState<number>(0);
  const handleOpen = () => {
    setOpen("projectDeadline");
  };
  const handleCalender = () => {
    setDate(!date);
  };
  const changeDate = (
    e: Value,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setDateState(e as Date);
  };

  const formattedDate = dateState ? (
    new Date(dateState).toLocaleDateString("en-GB")
  ) : (
    <div className="dddddd">
      <small> DD / MM / YYYY</small>
      <p>
        {date ? (
          <i className="fas fa-angle-up"></i>
        ) : (
          <i className="fas fa-angle-down "></i>
        )}
      </p>
    </div>
  );

  return (
    <div>
      <div className="component">
        <div onClick={handleOpen} className="heading">
          <p className="title">
            Estimated Deadline {date && titleCount !== 0 && `(${titleCount})`}
          </p>
          <p>
            {open ? (
              <i className="fas fa-angle-down rotate"></i>
            ) : (
              <i className="fas fa-angle-down"></i>
            )}
          </p>
        </div>
        {open && (
          <div className="show_component" style={{display:"flex", justifyContent:"center"}}>
            <div className="date_container">
              <div onClick={handleCalender} className="date_btn">
                {formattedDate}
              </div>
            </div>
            {date && (
              <div className="calender_design">
                <Calender selectedDate={dateState} handleDateChange={changeDate} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
