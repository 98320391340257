import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface LogoutModalProps {
  buttonClicked: string | number;
  handleButtonClicked: (id: number | string) => void;
}

export default function LogoutModal({
  buttonClicked,
  handleButtonClicked,
}: LogoutModalProps) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = (id: number) => {
    setOpen(true);
    handleButtonClicked(id);
  };
  const handleClose = () => setOpen(false);
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/")
  }
  const navigate = useNavigate();
  return (
    <div>
      <div
        className={`sidebar__links__link ${buttonClicked === 7 ? "sidebar-button-clicked" : ""}`}
        onClick={() => handleOpen(7)}
      >
        <LogoutIcon className="sidebar__links__link__icon" />
        Logout
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logoutModal"
      >
        <Box sx={style} className="logoutModal__container">
          <p className="logoutModal__container__head">Logout</p>
          <div className="logoutModal__container__iconBox">
            <LogoutIcon className="logoutModal__container__iconBox__icon" />
          </div>
          <p className="logoutModal__container__logoutMessage">
            Are you sure you want to logout?
          </p>
          <div className="logoutModal__container__buttonsBox">
            <Button
              variant="outlined"
              className="logoutModal__container__buttonsBox__cancel"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="logoutModal__container__buttonsBox__logout"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
