import { useParams } from "react-router-dom";
import Page from "../../components/Page";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { url } from "../../url";
import { useEffect, useState } from "react";
import styles from "./singleproject.module.scss";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatIcon from "@mui/icons-material/Chat";
import { useAtom, useAtomValue } from "jotai";
import { userProjectsAtom } from "../Projects/atoms/UserProjectAtomGlobal";
import { chosenUserAtom } from "../Conversations/atoms/ChatAtom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import { Check } from "@mui/icons-material";

import "./ProjectBox.scss";
import { socket } from "../../socket/socket";

var displayOptions = 3;

const getData = async (id: string) => {
  const response = await axios.get(url + `/project/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const getSavedData = async (token: string) => {
  const response = await axios.get(url + "/user/projects/found", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const getUserData = async (token: string) => {
  const response = await axios.get(url + "/user/myProjectsIds", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export default function SingleProject() {
  const [data, setData] = useState<any>({});
  const [projectCreator, setProjectCreator] = useState<any>({});
  const [mouseHeartEnter, setMouseHeartEnter] = useState<boolean>(false);
  const [mouseChatEnter, setMouseChatEnter] = useState<boolean>(false);
  const [projectsAdded, setProjectsAdded] = useState<any>({});
  const [userProjects, setUserProjects] = useAtom(userProjectsAtom);
  const [userCreatedProjectsIds, setUserCreatedProjectsIds] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string>("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [chosenUser, setChosenUser] = useAtom(chosenUserAtom);
  const [isPaneOpen, setIsPaneOpen] = useState<boolean>(true); // State for right pane
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const addUserProject = async (project: any): Promise<void> => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await axios.post(
          url + `/user/projects/${project._id}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserProjects([...userProjects, project._id]);
      } catch (err) {
        console.log(err);
      }
    }
    setProjectsAdded((prevState: any) => ({
      ...prevState,
      [project._id]: true,
    }));
  };

  const onChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const onClickSendMessageButtonClick = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axios.post(
          url + `/chat/singlePage/sendMessage`,
          {
            id: projectCreator._id,
            message: message,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;
        socket.emit("join", data.message.conversationId);
        socket.emit("chat", {
          room: data.message.conversationId,
          user: projectCreator._id,
          text: message,
        });
        socket.off("join");
        socket.off("chat");
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3500);
        setMessage("");
        return;
      } catch (err) {
        if (err) {
          return alert("???");
        }
      }
    }
    return alert("You need to be logged lol");
  };

  const deleteUserProject = async (project: any): Promise<void> => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await axios.delete(url + `/user/projects/${project._id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setUserProjects(
          userProjects.filter(function (p: any) {
            return p !== project._id;
          })
        );
      } catch (err) {
        console.log(err);
      }
    }
    setProjectsAdded((prevState: any) => ({
      ...prevState,
      [project._id]: false,
    }));
  };

  const redirectToConversation = (projectCreator: any) => {
    // console.log(projectCreator);
    if (localStorage.getItem("token")) {
      setChosenUser(projectCreator);
      navigate("/conversations");
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (id) {
        const response = await getData(id);
        setData(response.project);
        setProjectCreator(response.project_creator);
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await getUserData(token);
        setUserCreatedProjectsIds(response.myProjectsIds);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await getSavedData(token);
        setUserProjects(response.projects);
      }
    };
    fetchData();
  }, []);

  return (
    <Page>
      {!loading && (
        <div className={styles.pane_container}>
          {/* Main Content */}
          <div className={styles.pane_mainContent}>
            <div className={styles._titles__box}>
              <div className={styles._header__box}>
                <h2 className={styles._box__title}>
                  {data.projectName}
                  {projectCreator.username && (
                    <h6 className={styles._box__creator}>
                      Owner:
                      <span className={styles._usernameWrapper}>
                        <a href="/" className={styles._usernameLink}>
                          {projectCreator.username}
                        </a>
                      </span>
                    </h6>
                  )}
                </h2>
                {!userCreatedProjectsIds.includes(data._id) && (
                  <div className={styles._box__headerBox}>
                    <button
                      className={styles._box__heartBtn}
                      onMouseEnter={() => setMouseHeartEnter(true)}
                      onMouseLeave={() => setMouseHeartEnter(false)}
                      onClick={() => {
                        !userProjects?.includes(data._id)
                          ? addUserProject(data)
                          : deleteUserProject(data);
                      }}
                    >
                      {!userProjects?.includes(data._id) && !mouseHeartEnter ? (
                        <FavoriteBorderIcon
                          className={styles._box__heartIcon}
                        ></FavoriteBorderIcon>
                      ) : (
                        <FavoriteIcon
                          className={styles._box__heartIcon}
                        ></FavoriteIcon>
                      )}
                    </button>
                    <button
                      className={styles._box__chatBtn}
                      onMouseEnter={() => setMouseChatEnter(true)}
                      onClick={() => redirectToConversation(projectCreator)}
                    >
                      <ChatIcon className={styles._box__chatIcon}></ChatIcon>
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className={styles._box}>
              <div className="_projects__body__container__left__card__body">
                {data.toolsExperience?.length !== 0 && (
                  <div className="_projects__body__container__left__card__body__specs yellow_parent feature_box_tools small_none">
                    <div className="_question_parent">
                      <p>Tools</p>
                      {/* <div className="_question_hover">
                        <img className="_question_image" src={question} alt="" />
                        <div className="_yellow_text">
                          <p>Minimum Experience Period</p>
                        </div>
                      </div> */}
                    </div>
                    <div className="_projects__body__container__left__card__body__specs__desc">
                      {/* {project.toolsExperience
                        .sort()
                        .slice(0, displayOptions)
                        ?.map(
                          (obj: { tool: string; mep: number; _id: string }, index: number) => {
                            return (
                              <div key={obj._id} className="_flag_design">
                                <div>
                                  <small key={index}>
                                  +{obj.mep}M {obj.tool}
                                  </small>
                                </div>
                                {/* <div className="_border_right" />
                                <div className="_red_hover_tool">
                                  <img src={flag} alt="" />
                                  <small className="_hover_red_text">
                                    Report Tool
                                  </small>
                                </div> */}
                      {/* </div>
                            );
                          }
                        )} */}
                      {data.toolsExperience
                        ?.sort()
                        .slice(0, data.toolsExperience.length)
                        ?.map(
                          (
                            obj: { tool: string; mep: number; _id: string },
                            index: number
                          ) => (
                            <span className="_flag_design_small" key={index}>
                              +{obj.mep}M {obj.tool}
                            </span>
                          )
                        )}
                    </div>
                    {/* {data.toolsExperience?.length > 3 && (
                    <div className="_dot_container">
                      <div className="_dot_hover">
                        <div className="_dot_image">
                          <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                        </div>
                        <div className="_dot_hover_element">
                          {data.toolsExperience
                            ?.sort()
                            .slice(displayOptions, data.toolsExperience?.length)
                            ?.map(
                              (
                                obj: { tool: string; mep: number },
                                index: number
                              ) => (
                                <small key={index}>
                                  +{obj.mep}M {obj.tool} 
                                </small>
                              )
                            )}
                        </div>
                      </div>
                    </div>)} */}
                  </div>
                )}
                {data.areaOfExpertise?.length !== 0 && (
                  <div className="_projects__body__container__left__card__body__specs feature_box_industry">
                    <div className="_question_parent">
                      <p>Industry</p>
                    </div>
                    <div className="_projects__body__container__left__card__body__specs__desc">
                      {/* <div className="_projects__body__container__left__card__body__specs__desc"> */}
                      {data.areaOfExpertise
                        ?.sort()
                        .slice(0, data.areaOfExpertise?.length)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className="_flag_design_small" key={index}>
                              {i}
                            </span>
                          </>
                        ))}
                      {/* </div> */}
                    </div>

                    {/* {data.areaOfExpertise?.length > 3 && (
                      <div className="_dot_container">
                        <div className="_dot_hover">
                          <div className="_dot_image">
                            <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                          </div>
                          <div className="_dot_hover_element flex_items">
                            {data.areaOfExpertise
                              .slice(displayOptions, data.areaOfExpertise?.length)
                              ?.map((val: string, index: number) => (
                                <small key={index}>{val}</small>
                              ))}
                          </div>
                        </div>
                      </div>
                  )} */}
                  </div>
                )}
                {data.projectType !== 0 && (
                  <div className="_projects__body__container__left__card__body__specs yellow_parent feature_box_project_type small_none">
                    <div className="_question_parent">
                      <p>Project Type</p>
                      {/* <div className="_question_hover">
                        <img className="_question_image1" src={question} alt="" />
                        <div className="_yellow_text1">
                          <p>Project Type Experience</p>
                        </div>
                      </div> */}
                    </div>
                    <div className="_projects__body__container__left__card__body__specs__desc">
                      {data.projectType
                        ?.sort()
                        .slice(0, data.projectType.length)
                        ?.map((val: string, index: number) => (
                          <span className="_flag_design_small" key={index}>
                            {val}
                          </span>
                        ))}
                    </div>
                    {/* {data.projectType?.length > 3 && (
                      <div className="_dot_container">
                        <div className="_dot_hover">
                          <div className="_dot_image">
                            <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                          </div>
                          <div className="_dot_hover_element flex_items">
                            {data.projectType
                              .slice(displayOptions, data.projectType?.length)
                              ?.map((val: string, index: number) => (
                                <small key={index}>{val}</small>
                              ))}
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                )}
                {data.offeredGratification?.length !== 0 && (
                  <div className="_projects__body__container__left__card__body__specs feature_box_paid_with small_none">
                    <div className={"_question_parent"}>
                      <p>Paid with</p>
                    </div>
                    <div
                      className="_projects__body__container__left__card__body__specs__desc"
                      // style={{ display: "inline-block" }}
                    >
                      {data.offeredGratification?.length ? (
                        data.offeredGratification
                          ?.sort()
                          .slice(0, data.offeredGratification.length)
                          .map((gratifcation: string) => {
                            return (
                              <span
                                className="_flag_design_small"
                                key={gratifcation}
                                style={{ display: "inline-block" }}
                              >
                                {gratifcation}
                              </span>
                            );
                          })
                      ) : (
                        <span style={{ display: "inline-block" }}>Nothing</span>
                      )}
                    </div>
                    {/* {data.offeredGratification?.length > 3 && (
                      <div className="_dot_container">
                        <div className="_dot_hover">
                          <div className="_dot_image">
                            <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                          </div>
                          <div className="_dot_hover_element flex_items">
                            {data.offeredGratification
                              .slice(displayOptions, data.offeredGratification?.length)
                              ?.map((val: string, index: number) => (
                                <small key={index}>{val}</small>
                              ))}
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                )}
                {data.forGratification?.length !== 0 && (
                  <div className="_projects__body__container__left__card__body__specs feature_box_paid_for small_none">
                    <div className="_question_parent">
                      <p>Paid For</p>
                    </div>
                    <div className="_projects__body__container__left__card__body__specs__desc">
                      {data.forGratification
                        ?.sort()
                        .slice(0, data.forGratification.length)
                        .map((gratification: string) => (
                          <span
                            className="_flag_design_small"
                            key={gratification}
                            style={{ display: "inline-block" }}
                          >
                            {gratification}
                          </span>
                        ))}
                    </div>
                    {/* {data.forGratification?.length > 3 && (
                      <div className="_dot_container">
                        <div className="_dot_hover">
                          <div className="_dot_image">
                            <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                          </div>
                          <div className="_dot_hover_element flex_items">
                            {data.forGratification
                              .slice(displayOptions, data.forGratification?.length)
                              ?.map((val: string, index: number) => (
                                <small key={index}>{val}</small>
                              ))}
                          </div>
                        </div>
                      </div>
                  )} */}
                  </div>
                )}
                {data.deadline?.length !== 0 && (
                  <div className="_projects__body__container__left__card__body__specs feature_box_work_duration">
                    <div className={"_question_parent"}>
                      <p>Proj. Duration</p>
                    </div>
                    <div className="_projects__body__container__left__card__body__specs__desc">
                      <span
                        className="_flag_design_small"
                        style={{ display: "inline-block" }}
                      >
                        {new Date(data.deadline).toLocaleDateString()}
                      </span>
                      {/* <span style={{ display: "inline-block" }}>{1}</span>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          display: "inline-block"
                        }}
                      >
                        to
                      </span>
                      <span style={{ display: "inline-block" }}>{acceptedDeadline[1]}</span>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          display: "inline-block"
                        }}
                      >
                        months
                      </span> */}
                    </div>
                  </div>
                )}
                {data.workingTypeOffice && data.workingTypeRemote && (
                  <div className="_projects__body__container__left__card__body__specs feature_box_working_type small_none">
                    <div className={"_question_parent"}>
                      <p>Working Type</p>
                    </div>
                    <div
                      className="_projects__body__container__left__card__body__specs__desc"
                      // style={{ display: "inline-block" }}
                    >
                      {data.workingTypeOffice ? (
                        <span
                          className="flag_design_small"
                          style={{ display: "inline-block" }}
                        >
                          Office
                        </span>
                      ) : null}
                      {data.workingTypeRemote ? (
                        <span
                          className="flag_design_small"
                          style={{ display: "inline-block" }}
                        >
                          Remote
                        </span>
                      ) : null}
                      {/* <span style={{ display: "inline-block" }}>Office</span>
                    <span style={{ display: "inline-block" }}>Remote</span> */}
                    </div>
                  </div>
                )}
                {data.usedLanguages?.length !== 0 && (
                  <div className="_projects__body__container__left__card__body__specs feature_box_languages">
                    <div className={"_question_parent"}>
                      <p>Languages</p>
                    </div>
                    <div
                      className="_projects__body__container__left__card__body__specs__desc"
                      // style={{ display: "inline-block" }}
                    >
                      {data.usedLanguages
                        ?.sort()
                        .slice(0, displayOptions)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className="_flag_design_small" key={index}>
                              {i}
                            </span>
                          </>
                        ))}
                    </div>
                  </div>
                )}
                {data.locations?.length !== 0 && (
                  <div className="_projects__body__container__left__card__body__specs feature_box_location">
                    <div className={"_question_parent"}>
                      <p>Team Locations</p>
                    </div>

                    <div className="_projects__body__container__left__card__body__specs__desc">
                      {/* <span>
                          {
                          project.locations?.sort().slice(0, displayOptions).map((location: string) => {
                            return location + ", ";
                          })}
                          {/* {location[0]}, {location[1]} */}
                      {/* </span> */}
                      {/* <div className="_projects__body__container__left__card__body__specs__desc"> */}
                      {data.locations
                        ?.sort()
                        .slice(0, data.locations.length)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className="flag_design_small" key={index}>
                              {i}
                            </span>
                          </>
                        ))}
                      {/* </div> */}
                    </div>
                    {/* {data.locations?.length > 3 && (
                      <div className="_dot_container">
                        <div className="_dot_hover">
                          <div className="_dot_image">
                            <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                          </div>
                          <div className="_dot_hover_element flex_items">
                            {data.locations
                              .slice(displayOptions, data.locations?.length)
                              ?.map((val: string, index: number) => (
                                <small key={index}>{val}</small>
                              ))}
                          </div>
                        </div>
                      </div>
                      )} */}
                  </div>
                )}
                {data.softSkills?.length !== 0 && (
                  <div className="_projects__body__container__left__card__body__specs feature_box_soft_skills">
                    <div className={"_question_parent"}>
                      <p>Soft Skills</p>
                    </div>
                    <div className="_projects__body__container__left__card__body__specs__desc">
                      {/* <div className="_projects__body__container__left__card__body__specs__desc"> */}
                      {data.softSkills
                        ?.sort()
                        .slice(0, data.softSkills.length)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className="flag_design_small" key={index}>
                              {i}
                            </span>
                          </>
                        ))}
                      {/* </div> */}
                    </div>
                    {/* {data.softSkills?.length > 3 && (
                      <div className="_dot_container">
                        <div className="_dot_hover">
                          <div className="_dot_image">
                            <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                          </div>
                          <div className="_dot_hover_element flex_items">
                            {data.softSkills
                              .slice(displayOptions, data.softSkills?.length)
                              ?.map((val: string, index: number) => (
                                <small key={index}>{val}</small>
                              ))}
                          </div>
                        </div>
                      </div>
                      )} */}
                  </div>
                )}
                {/* Availability - different values arrangement*/}
                <div className="_projects__body__container__left__card__body2__specs feature_box_weekly_availability">
                  <div className={"_question_parent"}>
                    <p>Hours / Wkly</p>
                  </div>

                  <div className="_projects__body__container__left__card__body2__specs__desc">
                    <span style={{ display: "inline-block" }}>
                      {data.availabilityFrom} h
                    </span>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        display: "inline-block",
                        // margin: "0",
                      }}
                    >
                      to
                    </span>
                    <span style={{ display: "inline-block" }}>
                      {data.availabilityTo} h
                    </span>
                    {/* <span
                        style={{
                          backgroundColor: "transparent",
                          display: "inline-block",
                        }}
                      >
                        {/* h/{project.availabilityType} */}
                    {/* </span> */}
                  </div>
                </div>
                {/* Biz or Tech - different values arrangement*/}
                <div className="_projects__body__container__left__card__body__specs feature_box_biz_or_tech">
                  <div className={"_question_parent"}>
                    <p>Biz / Tech Role</p>
                  </div>
                  <div
                    className="_projects__body__container__left__card__body__specs__desc"
                    style={{ display: "grid", gap: "4px" }}
                  >
                    <span style={{ display: "inline-block" }}>
                      BizLike {data.bizRole ? data.bizRole : 0}%
                    </span>{" "}
                    <span
                      className="_flag_design_small"
                      style={{ display: "inline-block" }}
                    >
                      TechLike {data.techRole}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Pane */}
          <div
            className={`${styles.pane_rightPane} ${
              isPaneOpen ? styles.pane_open : styles.pane_closed
            }`}
          >
            <button
              className={styles.pane_toggleButton}
              onClick={() => setIsPaneOpen(!isPaneOpen)}
            >
              {isPaneOpen ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
            </button>

            {isPaneOpen && (
              <div
                className={`${styles.pane_rightPane} ${
                  isPaneOpen ? styles.pane_open : styles.pane_closed
                }`}
              >
                <button
                  className={styles.pane_toggleButton}
                  onClick={() => setIsPaneOpen(!isPaneOpen)}
                >
                  {isPaneOpen ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
                </button>

                {isPaneOpen && (
                  <div className={styles.pane_paneContent}>
                    <h3 className={styles.pane_title}>Description</h3>
                    <p className={styles.pane_text}>
                      <h5 style={{ fontWeight: "normal" }}>
                        {data.description}
                      </h5>
                    </p>

                    {/* Input and Button Wrapper */}
                    <div className={styles.pane_messageWrapper}>
                      <textarea
                        placeholder="Type your message here..."
                        className={styles.pane_input}
                        value={message}
                        onChange={onChangeTextArea}
                      ></textarea>
                      <button
                        onClick={onClickSendMessageButtonClick}
                        className={styles.pane_sendButton}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {showAlert && (
        <div className={styles["alert-container"]}>
          <Alert icon={<Check fontSize="inherit" />} severity="success">
            <h4>Successfully sent message!</h4>
          </Alert>
        </div>
      )}
    </Page>
  );
}
