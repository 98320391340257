import React, { useEffect, useState, useRef } from "react";
import styles from "./formlabels.module.scss";

const Password = ({
  password,
  setPassword,
  change_placeholder,
}: {
  password: string;
  setPassword: (newState: string) => void;
  change_placeholder?: boolean;
}) => {
  const [placeholder, setPlaceholder] = useState("Password");
  const inputRef = useRef<HTMLInputElement>(null);
  const [text, setText] = useState<string>("");
  useEffect(() => {
    if (change_placeholder) {
      setPlaceholder("Confirm password");
    }
  }, [change_placeholder]);

  return (
    <div>
      <div className={styles.box}>
        <input
          ref={inputRef}
          placeholder=""
          type="password"
          className={styles.input}
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
            setText(event.target.value)
          }}
        />
        <label
          htmlFor="password"
          className={styles.label}
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
          style={text !== "" ? {top:"-5px", fontSize:"11px", color: "#2e79f1"}:{}}
        >
          Password
        </label>
      </div>
    </div>
  );
};
export default Password;
