import React, { useEffect, useRef, useState } from "react";
import tools_tools from "../../data/projects/enums/projects_tools_tools";
import "../../sass/components/Filters/ToolsExperienceFilter.scss";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { url } from "../../url";
import Alert from '@mui/material/Alert';
import { Check } from "@mui/icons-material";
import styles from "./styles/alert.module.scss";

export default function ToolsExperienceFilter({
  pickedOptions,
  setPickedOptions,
  optionViews,
  setOptionViews,
  show,
  setShow,
  displayColumn
}: {
  pickedOptions: string[];
  setPickedOptions: (newState: any) => void;
  optionViews: any;
  setOptionViews: (newState: any) => any;
  show: boolean;
  setShow: (key: string) => void;
  displayColumn?:boolean
}) {
  const location = useLocation();
  // const [show, setShow] = useState<boolean>(false);
  const floatRefs = useRef<HTMLInputElement[]>([]);
  const initialOptions = tools_tools;
  const [originalOptions, setOriginalOptions] =
    useState<string[]>(initialOptions);
  const [allOptions, setAllOptions] = useState<string[]>(originalOptions);
  // const [pickedOptions, setPickedOptions] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [floatValue, setFloatValue] = useState<number | string>(""); 
  const [adding, setAdding] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (searchText === "") {
      const unpickedOptions = originalOptions.filter(
        (option) => !pickedOptions.includes(option)
      );
      setAllOptions(unpickedOptions);
      if (location.pathname === "/project/add"){
        setAdding(false);
      }
    } else {
      const filteredOptions = originalOptions.filter(
        (option) =>
          !pickedOptions.includes(option) &&
          option.toLowerCase().includes(searchText.toLowerCase())
      );
      setAllOptions(filteredOptions);
      if(location.pathname === "/project/add" && filteredOptions.length === 0 && adding === false){
        setAdding(true);
      } else if (location.pathname === "/project/add" && filteredOptions.length > 0 && adding === true){
        setAdding(false);
      }
    }
  }, [searchText, pickedOptions, originalOptions]);

  const handleClick = (index: number) => {
    const selectedOption = allOptions[index];
    console.log(optionViews);
    if (optionViews[selectedOption] === 0) {
      floatRefs.current[index].focus();
    } else {
      if (optionViews[selectedOption] > 70 || optionViews[selectedOption] <= 0){
        alert("dodaj wartość od 0 do 70");
      }
      else { 
        const updatedOptions = allOptions.filter((_, i) => i !== index);
        setAllOptions(updatedOptions);
        if (!pickedOptions.includes(selectedOption)) {
          setPickedOptions([...pickedOptions, selectedOption]);
        }
      }
    }
  };

  const handleRemove = (index: number) => {
    try {
      const removedOption = pickedOptions[index];
      const updatedPickedOptions = pickedOptions.filter((_, i) => i !== index);
      setPickedOptions(updatedPickedOptions);
      if (!allOptions.includes(removedOption)) {
        const updatedOptions = [...allOptions, removedOption].sort(
          (a: string, b: string) => {
            const numA = parseInt(a.match(/\d+/)![0]);
            const numB = parseInt(b.match(/\d+/)![0]);
            return numA - numB;
          }
        );

        setAllOptions(updatedOptions);
      }
    } catch (err) {
      // do literally nothing
    }
  };

  const onClickProposeButton = async () => {
    const token = localStorage.getItem("token");
    
    try {
      const response = await axios.post(url+ "/proposition", {
        proposition: searchText,
        filterType: "toolsExperience" 
      },{
        headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }}); 
      setAdding(false); 
      setSearchText("");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3500);
    } catch (err) { // handler error
      console.log(err);
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText(searchText);
  };
  const handleFloatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);

    if (value >= 0 && value <= 70) {
      handleInputChange(allOptions[0], e.target.value);
      setFloatValue(value); // Update state with the float value
    } else {
      setFloatValue(""); // Clear the value if it is out of range
    }
  };

  // const handleViewsIncrement = (option: string) => {
  //   const updatedOptionViews = { ...optionViews };
  //   updatedOptionViews[option] += 1;
  //   setOptionViews(updatedOptionViews);
  // };

  // const handleViewsDecrement = (option: string) => {
  //   const updatedOptionViews = { ...optionViews };
  //   if (updatedOptionViews[option] > 1) {
  //     updatedOptionViews[option] -= 1;
  //     setOptionViews(updatedOptionViews);
  //   }
  // };

  const handleInputChange = (option: any, value: any) => {
    const updatedOptionViews = { ...optionViews };
    updatedOptionViews[option] = value; // Update the value of the option
    setOptionViews(updatedOptionViews); // Update the state
  };

  const handleShowComponents = () => {
    setShow("toolsExperience");
  };

  return (
    <>
      <div className="component">
        {window.location.pathname === "/teammates" ||
        window.location.pathname === "/projects" ? (
          ""
        ) : (
          <div
            style={{
              position: "absolute",
              left: "-0.7em",
              top: ".5em",
              color: "red",
              fontSize: "30px",
            }}
          >
            *
          </div>
        )}
        <div onClick={handleShowComponents} className="title ">
          <p>
            Used Tools&nbsp;
            {pickedOptions.length > 0 && (
              <span className="ml_3" style={{ marginLeft: "4px" }}>
                ({pickedOptions.length})
              </span>
            )}
          </p>
          <i className={`${show && "rotate"} fa-solid fa-chevron-down`}></i>
        </div>
        {/* container */}
        {show && (
          <div
            className={`scroll_bar ${
              !(
                window.location.pathname === "/teammates" ||
                window.location.pathname === "/projects"
              )
                ? "show_component1"
                : "without_border_component"
            }`}
          >
            {/* input boxes */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {adding && (
                <button className="propose-button" onClick={onClickProposeButton}>propose</button>
              )}
              <input
                type="text"
                className="search_input"
                onChange={handleSearch}
                value={searchText}
                style={{ width: "60%" }} // 60% width for text input
              />

              <input
                type="number"
                className="search_input"
                onChange={handleFloatChange}
                value={floatValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleClick(0);
                    setFloatValue("");
                    setSearchText("");
                  }
                }}
                // max="1000"
                // step="0.1"
                placeholder="+Y"
                style={{ width: displayColumn?"15%":"10%", marginLeft: "10px" }} // 20% width for float input
              />
            </div>
            <div
              className="mainContentBox" style={{flexDirection:displayColumn?"column":"row"}}
            >
              {/* left column - picked section */}
              <div
                className="picked"
                style={{ padding: "10px"}}
              >
                <h6>Picked</h6>
                <div className="all_options_scroll">
                  {pickedOptions.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleRemove(index)}
                    >
                      <div className="checkbox">
                        <div className="inner_box"></div>
                      </div>
                      <p>
                        {option} + {optionViews[option]} Y
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              {/* right column - all section */}
              <div
                className="all"
                style={{padding: "10px"}}
              >
                <h6>All</h6>
                <div className="all_options_scroll">
                  {allOptions.map((option, index) => (
                    <div className="" key={index}>
                      <div key={index} className="option">
                        <div className="checkbox">
                          <div
                            className="inner_box"
                            onClick={() => handleClick(index)}
                          ></div>
                        </div>
                        {/* <div className="op"> */}
                        <span className="ppppp" style={{ flexShrink: "0" }}>
                          +{" "}
                          <input
                            type="text"
                            style={{
                              border: "none",
                              width: "20px",
                              background: "transparent",
                            }}
                            value={optionViews[option]}
                            onChange={(e) => {
                              handleInputChange(option, e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleClick(index);
                              }
                            }}
                            ref={(el: HTMLInputElement) =>
                              (floatRefs.current[index] = el)
                            }
                          />{" "}
                          Y |
                        </span>
                        <div
                          style={{ fontSize: "" }}
                          onClick={() => handleClick(index)}
                        >
                          {option}
                        </div>{" "}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showAlert && (
        <div className={styles["alert-container"]}>
          <Alert icon={<Check fontSize="inherit" />} severity="success">
            <h4>Succesfully propose new filter</h4>
          </Alert>
        </div>
    )}
    </>
  );
}
