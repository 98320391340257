import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Page from "../../components/Page";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TeammatesDetails from "../../components/InputFields/TeammatesDetails";
import AddedSkillers from "../../components/InputFields/AddedSkillers";
import AddedSkillersSidebar from "../../components/InputFields/AddedSkillersSiderbar";
import TeammateBox from "../../components/Boxs/TeammateBox";
import { url } from "../../url";
import TeammateFilters from "../../components/TeammateFilters/TeammateFilters";
import { teammatesAtom } from "./atoms/TeammateAtomGlobal";
import { userTeammatesAtom } from "./atoms/UserTeammatesAtomGlobal";
import Pagination from "@mui/material/Pagination";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from '@mui/material/CircularProgress';

import { useAtom } from "jotai";
import { Navigate } from "react-router-dom";

const Teammates = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [showSkillers, setShowSkillers] = useState(false);
  const [teammates, setTeammates] = useAtom<any[]>(teammatesAtom);
  const [userTeammates, setUserTeammates] = useAtom(userTeammatesAtom);
  const [userTeammateProfileId, setUserTeammateProfileId] = useState("");
  const [teammateAdded, setTeammateAdded] = useState<any>({});
  // const [pages, setPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  // Infinite scroll 
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(2);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let teammates = null;
      try {
        teammates = await axios.get(url + `/teammate?page=1`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } catch (err) {
        setError(true);
        return;
      }

      const teammatesArr = teammates.data.teammates; 
      setTeammates(teammatesArr);

      const morePages = teammates.data.teammatesPages == 1 ? false : true
      if(!morePages){
        setHasMore(false);
      }

      const token = localStorage.getItem("token");
      if (token) {
        const userTeammates = await axios.get(url + "/user/teammates/found", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const userTeammateArr = userTeammates.data.teammates;
        setUserTeammates(userTeammateArr);

        teammatesArr.forEach((teammate: any) => {
          if (userTeammateArr.includes(teammate._id)) {
            setTeammateAdded((prevState: any) => ({
              ...prevState,
              [teammate._id]: true,
            }));
          } else {
            setTeammateAdded((prevState: any) => ({
              ...prevState,
              [teammate._id]: false,
            }));
          }
        });

        const TeammateProfile = await axios.get(url + "/user/myTeammate", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (TeammateProfile.data.userTeammateProfile?._id) {
          setUserTeammateProfileId(
            TeammateProfile.data.userTeammateProfile._id
          );
        }
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const fetchMoreTeammates = async() =>{
    await axios.get(url + `/teammates/?page=${index}`)
    .then((res)=> {
      const teammateArr = res.data.teammates;
      setTeammates((prevTeammates: any[])=> [...prevTeammates,...teammateArr]);

      teammateArr.forEach((teammate: any) => {
        if (userTeammates.includes(teammate._id)) {
          setTeammateAdded((prevState: any) => ({
            ...prevState,
            [teammate._id]: true,
          }));
        } else {
          setTeammateAdded((prevState: any) => ({
            ...prevState,
            [teammate._id]: false,
          }));
        }
      })

      teammateArr.length > 0 ? setHasMore(true) : setHasMore(false);
    }).catch((prevIndex)=> prevIndex + 1);
  }; 

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  const handleShowFilters = () => {
    if (showFilters === false) {
      setShowFilters(true);
    } else {
      setShowFilters(false);
    }
  };
  const handleShowSkillers = () => {
    if (showSkillers === false) {
      setShowSkillers(true);
    } else {
      setShowSkillers(false);
    }
  };

  const tokenAvailable = () => {
    const token = localStorage.getItem("token");
    if (token) {
      return true;
    }
    return false;
  };

  const addTeammate = async (teammate: any): Promise<void> => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await axios.post(
          url + `/user/teammates/${teammate._id}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserTeammates([...userTeammates, teammate._id]);
      } catch (err) {
        console.log(err);
      }
    }
    setTeammateAdded((prevState: any) => ({
      ...prevState,
      [teammate._id]: true,
    }));
  };

  const deleteUserTeammates = async (teammate: any): Promise<void> => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await axios.delete(url + `/user/teammates/${teammate._id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setUserTeammates(
          userTeammates.filter(function (t: any) {
            return t !== teammate._id;
          })
        );
      } catch (err) {
        console.log(err);
      }
    }
    setTeammateAdded((prevState: any) => ({
      ...prevState,
      [teammate._id]: false,
    }));
  };

  if (error) {
    return <Navigate to="/error" />
  }
  return (
    <Page>
      <section className="projects">
        <header className="container projects__header">
          <div className="projects__header__left"></div>
          <div className="projects__header__right">
            <div className="projects__header__right__skillerButton">
              {/* <AddedSkillersSidebar handleShowSkillers={handleShowSkillers} showSkillers={showSkillers} /> */}
            </div>
            <Button
              onClick={handleShowFilters}
              variant="contained"
              size="small"
              className={`projects__header__right__filterButton ${
                showFilters ? "clicked-button" : ""
              }`}
            >
              <FilterAltIcon className="projects__header__right__filterButton__icon" />
              Apply Filter
            </Button>
          </div>
        </header>
        <div className="projects__body">
          {showFilters ? <TeammateFilters /> : null}
          <div className="projects__body__container">
            <div className="projects__body__container__left">
              <InfiniteScroll
                dataLength={teammates.length}
                next={fetchMoreTeammates}
                hasMore={hasMore}
                loader={<CircularProgress/>} 
              >
              {teammates.map((teammate: any) => {
                if (userTeammateProfileId.includes(teammate._id)) {
                  return (
                    <TeammateBox
                      teammate={teammate}
                      tokenAvailable={false}
                      loading={loading}
                    />
                  );
                } else {
                  return (
                    <TeammateBox
                      teammate={teammate}
                      tokenAvailable={tokenAvailable()}
                      addTeammate={() => addTeammate(teammate)}
                      deleteTeammate={() => deleteUserTeammates(teammate)}
                      teammateAdded={teammateAdded[teammate._id]}
                      loading={loading}
                    />
                  );
                }
              })}
              </InfiniteScroll>
              {/* <TeammatesDetails project={null} title={"Skillers"} />
                <TeammatesDetails project={null} title={"Skillers"} />
                <TeammatesDetails project={null} title={"Skillers"} />
                <TeammatesDetails project={null} title={"Skillers"} />
                <TeammatesDetails project={null} title={"Skillers"} />
                <TeammatesDetails project={null} title={"Skillers"} /> */}
            </div>
            {/* <div className="projects__body__container__right">
                <AddedSkillers />
              </div> */}
          </div>
        </div>
        <div>
        </div>
      </section>
    </Page>
  );
};

export default Teammates;
