import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Icon, IconButton, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";

interface SidebarDrawerStateInt {
  top: boolean;
  left: boolean;
  bottom: boolean;
  right: boolean;
}

export default function SidebarDrawer() {
  const [state, setState]: [
    SidebarDrawerStateInt,
    React.Dispatch<React.SetStateAction<SidebarDrawerStateInt>>,
  ] = React.useState<SidebarDrawerStateInt>({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    <K extends keyof SidebarDrawerStateInt>(anchor: K, open: boolean) =>
    (event: any) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = <K extends keyof SidebarDrawerStateInt>(anchor: K) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 180 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Sidebar />
    </Box>
  );

  return (
    <div className="addedSkillerSidebar">
      <React.Fragment key={"left"}>
        <IconButton onClick={toggleDrawer("left", true)}>
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
      {/* {['left'].map((anchor) => ( // why map? lol
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)}>
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor={anchor as SidebarDrawerStateInt | undefined }
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))} */}
    </div>
  );
}
