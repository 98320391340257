import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import thunk from "redux-thunk";
// import { Provider } from 'react-redux';
import { Provider, createStore } from "jotai";
import { DevTools } from "jotai-devtools";
// import { createStore,compose,applyMiddleware } from 'redux';
import projectReducer from "./store/reducers/project";

// declare global {
//   interface Window {
//     __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
//   }
// }

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(projectReducer as any,composeEnhancers(applyMiddleware(thunk)));

const store = createStore();

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  // <Provider store={store}>
  <Provider store={store}>
    <DevTools store={store} />
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </Provider>,
  // </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
