import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import Sidebar from "../../components/Sidebar";
import SidebarDrawer from "../../components/SidebarDrawer";
import AddSkiller from "../Teammate/AddTeammate";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../../url";
import TeammateBox from "../../components/Boxs/TeammateBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useResetTeammateAtom } from "../../utils/useResetTeammateAtom";
const MySkillers = () => {
  const [teammateProfile, setTeammateProfile] = useState("");
  const [loading, setLoading] = useState(true);
  const resetTeammateAtoms = useResetTeammateAtom();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const teammateProfileRes = await axios.get(url + "/user/myTeammate", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setTeammateProfile(teammateProfileRes.data.userTeammateProfile);
      setLoading(false);
    };
    fetchData();
  }, []);

  const navigate = useNavigate();

  const goToCreateTeammate = () => {
    resetTeammateAtoms();
    navigate("/teammates/add");
  };

  const goToEditTeammateProfile = (teammate:any) => {
    navigate(`/teammates/editTeammate/${teammate._id}`);
  };

  const deleteMyTeammate = async () => {
    const token = localStorage.getItem("token");
    await axios.delete(url + "/user/deleteMyTeammate", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setTeammateProfile("");
  };

  return (
    <Page>
      <div className="mySkillers">
        <header className="mySkillers__header">
          <div className="mySkillers__header__sidebar">
            <SidebarDrawer />
          </div>
        </header>
        <div className="mySkillers__container">
          <div className="mySkillers__container__sidebar">
            <Sidebar />
          </div>
          <div className="mySkillers__container__savedSkillers">
            {!loading && (
            <>
              {teammateProfile ? (
                <div>
                  <div className="mySkillers__container__savedSkillers__addSkiller">
                    <h4>Edit Skiller Profile</h4>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => goToEditTeammateProfile(teammateProfile)}
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => deleteMyTeammate()}
                    >
                      Delete
                    </Button>
                  </div>
                  <div className="projects__body__container">
                    <div className="projects__body__container__left">
                      <TeammateBox
                        teammate={teammateProfile}
                        tokenAvailable={false}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mySkillers__container__savedSkillers__addSkiller">
                  <h4>Add Skiller Profile</h4>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => goToCreateTeammate()}
                  >
                    Add
                  </Button>
                </div>
              )}
            </>
            )}
            {/* <div className="mySkillers__container__savedSkillers__message"> */}
            {/* <AddSkiller name={"Add Skiller"} /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default MySkillers;
