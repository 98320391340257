import React, { useState, useRef } from "react";
import styles from "./formlabels.module.scss";
const Email = ({
  email,
  setEmail,
}: {
  email: string;
  setEmail: (newState: string) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [text, setText] = useState<string>("");
  return (
    <div>
      <div className={styles.box}>
        <input
          ref={inputRef}
          placeholder=""
          type="text"
          className={styles.input}
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
            setText(event.target.value)
          }}
        />
        <label htmlFor="email" className={styles.label} onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        style={text !== "" ? {top:"-5px", fontSize:"11px", color: "#2e79f1"}:{}}
        >
          Email
        </label>
      </div>
    </div>
  );
};
export default Email;
