import React, { useEffect, useState } from "react";
import project_types from "../../data/projects/enums/project_type";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { url } from "../../url";
import Alert from '@mui/material/Alert';
import { Check } from "@mui/icons-material";
import styles from "./styles/alert.module.scss";

export default function ProjectType({
  pickedOptions,
  setPickedOptions,
  show,
  setShow,
  displayColumn
}: {
  pickedOptions: string[];
  setPickedOptions: (newState: any) => void;
  show: boolean;
  setShow: (key: string)=>void;
  displayColumn?:boolean
}) {
  const location = useLocation();
  const [originalOptions, setOriginalOptions] = useState<string[]>(project_types);
  const [allOptions, setAllOptions] = useState<string[]>(originalOptions);
  const [searchText, setSearchText] = useState<string>("");
  const [adding, setAdding] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (searchText === "") {
      const unpickedOptions = originalOptions.filter(
        (option) => !pickedOptions.includes(option),
      );
      setAllOptions(unpickedOptions);
      if (location.pathname === "/project/add"){
        setAdding(false);
      }
    } else {
      const filteredOptions = originalOptions.filter(
        (option) =>
          !pickedOptions.includes(option) &&
          option.toLowerCase().includes(searchText.toLowerCase()),
      );
      setAllOptions(filteredOptions);
      if(location.pathname === "/project/add" && filteredOptions.length === 0 && adding === false){
        setAdding(true);
      } else if (location.pathname === "/project/add" && filteredOptions.length > 0 && adding === true){
        setAdding(false);
      }
    }
  }, [searchText, pickedOptions, originalOptions, location.pathname]);

  const handleClick = (index: number) => {
    const selectedOption = allOptions[index];
    const updatedOptions = allOptions.filter((_, i) => i !== index);
    setAllOptions(updatedOptions);
    if (!pickedOptions.includes(selectedOption)) {
      setPickedOptions([...pickedOptions, selectedOption]);
    }
  };

  const handleRemove = (index: number) => {
    try {
      const removedOption = pickedOptions[index];
      const updatedPickedOptions = pickedOptions.filter((_, i) => i !== index);
      setPickedOptions(updatedPickedOptions);
      if (!allOptions.includes(removedOption)) {
        const updatedOptions = [...allOptions, removedOption].sort((a, b) => {
          const numA = parseInt(a.match(/\d+/)![0]);
          const numB = parseInt(b.match(/\d+/)![0]);
          return numA - numB;
        });
        setAllOptions(updatedOptions);
      }
    } catch (err) {
      // do literally nothing
    }
  };

  const onClickProposeButton = async () => {
    const token = localStorage.getItem("token");
    
    try {
      const response = await axios.post(url+ "/proposition", {
        proposition: searchText,
        filterType: "projectType" 
      },{
        headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }}); 
      setAdding(false); 
      setSearchText("");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3500);
    } catch (err) { // handler error
      console.log(err);
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText(searchText);
  };

  return (
    <>
      <div className="component">
        {window.location.pathname === "/teammates" ||
        window.location.pathname === "/projects" ? (
          ""
        ) : (
          <div
            style={{
              position: "absolute",
              left: "-0.7em",
              top: ".5em",
              color: "red",
              fontSize: "30px",
            }}
          >
            *
          </div>
        )}
        <div onClick={() => setShow("projectType")} className="title ">
          <p>
            Project Type&nbsp;
            {pickedOptions.length > 0 && (
              <span className="ml_3" style={{ marginLeft: "4px" }}>
                ({pickedOptions.length})
              </span>
            )}
          </p>
          <i className={`${show && "rotate"} fa-solid fa-chevron-down`}></i>
        </div>
        {/* container */}
        {show && (
          <div
            className={`scroll_bar ${
              !(
                window.location.pathname === "/teammates" ||
                window.location.pathname === "/projects"
              )
                ? "show_component1"
                : "without_border_component"
            }`}
          >
            {/* input boxes */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {adding && (
                <button className="propose-button" onClick={onClickProposeButton}>Propose</button>
              )}
              <input
                type="text"
                className="search_input"
                onChange={handleSearch}
                value={searchText}
                placeholder="Search..."
                style={{ width: "100%" }}
              />
            </div>
            <div
              className="mainContentBox" style={{flexDirection:displayColumn?"column":"row"}}
            >
              {/* left column - picked section */}
              <div
                className="picked"
                style={{ padding: "10px"}}
              >
                <h6>Picked</h6>
                <div className="all_options_scroll">
                  {pickedOptions.map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleRemove(index)}
                    >
                      <div className="checkbox">
                        <div className="inner_box"></div>
                      </div>
                      <p>
                        {option}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              {/* right column - all section */}
              <div
                className="all"
                style={{padding: "10px"}}
              >
                <h6>All</h6>
                <div className="all_options_scroll">
                  {allOptions.map((option, index) => (
                    <div className="" key={index}>
                      <div key={index} className="option">
                        <div className="checkbox">
                          <div
                            className="inner_box"
                            onClick={() => handleClick(index)}
                          ></div>
                        </div>
                        <div
                          style={{ fontSize: "" }}
                          onClick={() => handleClick(index)}
                        >
                          {option}
                        </div>{" "}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showAlert && (
        <div className={styles["alert-container"]}>
          <Alert icon={<Check fontSize="inherit" />} severity="success">
            <h4>Succesfully propose new filter</h4>
          </Alert>
        </div>
    )}
    </>
  );
}
