import React, { useState } from "react";
import PersonSearchTwoToneIcon from "@mui/icons-material/PersonSearchTwoTone";
import { useNavigate } from "react-router";

const FindTeammateCard = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const [cardColor, setCardColor] = useState(false);
  const navigate = useNavigate();
  const handleCardColorChange = () => {
    setCardColor(true);
  };

  const handleCardColorRemove = () => {
    setCardColor(false);
  };

  const handleNavigation = () => {
    const path = "/teammates";
    navigate(path);
  };
  return (
    <div
      onClick={
        title === "Projects" ? () => navigate("/projects") : handleNavigation
      }
      className={`homeCardsView__container__projectCards__card ${cardColor ? `homeCardsView__container__projectCards__cardProject` : ""}`}
      onMouseEnter={handleCardColorChange}
      onMouseLeave={handleCardColorRemove}
    >
      <div className="homeCardsView__container__projectCards__card__iconBox">
        {title === "Projects" ? (
          <PersonSearchTwoToneIcon className="homeCardsView__container__projectCards__cardProject__iconBox__iconProject" />
        ) : (
          <PersonSearchTwoToneIcon
            className={`homeCardsView__container__projectCards__card__iconBox__icon ${cardColor ? `homeCardsView__container__projectCards__cardProject__iconBox__iconProject` : ""}`}
          />
        )}
      </div>
      <p>{title}</p>
      <span>{description}</span>
    </div>
  );
};

export default FindTeammateCard;
