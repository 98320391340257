import React from "react";
import { Avatar } from "@mui/material";
import { chosenUserAtom } from "../atoms/ChatAtom";
import { useSetAtom,useAtom } from "jotai";

const PeopleCard = ({user}:{user: any}) => {
  const [chosenUser, setChosenUser ] = useAtom(chosenUserAtom);

  return (
    <div className={chosenUser === user ?  "conversations__container__people__card__active":"conversations__container__people__card" } onClick={()=>{setChosenUser(user)}} style={{cursor: 'pointer'}}>
      <Avatar
        alt="Cindy Baker"
        src="/static/images/avatar/3.jpg"
        className="conversations__container__people__card__avatar"
      />
      <div className="conversations__container__people__card__desc">
        <div className="conversations__container__people__card__desc__name">
          <p>{user.username}</p>
          {/* <span>2h</span> */}
        </div>
        <p className="conversations__container__people__card__desc__previewText">
          preview
        </p>
      </div>
    </div>
  );
};

export default PeopleCard;
