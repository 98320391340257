import React from "react";
import Page from "../../../components/Page";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import HouseIcon from "@mui/icons-material/House";
import { useNavigate } from "react-router-dom";

const SettingsMessage = () => {
  const navigate = useNavigate();
  return (
    <Page>
      <div className="settingsMessage">
        <div className="settingsMessage__container">
          <div className="settingsMessage__container__topIcon">
            <SportsHandballIcon className="settingsMessage__container__topIcon__icon" />
          </div>
          <h4>Great Job!</h4>
          <p className="settingsMessage__container__message">
            You’ve just sent your offers, wait for answer, or…
          </p>
          <div className="settingsMessage__container__actions">
            <div
              onClick={() => navigate("/sendmoreoffers")}
              className="settingsMessage__container__actions__moreOffer"
            >
              <NoteAddIcon className="settingsMessage__container__actions__moreOffer__icon" />
              <p>Send More Offers</p>
            </div>
            <div
              onClick={() => navigate("/")}
              className="settingsMessage__container__actions__moreOffer"
            >
              <HouseIcon className="settingsMessage__container__actions__moreOffer__icon" />
              <p>Go to Home</p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SettingsMessage;
