import React, { useEffect, useState } from "react";
import used_languages from "../../data/projects/enums/languages";
export default function UsedLanguagesFilter({
  pickedOptions,
  setPickedOptions,
  show,
  setShow,
  displayColumn
}: {
  pickedOptions: string[];
  setPickedOptions: (newState: any) => void;
  show: boolean;
  setShow: (key: string) => void
  displayColumn:boolean
}) {
  // const [show, setShow] = useState<boolean>(false);
  const [originalOptions, setOriginalOptions] =
    useState<string[]>(used_languages);
  const [allOptions, setAllOptions] = useState<string[]>(originalOptions);
  // const [pickedOptions, setPickedOptions] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  useEffect(() => {
    if (searchText === "") {
      const unpickedOptions = originalOptions.filter(
        (option) => !pickedOptions.includes(option),
      );
      setAllOptions(unpickedOptions);
    } else {
      const filteredOptions = originalOptions.filter(
        (option) =>
          !pickedOptions.includes(option) &&
          option.toLowerCase().includes(searchText.toLowerCase()),
      );
      setAllOptions(filteredOptions);
    }
  }, [searchText, pickedOptions, originalOptions]);
  const handleClick = (index: number) => {
    const selectedOption = allOptions[index];
    const updatedOptions = allOptions.filter((_, i) => i !== index);
    setAllOptions(updatedOptions);
    if (!pickedOptions.includes(selectedOption)) {
      setPickedOptions([...pickedOptions, selectedOption]);
    }
  };
  const handleRemove = (index: number) => {
    try {
      const removedOption = pickedOptions[index];
      const updatedPickedOptions = pickedOptions.filter((_, i) => i !== index);
      setPickedOptions(updatedPickedOptions);
      if (!allOptions.includes(removedOption)) {
        const updatedOptions = [...allOptions, removedOption].sort((a, b) => {
          const numA = parseInt(a.match(/\d+/)![0]);
          const numB = parseInt(b.match(/\d+/)![0]);
          return numA - numB;
        });
        setAllOptions(updatedOptions);
      }
    } catch (err) {
      // do nothing
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText(searchText);
  };
  return (
    <>
      <div className="component set_border_2_grey">
        <div onClick={() => setShow("usedLanguages")} className="title">
          <p>
            Used Languages&nbsp;
            {pickedOptions.length > 0 && (
              <span className="ml_3">({pickedOptions.length})</span>
            )}
          </p>
          <i
            className={`${
              show && "rotate"
            } fa-solid fa-chevron-down cursor-pointer`}
          />
        </div>
        {show && (
          <div className="show_component">
            <input
              type="text"
              className="search_input"
              onChange={handleSearch}
              value={searchText}
              onKeyDown={(e)=>{
                if(e.key === "Enter" && searchText.length) {
                  handleClick(0);
                  setSearchText("");
                }
              }}
            />
            <div
              className="mainContentBox"
              style={{flexDirection:displayColumn?"column":"row"}}
            >
            <div className="picked">
              <h6>Picked</h6>
              {pickedOptions.map((option, index) => (
                <div
                  key={index}
                  className="option"
                  onClick={() => handleRemove(index)}
                >
                  <div className="checkbox">
                    <div className="inner_box" />
                  </div>
                  <p>{option}</p>
                </div>
              ))}
            </div>
            <div className="all">
              <h6>All</h6>
              <div className="all_options_scroll">
                {allOptions.map((option, index) => (
                  <div
                    onClick={() => handleClick(index)}
                    key={index}
                    className="option"
                  >
                    <div className="checkbox">
                      {" "}
                      <div className="inner_box" />
                    </div>
                    <p>{option}</p>
                  </div>
                ))}
              </div>
            </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
