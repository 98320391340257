import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import moment from "moment";

const Calender = ({ handleDateChange, selectedDate }: any) => {
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div style={{ width: "100%" }}>
                    <DateCalendar
                        sx={{ width: "100%" }}
                        value={moment(selectedDate)}
                        onChange={handleDateChange}
                    />
                </div>
            </LocalizationProvider>
        </>
    );
};

export default Calender;
