import { atom } from "jotai";
import tools_tools from "../../../data/projects/enums/projects_tools_tools";

const nicknameAtom = atom("");
nicknameAtom.debugLabel = "Nickname";

const toolsExperienceAtom = atom<string[]>([]);
toolsExperienceAtom.debugLabel = "Tools Experience";

const toolsExperienceOptionViewsAtom = atom(
  tools_tools.reduce((acc: any, option: string) => {
    acc[option] = 0; // Initialize views for each option to 1
    return acc;
  }, {})
);

toolsExperienceOptionViewsAtom.debugLabel = "Tools Experience Option Views";

const projectTypeAtom = atom([]);
projectTypeAtom.debugLabel = "Project Type";

const offeredGratificationAtom = atom([]);
offeredGratificationAtom.debugLabel = "Offered Gratification";

const forGratificationAtom = atom([]);
forGratificationAtom.debugLabel = "For Gratification";

const softSkillsAtom = atom([]);
softSkillsAtom.debugLabel = "Soft Skills";

const requiredAreaOfExpertiseAtom = atom([]);
requiredAreaOfExpertiseAtom.debugLabel = "Required Area of Expertise";

const hardSkillsAtom = atom([]);
hardSkillsAtom.debugLabel = "Hard Skills";

const offeredWorkingTypeAtomNewArray = atom<string[]>([]);
offeredWorkingTypeAtomNewArray.debugLabel = "Offered Working Type";

const offeredWorkingTypeActiveOptionsAtom = atom<boolean[]>([]);
offeredWorkingTypeActiveOptionsAtom.debugLabel =
  "Offered Working Type Active Options";

const usedLanguagesAtom = atom([]);
usedLanguagesAtom.debugLabel = "Used Languages";

const locationsAtom = atom([]);
locationsAtom.debugLabel = "Locations";

const bizOrTechRoleAtomLeftValue = atom("");
bizOrTechRoleAtomLeftValue.debugLabel = "Required Biz Or Tech Role Left Value";

const bizOrTechRoleAtomRightValue = atom("");
bizOrTechRoleAtomRightValue.debugLabel =
  "Required Biz Or Tech Role Right Value";

const requiredAvailabilityAtomStartValue = atom(10);
requiredAvailabilityAtomStartValue.debugLabel =
  "Required Availability Start Value";

const projectDeadlineAtom = atom(new Date());
projectDeadlineAtom.debugLabel = "Collab Until";

const requiredAvailabilityAtomEndValue = atom(15);
requiredAvailabilityAtomEndValue.debugLabel = "Required Availability End Value";

const requiredAvailabilityAtomOption = atom("Monthly");
requiredAvailabilityAtomOption.debugLabel = "Required Availability Option";

const pageAtom = atom(0);
pageAtom.debugLabel = "Page";

const descriptionAtom = atom("");
descriptionAtom.debugLabel = "Description";

const openAtom = atom({
  toolsExperience: false,
  projectType: false,
  offeredGratification: false,
  forGratification: false,
  softSkills: false,
  requiredAreaOfExpertise: false,
  projectDeadline: false,
  hardSkills: false,
  offeredWorkingType: false,
  usedLanguages: false,
  bizOrTech: false,
  requiredAvailability: false,
  locations: false,
});
openAtom.debugLabel = "Edit Teammate Open Filters";

export {
  bizOrTechRoleAtomLeftValue,
  bizOrTechRoleAtomRightValue,
  descriptionAtom,
  forGratificationAtom,
  hardSkillsAtom,
  locationsAtom,
  nicknameAtom,
  offeredGratificationAtom,
  offeredWorkingTypeActiveOptionsAtom,
  offeredWorkingTypeAtomNewArray,
  openAtom,
  pageAtom,
  projectTypeAtom,
  requiredAreaOfExpertiseAtom,
  requiredAvailabilityAtomEndValue,
  requiredAvailabilityAtomOption,
  requiredAvailabilityAtomStartValue,
  softSkillsAtom,
  toolsExperienceAtom,
  toolsExperienceOptionViewsAtom,
  usedLanguagesAtom,
  projectDeadlineAtom
};
