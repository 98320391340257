import React, { useEffect, useState } from "react";
import "../../sass/components/Filters/RequiredAvailabilityFilter.scss";
export default function RequiredAvailabilityFilter({
  startValue,
  setStartValue,
  endValue,
  setEndValue,
  selectedOption,
  setSelectedOption,
  open,
  setOpen
}: {
  startValue: number;
  setStartValue: (newState: number) => void;
  endValue: number;
  setEndValue: (newState: number) => void;
  selectedOption: string;
  setSelectedOption: (newState: string) => void;
  open: boolean;
  setOpen: (key: string) => void;
}) {
  // const [open, setOpen] = useState<boolean>(false);
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);
  // const [startValue, setStartValue] = useState<number>(10);
  // const [endValue, setEndValue] = useState<number>(15);
  // const [selectedOption, setSelectedOption] = useState<string | null>(null); // Use null to represent no selection
  const [selectedOptions, setSelectedOptions] = useState<string[]>(["Monthly"]);
  const [options, setOptions] = useState<string[]>(["Monthly", "Weekly"]);
  const [count, setCount] = useState<number>(0);


  const handleOpen = () => {
    setOpen("requiredAvailability");
  };

  const handleOptionsOpen = () => {
    setOptionsOpen(!optionsOpen);
  };
  const handleOptionSelect = (option: string) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      if (selectedOptions.length < 2) {
        setSelectedOptions([...selectedOptions, option]);
      }
    }
    setSelectedOption(option);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setValue: (value: number) => void) => {
    const value = event.target.value;
    
    if (value === '') {
      setValue(0);
    } else if (!isNaN(parseInt(value))) {
      setValue(parseInt(value));
    }
    setCount(prevCount => prevCount + 1);
  };


  return (
    <>
    <div className="component">
      <div onClick={handleOpen} className="heading">
        <p className="title">
          Commitment
          {selectedOptions && (
            <span className="ml_3" style={{ marginLeft: "4px" }}>
              {count > 0 ? `(${count})` : ""}
            </span>
          )}
        </p>
        <p>
          {open ? (
            <i className="fas fa-angle-down rotate"></i>
          ) : (
            <i className="fas fa-angle-down"></i>
          )}
        </p>
      </div>
      {open && (
        <div className="show_component" style={{display:"flex",justifyContent:"center"}}>
          <div className="ul_design">
            <div>From </div>
            <div>
              <input
                onChange={(event) => handleInputChange(event, setStartValue)}
                type="text"
                className="text_input"
                value={startValue}
              // onChange={(event) => {
              //   const value = event.target.value;
              //   if (value === '') {
              //     setStartValue(0); // Set to 0 or any default value you prefer
              //   } else if (!isNaN(parseInt(value))) {
              //     setStartValue(parseInt(value));
              //   }
              // }}
              />
            </div>
            <div>to</div>
            <div>
              <input
                type="text"
                className="text_input"
                value={endValue}
                onChange={(event) => handleInputChange(event, setEndValue)}
              // onChange={(event) => {
              //   const value = event.target.value;
              //   if (value === '') {
              //     setEndValue(0); // Set to 0 or any default value you prefer
              //   } else if (!isNaN(parseInt(value))) {
              //     setEndValue(parseInt(value));
              //   }
              // }}
              />
            </div>
            <div style={{ marginRight: "4px" }}>Hours</div>
            <div>
              <div onClick={handleOptionsOpen} className="options">
                {selectedOption}
                {optionsOpen ? (
                  <i className="m-2 fas fa-angle-down rotate"></i>
                ) : (
                  <i className="m-2 fas fa-angle-down"></i>
                )}
                {optionsOpen && (
                  <div >
                    {options.map((option) => (
                      <div
                        key={option}
                        className={selectedOption === option ? "selected" : ""}
                        onClick={() => handleOptionSelect(option)}

                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </>
  );
}
