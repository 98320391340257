import { TextField, Button } from "@mui/material";
import Alert from '@mui/material/Alert';
import { Check } from "@mui/icons-material";
import { useState } from "react";
import axios from "axios";
import { url } from "../../url";
import styles from "./FeedbackForm.module.scss";

const FeedbackForm = () => {
  const [state, setState] = useState({
    description: "",
    email: "",
  });

  const [showAlert, setShowAlert] = useState(false);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const onClickButton = async () => {
    try{ 
      const response = await axios.post(
        url + "/feedback",
        {
          email: state.email,
          description: state.description
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setShowAlert(true);
      setState((prevState)=>{
        return {
          ...prevState,
          description: "",
          email: ""
        }
      });
      setTimeout(() => setShowAlert(false), 3500);
    }
    catch(err){
      console.log("Error sending feedback", err);
    }
  };

  return (
    <>
    <div className="form-container">

      <form className="form-3d">
        <div className="form-group">
          <TextField
            label="Description"
            multiline
            rows={10}
            variant="outlined"
            fullWidth
            id="description"
            name="description"
            onChange={onChangeInput}
            value={state.description}
          />
        </div>
        <div className="form-group-email">
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            id="email"
            name="email"
            onChange={onChangeInput}
            value={state.email}
          />
        </div>
        <div className="form-group button-container">
          <Button className="form-button" onClick={onClickButton}>
            Submit
          </Button>
        </div>
      </form>
    </div>
    {showAlert && (
        <div className={styles["alert-container"]}>
          <Alert icon={<Check fontSize="inherit" />} severity="success">
            <h4>Successfully sent feedback!</h4>
          </Alert>
        </div>
    )}
    </>
  );
};

export default FeedbackForm;
