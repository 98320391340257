import { useState } from "react";
import "../../sass/components/Filters/RequiredBizOrTechRoleFilter.scss";

export default function RequiredBizOrTechRoleFilter({
  leftValue,
  setLeftValue,
  rightValue,
  setRightValue,
  open,
  setOpen,
}: {
  leftValue: string;
  setLeftValue: (newState: string) => void;
  rightValue: string;
  setRightValue: (newState: string) => void;
  open: boolean;
  setOpen: (key: string) => void;
}) {
  // const [open, setOpen] = useState<boolean>(false);
  //   const [leftValue, setLeftValue] = useState<string>("");
  //   const [rightValue, setRightValue] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [title, setTitle] = useState<string>("Biz/Tech Acumen"); // Initial title

  const handleOpen = () => {
    setOpen("bizOrTech");
  };

  const updateValues = (value: string) => {
    const parsedValue = parseInt(value);
    if (parsedValue < 0) {
      setLeftValue("0");
      setRightValue("100");
    } else if (parsedValue > 100) {
      setLeftValue("100");
      setRightValue("0");
    } else if (!isNaN(parsedValue)) {
      setLeftValue(value);
      setRightValue((100 - parsedValue).toString());
      setCount(parsedValue);
      setTitle(`Required Biz Or Tech Role (1)`); // Always set the title to (1) when there's an input value
    } else {
      setLeftValue("");
      setRightValue("");
      setCount(1);
      setTitle("Required Biz Or Tech Role"); // Reset the title when the input is cleared
    }
  };

  const leftIncDec = () => {
    handleIncrement(-1);
  };

  const rightIncDec = () => {
    handleIncrement(1);
  };

  const handleIncrement = (incrementValue: number) => {
    let newCount = count + incrementValue;
    if (newCount < 0) {
      newCount = 0;
    } else if (newCount > 100) {
      newCount = 100;
    }
    updateValues(newCount.toString());
  };

  return (
    <div>
      <div className="component" style={{position: "relative"}}>
        <div onClick={handleOpen} className="heading">
          <p className="title">{title}</p>
          <p>
            {open ? (
              <i className="fas fa-angle-down rotate"></i>
            ) : (
              <i className="fas fa-angle-down"></i>
            )}
          </p>
        </div>
        {open && (
          <div className="show_component" style={{display:"flex",justifyContent:"center"}}>
            <ul className="ul_design">
              <li>Biz </li>
              <li>
                <input
                  type="text"
                  className="text_input"
                  value={leftValue}
                  onChange={(e) => updateValues(e.target.value)}
                />
              </li>
              <li style={{ marginRight: "4px" }}>%</li>
              <li>
                <button className="angle_btn_design" onMouseOver={leftIncDec}>
                  <i className="fas fa-angle-left left-arrow-design"></i>
                </button>
              </li>
              <li>
                <button className="angle_btn_design" onMouseOver={rightIncDec}>
                  <i className="fas fa-angle-right left-arrow-design"></i>
                </button>
              </li>
              <li style={{ marginLeft: "4px" }}>Tech</li>
              <li>
                <input
                  type="text"
                  className="text_input"
                  value={rightValue}
                  onChange={(e) =>
                    updateValues((100 - parseInt(e.target.value)).toString())
                  }
                />
              </li>
              <li style={{ marginRight: "4px" }}>%</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
