import { atom } from "jotai";

const pageAtom = atom(0);
pageAtom.debugLabel = "Page";

const openAtom = atom({
    toolsExperience: false,
    productType: false,
    projectType: false,
    offeredGratification: false,
    forGratification: false,
    softSkills: false,
    requiredAreaOfExpertise: false,
    projectDeadline: false,
    hardSkills: false,
    offeredWorkingType: false,
    usedLanguages: false,
    bizOrTech: false,
    requiredAvailability: false,
    locations: false,
  });

openAtom.debugLabel="Add Project Open Filters";

export { pageAtom, openAtom };
