import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import ProjectBox from "../../components/Boxs/ProjectBox";
import Page from "../../components/Page";
import Sidebar from "../../components/Sidebar";
import SidebarDrawer from "../../components/SidebarDrawer";
import { url } from "../../url";
import { projectsAtom } from "../Projects/atoms/ProjectAtomGlobal";
import { userProjectsAtom } from "../Projects/atoms/UserProjectAtomGlobal";
import styles from "./project.module.scss"; // to fix
const FoundProjects = () => {
  const [projects, setProjects] = useAtom(projectsAtom);
  const [userProjects, setUserProjects] = useAtom(userProjectsAtom);
  // const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // Infinite scroll
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(2);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const projects = await axios.get(url + "/project", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setProjects(projects.data.projects);

      const token = localStorage.getItem("token");
      if (token) {
        const userProjects = await axios.get(
          url + `/user/projects/found?page=${currentPage}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserProjects(userProjects.data.projects);
        const morePages = userProjects.data.projectPages == 1 ? false : true;
        if (!morePages) {
          setHasMore(false);
        }
      }
    };
    fetchData()
  }, [currentPage, setProjects, setUserProjects]);

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  const fetchMoreProjects = async () => {
    await axios
      .get(url + `/user/projects/found?page=${currentPage}`)
      .then((res) => {
        const projectsArr = res.data.projects;
        setProjects((prevProjects: any[]) => [...prevProjects, ...projectsArr]);

        projectsArr.length > 0 ? setHasMore(true) : setHasMore(false);
      })
      .catch((err) => console.error(err));

    setIndex((prevIndex) => prevIndex + 1);
  };

  // const tokenAvailable = () => {
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     return true;
  //   }
  //   return false;
  // };

  const deleteFoundedProject = async (project: any) => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await axios.delete(url + `/user/projects/${project._id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setUserProjects(
          userProjects.filter(function (p: any) {
            return p !== project._id;
          })
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <Page>
      <div className="foundSkillers">
        <header className="foundSkillers__header">
          <div className="foundSkillers__header__sidebar">
            <SidebarDrawer />
          </div>
        </header>
        <div className="foundSkillers__container">
          <div className="foundSkillers__container__sidebar">
            <Sidebar />
          </div>
          <div className="foundSkillers__container__skills">
            <InfiniteScroll
              dataLength={projects.length}
              next={fetchMoreProjects}
              hasMore={hasMore}
              loader={<CircularProgress />}
            >
              {projects.map((project: any) => {
                if (userProjects.includes(project._id)) {
                  return (
                    <ProjectBox
                      project={project}
                      tokenAvailable={true}
                      deleteProject={() => deleteFoundedProject(project)}
                      hidingBox={true}
                      projectAdded={true}
                      myProject={false}
                    />
                  );
                }
              })}
              {userProjects.length === 0 && (
                <div className={styles.noContentBox}>
                  <p className={styles.noContentText}>No projects added...</p>
                  <Button
                    onClick={() => navigate("/projects")}
                    variant="contained"
                    size="small"
                    style={{ textTransform: "none" }}
                  >
                    Add some
                  </Button>
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default FoundProjects;
