import { atom } from "jotai";
import tools_tools from "../../../data/projects/enums/projects_tools_tools";

const projectNameAtom = atom("");
projectNameAtom.debugLabel = "Project Name";

const toolsExperienceAtom = atom([]);
toolsExperienceAtom.debugLabel = "Tools Experience";

const toolsExperienceOptionViewsAtom = atom(
  tools_tools.reduce((acc: any, option: string) => {
    acc[option] = 0; // Initialize views for each option to 1
    return acc;
  }, {}),
);
toolsExperienceOptionViewsAtom.debugLabel = "Tools Experience Option Views";

const projectTypeAtom = atom([]);
projectTypeAtom.debugLabel = "Project Type";

const productTypeAtom = atom([]);
productTypeAtom.debugLabel = "Product Type";

const offeredGratificationAtom = atom([]);
offeredGratificationAtom.debugLabel = "Offered Gratification";

const forGratificationAtom = atom([]);
forGratificationAtom.debugLabel = "For Gratification";

const softSkillsAtom = atom([]);
softSkillsAtom.debugLabel = "Required Soft Skills";

const requiredAreaOfExpertiseAtom = atom([]);
requiredAreaOfExpertiseAtom.debugLabel = "Required Area of Expertise";

export {
  projectNameAtom,
  toolsExperienceAtom,
  toolsExperienceOptionViewsAtom,
  productTypeAtom,
  projectTypeAtom,
  offeredGratificationAtom,
  softSkillsAtom,
  requiredAreaOfExpertiseAtom,
  forGratificationAtom,
};
