import React from 'react';
import Page from '../../components/Page';

const NotFound: React.FC = () => {
    return (
        <Page>
            <div className="nofound-page">
                <div className="message">
                    <h1>404 - Page Not Found</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                    <a href="/">Go to Homepage</a>
                </div>
            </div>
        </Page>
    );  
};


export default NotFound;