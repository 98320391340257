import React from "react";

const Message = ({myMessage,content,time}: {myMessage: boolean;content: string;time: string})=>{
    return (
        <> 
        {
            myMessage ? 
            (
              <div className="conversations__container__chat__box__right">
                <p>{content}</p>
                <span>{time}</span>
              </div>
            ) 
            : 
            (
              <div className="conversations__container__chat__box__left">
                <p>{content}</p>
                <span>{time}</span>
              </div>
            )
        }
        </>
    );
};

export default Message;