import React from "react";
import Page from "../../components/Page";
import HomeCard from "./Components/HomeCard";

const Home = () => {
  return (
    <Page>
      <section className="home">
        <div className="home__container">
          <div className="home__container__left">
            <p>Welcome to</p>
            <h3>
              Slinge<b>rr</b>
            </h3>
            <span>Your projects home</span>
          </div>
          <div className="home__container__right">
            <HomeCard
              cardNumber={1}
              title={"Leader"}
              description={"Find Teammates for your Project"}
            />
            <HomeCard
              cardNumber={2}
              title={"Teammate"}
              description={"Join to existing Project"}
            />
          </div>
        </div>
      </section>
    </Page>
  );
};

export default Home;
