import { useAtomValue } from "jotai";

import {
  toolsExperienceAtom,
  toolsExperienceOptionViewsAtom,
  projectTypeAtom,
  offeredGratificationAtom,
  forGratificationAtom,
  hardSkillsAtom,
  requiredAreaOfExpertiseAtom,
  projectDeadlineAtom,
  softSkillsAtom,
  offeredWorkingTypeAtomNewArray,
  offeredWorkingTypeActiveOptionsAtom,
  usedLanguagesAtom,
  bizOrTechRoleAtomLeftValue,
  bizOrTechRoleAtomRightValue,
  requiredAvailabilityAtomStartValue,
  requiredAvailabilityAtomEndValue,
  requiredAvailabilityAtomOption,
  locationsAtom,
} from "../components/TeammateFilters/atom/TeammateFiltersAtom";

interface reqBody {
  hardSkills?: string[];
  areaOfExpertise?: string[];
  toolsExperience?: any;
  softSkills?: string[];
  offeredGratification?: string[];
  forGratification?: string[];
  projectType?: string[];
  deadline?: Date;
  bizRole?: number;
  techRole?: number;
  availabilityFrom?: number;
  availabilityTo?: number;
  availabilityType?: string;
  workingTypeOffice?: boolean;
  workingTypeRemote?: boolean;
  usedLanguages?: string[];
  description?: string;
  locations?: string[];
}

const useDataFromTeammateFilters = () => {
  const toolsExperience = useAtomValue<string[]>(toolsExperienceAtom);
  const toolsExperienceOptionViews = useAtomValue(
    toolsExperienceOptionViewsAtom,
  );
  const projectType = useAtomValue<string[]>(projectTypeAtom);
  const offeredGratification = useAtomValue<string[]>(offeredGratificationAtom);
  const hardSkills = useAtomValue<string[]>(
    hardSkillsAtom,
  );
  const requiredAreaOfExpertise = useAtomValue<string[]>(
    requiredAreaOfExpertiseAtom,
  );
  const forGratification = useAtomValue<string[]>(forGratificationAtom);
  const projectDeadline = useAtomValue(projectDeadlineAtom);
  const softSkills = useAtomValue<string[]>(softSkillsAtom);
  const offeredWorkingTypeActiveOptions = useAtomValue<boolean[]>(
    offeredWorkingTypeActiveOptionsAtom,
  );
  const usedLanguages = useAtomValue<string[]>(usedLanguagesAtom);
  const bizOrTechRoleLeftValue = useAtomValue(bizOrTechRoleAtomLeftValue);
  const bizOrTechRoleRightValue = useAtomValue(bizOrTechRoleAtomRightValue);
  const requiredAvailabilityStartValue = useAtomValue(
    requiredAvailabilityAtomStartValue,
  );
  const requiredAvailabilityEndValue = useAtomValue(
    requiredAvailabilityAtomEndValue,
  );
  const requiredAvailabilityOption = useAtomValue(
    requiredAvailabilityAtomOption,
  );
  const locations = useAtomValue(locationsAtom);

  let body: reqBody = {};
  body.hardSkills = hardSkills;
  body.toolsExperience = [];
  body.projectType = projectType;
  body.offeredGratification = offeredGratification;
  body.forGratification = forGratification;
  body.areaOfExpertise = requiredAreaOfExpertise;
  body.deadline = projectDeadline;
  body.softSkills = softSkills;
  body.workingTypeRemote = offeredWorkingTypeActiveOptions[0];
  body.workingTypeOffice = offeredWorkingTypeActiveOptions[1];
  body.usedLanguages = usedLanguages;
  body.bizRole =
    bizOrTechRoleLeftValue == "" ? 0 : parseInt(bizOrTechRoleLeftValue);
  body.techRole =
    bizOrTechRoleRightValue == "" ? 0 : parseInt(bizOrTechRoleRightValue);
  body.availabilityFrom = requiredAvailabilityStartValue;
  body.availabilityTo = requiredAvailabilityEndValue;
  body.availabilityType = requiredAvailabilityOption;
  body.locations = locations;

  const keys = Object.keys(body);
  for (const key of keys) {
    if (!body[key as keyof reqBody]) {
      delete body[key as keyof reqBody];
    }
  } // is array empty we can do sth like that

  for (const tool of toolsExperience) {
    body.toolsExperience.push({
      tool: tool,
      mep: toolsExperienceOptionViews[tool],
    });
  }

  let stringToReturn = "";

  for (const key of Object.keys(body)) {
    if (key == "toolsExperience" && body[key as keyof reqBody].length) {
      stringToReturn += key + "=" + JSON.stringify(body[key as keyof reqBody]);
      stringToReturn += "&";
    } else if (
      Array.isArray(body[key as keyof reqBody]) &&
      body[key as keyof reqBody].length
    ) {
      stringToReturn += key + "=" + JSON.stringify(body[key as keyof reqBody]);
      // for(const element of body[key as keyof(reqBody)]){
      //     stringToReturn+=element+",";
      // }
      stringToReturn += "&";
    } else {
      if (
        typeof body[key as keyof reqBody] == "string" &&
        body[key as keyof reqBody] != ""
      ) {
        stringToReturn += key + "=" + body[key as keyof reqBody];
        stringToReturn += "&";
      } else if (
        typeof body[key as keyof reqBody] != "string" &&
        !Array.isArray(body[key as keyof reqBody])
      ) {
        switch (typeof body[key as keyof reqBody]) {
          case "object": {
            // date
            if (
              new Date(body[key as keyof reqBody]).getTime() !=
              new Date(0).getTime()
            ) {
              stringToReturn +=
                key + "=" + new Date(body[key as keyof reqBody]).toISOString();
              stringToReturn += "&";
            }
            break;
          }
          case "number": {
            if (body[key as keyof reqBody]) {
              stringToReturn += key + "=" + body[key as keyof reqBody];
              stringToReturn += "&";
            }
            break;
          }
          case "boolean": {
            if (body[key as keyof reqBody]) {
              stringToReturn += key + "=" + body[key as keyof reqBody];
              stringToReturn += "&";
            }
            break;
          }
        }
      }
    }
  }
  return stringToReturn;
};

export { useDataFromTeammateFilters };
