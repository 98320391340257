import { atom } from "jotai";

const usedLanguagesAtom = atom([]);
usedLanguagesAtom.debugLabel = "Used Languages";

const locationsAtom = atom([]);
locationsAtom.debugLabel = "Locations";

const bizOrTechRoleAtomLeftValue = atom("");
bizOrTechRoleAtomLeftValue.debugLabel = "Required Biz Or Tech Role Left Value";

const bizOrTechRoleAtomRightValue = atom("");
bizOrTechRoleAtomRightValue.debugLabel =
  "Required Biz Or Tech Role Right Value";

const requiredAvailabilityAtomStartValue = atom(10);
requiredAvailabilityAtomStartValue.debugLabel =
  "Required Availability Start Value";

const requiredAvailabilityAtomEndValue = atom(15);
requiredAvailabilityAtomEndValue.debugLabel = "Required Availability End Value";

const requiredAvailabilityAtomOption = atom("Monthly");
requiredAvailabilityAtomOption.debugLabel = "Required Availability Option";

export {
  usedLanguagesAtom,
  bizOrTechRoleAtomLeftValue,
  bizOrTechRoleAtomRightValue,
  requiredAvailabilityAtomStartValue,
  requiredAvailabilityAtomEndValue,
  requiredAvailabilityAtomOption,
  locationsAtom,
};
