import { atom } from "jotai";
import tools_tools from "../../../data/projects/enums/projects_tools_tools";

const toolsExperienceAtom = atom([]);
toolsExperienceAtom.debugLabel = "Tools Experience";

const toolsExperienceOptionViewsAtom = atom(
  tools_tools.reduce((acc: any, option: string) => {
    acc[option] = 1; // Initialize views for each option to 1
    return acc;
  }, {}),
);

const inputTextSearchAtom = atom("");
inputTextSearchAtom.debugLabel = "Input Text Search";
const labelsAtom = atom<string[]>([]);
labelsAtom.debugLabel = "Predicted Labels";

toolsExperienceOptionViewsAtom.debugLabel = "Tools Experience Option Views";

const projectTypeAtom = atom([]);
projectTypeAtom.debugLabel = "Project Type";

const offeredGratificationAtom = atom([]);
offeredGratificationAtom.debugLabel = "Offered Gratification";

const forGratificationAtom = atom([]);
forGratificationAtom.debugLabel = "For Gratification";

const hardSkillsAtom = atom([]);
hardSkillsAtom.debugLabel = "Required Project Experience";

const requiredAreaOfExpertiseAtom = atom([]);
requiredAreaOfExpertiseAtom.debugLabel = "Required Area of Expertise";

const projectDeadlineAtom = atom(new Date(0));
projectDeadlineAtom.debugLabel = "Project Deadline";

const softSkillsAtom = atom([]);
softSkillsAtom.debugLabel = "Soft Skills";

const offeredWorkingTypeAtomNewArray = atom([]);
offeredWorkingTypeAtomNewArray.debugLabel = "Offered Working Type";

const offeredWorkingTypeActiveOptionsAtom = atom([]);
offeredWorkingTypeActiveOptionsAtom.debugLabel =
  "Offered Working Type Active Options";

const usedLanguagesAtom = atom([]);
usedLanguagesAtom.debugLabel = "Used Languages";

const locationsAtom = atom([]);
locationsAtom.debugLabel = "Locations";

const bizOrTechRoleAtomLeftValue = atom("");
bizOrTechRoleAtomLeftValue.debugLabel = "Required Biz Or Tech Role Left Value";

const bizOrTechRoleAtomRightValue = atom("");
bizOrTechRoleAtomRightValue.debugLabel =
  "Required Biz Or Tech Role Right Value";

const requiredAvailabilityAtomStartValue = atom(0);
requiredAvailabilityAtomStartValue.debugLabel =
  "Required Availability Start Value";

const requiredAvailabilityAtomEndValue = atom(0);
requiredAvailabilityAtomEndValue.debugLabel = "Required Availability End Value";

const requiredAvailabilityAtomOption = atom("");
requiredAvailabilityAtomOption.debugLabel = "Required Availability Option";

const openAtom = atom({
  inputTextSearch: false,
  toolsExperience: false,
  projectType: false,
  offeredGratification: false,
  forGratification: false,
  hardSkills: false,
  requiredAreaOfExpertise: false,
  projectDeadline: false,
  softSkills: false,
  offeredWorkingType: false,
  usedLanguages: false,
  bizOrTech: false,
  requiredAvailability: false,
  locations: false,
});

openAtom.debugLabel = "Open Teammate Filters"

export {
  labelsAtom,
  inputTextSearchAtom,
  toolsExperienceAtom,
  toolsExperienceOptionViewsAtom,
  projectTypeAtom,
  offeredGratificationAtom,
  forGratificationAtom,
  hardSkillsAtom,
  requiredAreaOfExpertiseAtom,
  projectDeadlineAtom,
  softSkillsAtom,
  offeredWorkingTypeAtomNewArray,
  offeredWorkingTypeActiveOptionsAtom,
  usedLanguagesAtom,
  bizOrTechRoleAtomLeftValue,
  bizOrTechRoleAtomRightValue,
  requiredAvailabilityAtomStartValue,
  requiredAvailabilityAtomEndValue,
  requiredAvailabilityAtomOption,
  locationsAtom,
  openAtom
};
