import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import Sidebar from "../../components/Sidebar";
import SidebarDrawer from "../../components/SidebarDrawer";
import TeammatesDetails from "../../components/InputFields/TeammatesDetails";
import { useNavigate } from "react-router-dom";
import { url } from "../../url";
import axios from "axios";
import { createdProjectsAtom } from "../Projects/atoms/CreatedProjectAtomGlobal";
import { useAtom } from "jotai";
// import CreatedProjectBox from "../../components/Boxs/CreatedProjectBox";
// import Pagination from "@mui/material/Pagination";
import ProjectBox from "../../components/Boxs/ProjectBox";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from '@mui/material/CircularProgress';

const MyProject = () => {
  const [createdProjects, setCreatedProjects] = useAtom(createdProjectsAtom);
  // const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // Infinite scroll
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(2);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const myProjects = await axios.get(
        url + `/user/myProjects?page=${currentPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setCreatedProjects(myProjects.data.UserProjects);
      const morePages = myProjects.data.myProjectsPages == 1 ? false : true;
      if (!morePages){
        setHasMore(false);
      }
    };
    fetchData();
  }, [currentPage, setCreatedProjects]);

  const navigate = useNavigate();

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  const goToCreateProject = () => {
    navigate("/project/add");
  };

  const navigateToEdit = (project: any) => {
    navigate(`/project/editProject/${project._id}`);
  };

  const fetchMoreProjects = async () => {
    await axios.get(url + `/user/myProjects?page=${currentPage}`)
    .then((res) => {
      const myProjectsArr = res.data.UserProjects;
      setCreatedProjects((prevProjects: any[]) => [...prevProjects, ...myProjectsArr]);

      myProjectsArr.length > 0 ? setHasMore(true) : setHasMore(false);
    }).catch((err) => console.error(err));
    setIndex((prevIndex) => prevIndex + 1);
  };

  const deleteMyProject = async (project: any) => {
    const token = localStorage.getItem("token");
    try {
      await axios.delete(url + "/user/myProjects", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          project_id: project._id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Page>
      <div className="myProjects">
        <header className="myProjects__header">
          <div className="myProjects__header__sidebar">
            <SidebarDrawer />
          </div>
          <div>
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall projectsheaderright__filterButton css-11qr2p8-MuiButtonBase-root-MuiButton-root"
              onClick={() => goToCreateProject()}
            >
              Add
            </button>
          </div>
        </header>
        <div className="myProjects__container">
          <div className="myProjects__container__sidebar">
            <Sidebar />
          </div>
          <div className="myProjects__container__savedProjects">
            <InfiniteScroll
              dataLength={createdProjects.length}
              next={fetchMoreProjects}
              hasMore={hasMore}
              loader={<CircularProgress/>}
            >
            {createdProjects.map((project: any) => {
              return (
                <ProjectBox
                  tokenAvailable={false}
                  key={project._id}
                  project={project}
                  hidingBox={true}
                  deleteProject={() => deleteMyProject(project)}
                  navigateToEdit={() => navigateToEdit(project)}
                  myProject={true}
                />
              );
            })}
            </InfiniteScroll>
            {/* <div className="myProjects__container__savedProjects__message">
            </div> */}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default MyProject;