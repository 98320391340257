import { useAtom, useSetAtom, useAtomValue } from "jotai";
import {
  labelsAtom,
  inputTextSearchAtom,
  toolsExperienceAtom,
  toolsExperienceOptionViewsAtom,
  projectTypeAtom,
  offeredGratificationAtom,
  forGratificationAtom,
  hardSkillsAtom,
  requiredAreaOfExpertiseAtom,
  projectDeadlineAtom,
  softSkillsAtom,
  offeredWorkingTypeAtomNewArray,
  offeredWorkingTypeActiveOptionsAtom,
  usedLanguagesAtom,
  bizOrTechRoleAtomLeftValue,
  bizOrTechRoleAtomRightValue,
  requiredAvailabilityAtomStartValue,
  requiredAvailabilityAtomEndValue,
  requiredAvailabilityAtomOption,
  locationsAtom,
  openAtom
} from "./atom/TeammateFiltersAtom";

import "../../sass/components/ProjectFilters/Layout.scss";
import ToolsExperienceFilter from "../Filters_Teammates/ToolsExperienceFilter";
import ProjectType from "../Filters_Teammates/ProjectTypeFilter";
import OfferredGratification from "../Filters_Teammates/OfferedGratificationFilter";
import ForGratification from "../Filters_Teammates/ForGratificationFilter";
import AreaOfExperties from "../Filters_Teammates/AreaOfExpertiesFilter";
import ProjectDeadline from "../Filters_Teammates/ProjectDeadlineFilter";
import OfferedWorkingType from "../Filters_Teammates/OfferedWorkingTypeFilter";
import Location from "../Filters_Teammates/LocationFilter";
import UsedLanguages from "../Filters_Teammates/UsedLanguagesFilter";
import Availability from "../Filters_Teammates/RequiredAvailabilityFilter";
import BizOrTechRole from "../Filters_Teammates/RequiredBizOrTechRoleFilter";
import { Button } from "@mui/material";
import { useDataFromTeammateFilters } from "../../utils/useDataFromTeammateFilters";
import { url } from "../../url";
import axios from "axios";
import tools_tools from "../../data/projects/enums/projects_tools_tools";
import { teammatesAtom } from "../../pages/Teammate/atoms/TeammateAtomGlobal";
import {useOpenCloseFiltersFromTeammateFilters} from "../../utils/useOpenCloseFiltersFromTeammateFilters";
import HardSkillsFilter from "../Filters_Teammates/HardSkillsFilter";
import SoftSkillsFilter from "../Filters_Teammates/SoftSkillsFilter";
import { url_predict } from "../../url";

const TeammateFilters = () => {
  const [labels, setLabels] = useAtom(labelsAtom);  // Store predicted labels
  const [inputTextSearch, setInputTextSearch] = useAtom(inputTextSearchAtom);  // Search text input
  
  const handleSearch = async () => {
    if (inputTextSearch.trim() !== "") {
      try {

        //  LOCAL
        // const response = await axios.post(url_predict + "/predict", {
        //   input_text: inputTextSearch,
        //   len_2_cnt: 2
        // });

        // DEPLOY
        const response = await axios.post("https://search-filter-model-232159043195.europe-central2.run.app/predict", {
          input_text: inputTextSearch,
          len_2_cnt: 2
        });
        
        const result = response.data.similar_labels_dict;
        setLabels(result);  // Store full response for display
        
        // NEW: Update filters dynamically with API response
        updateFilters(result);
  
        console.log("Predicted Labels Dict:", result);
      } catch (error) {
        console.error('Error fetching predictions:', error);
      }
    }
  };
  
  // NEW: Map API response to filter atoms
  const updateFilters = (result: Record<string, string[]>) => {
    const filterMapping: Record<string, any> = {
      toolsExperience: setToolsExperience,
      projectType: setProjectType,
      hardSkills: setHardSkills,
      softSkills: setSoftSkills,
      requiredAreaOfExpertise: setRequiredAreaOfExpertise,
      offeredGratification: setOfferedGratification,
      forGratification: setForGratification,
      usedLanguages: setUsedLanguages,
      locations: setLocations,
    };
  
    Object.entries(result).forEach(([key, values]) => {
      if (filterMapping[key]) {
        console.log(`Updating ${key} with`, values);  // Debug
        filterMapping[key]((prev: string[]) => [...values]);  // Spread to trigger re-render
      } else {
        console.warn(`No atom found for key: ${key}`);  // Warn if unmapped key
      }
    });
  };



  const [toolsExperience, setToolsExperience] = useAtom(toolsExperienceAtom);
  const [toolsExperienceOptionViews, setToolsExperienceOptionViews] = useAtom(
    toolsExperienceOptionViewsAtom,
  );
  const [projectType, setProjectType] = useAtom(projectTypeAtom);
  const [offeredGratification, setOfferedGratification] = useAtom(
    offeredGratificationAtom,
  );
  const [hardSkills, setHardSkills] = useAtom(
    hardSkillsAtom,
  );
  const [requiredAreaOfExpertise, setRequiredAreaOfExpertise] = useAtom(
    requiredAreaOfExpertiseAtom,
  );
  const [forGratification, setForGratification] = useAtom(forGratificationAtom);
  const [projectDeadline, setProjectDeadline] = useAtom(projectDeadlineAtom);
  const [softSkills, setSoftSkills] = useAtom(softSkillsAtom);
  const [offeredWorkingTypeNewArray, setOfferedWorkingTypeNewArray] = useAtom(
    offeredWorkingTypeAtomNewArray,
  );
  const [offeredWorkingTypeActiveOptions, setOfferedWorkingTypeActiveOptions] =
    useAtom(offeredWorkingTypeActiveOptionsAtom);
  const [usedLanguages, setUsedLanguages] = useAtom(usedLanguagesAtom);
  const [bizOrTechRoleLeftValue, setBizOrTechRoleLeftValue] = useAtom(
    bizOrTechRoleAtomLeftValue,
  );
  const [bizOrTechRoleRightValue, setBizOrTechRoleRightValue] = useAtom(
    bizOrTechRoleAtomRightValue,
  );
  const [requiredAvailabilityStartValue, setRequiredAvailabilityStartValue] =
    useAtom(requiredAvailabilityAtomStartValue);
  const [requiredAvailabilityEndValue, setRequiredAvailabilityEndValue] =
    useAtom(requiredAvailabilityAtomEndValue);
  const [requiredAvailabilityOption, setRequiredAvailabilityOption] = useAtom(
    requiredAvailabilityAtomOption,
  );
  const [locations, setLocations] = useAtom(locationsAtom);

  const openFilters = useAtomValue(openAtom);
  const enableAndResetFilters = useOpenCloseFiltersFromTeammateFilters();

  const setTeammates = useSetAtom(teammatesAtom);
  const query = useDataFromTeammateFilters();

  const applyFilters = async () => {
    console.log(query);
    const res = await axios.get(url + "/teammate?page=1&" + query, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    setTeammates(res.data.teammates);
  };

  const clearFilters = async () => {
    setToolsExperience([]);
    setToolsExperienceOptionViews(
      tools_tools.reduce((acc: any, option: string) => {
        acc[option] = 1; // Initialize views for each option to 1
        return acc;
      }, {}),
    );
    setProjectType([]);
    setOfferedGratification([]);
    setHardSkills([]);
    setRequiredAreaOfExpertise([]);
    setForGratification([]);
    setProjectDeadline(new Date(0));
    setSoftSkills([]);
    setOfferedWorkingTypeNewArray([]);
    setOfferedWorkingTypeActiveOptions([]);
    setUsedLanguages([]);
    setBizOrTechRoleLeftValue("");
    setBizOrTechRoleRightValue("");
    setRequiredAvailabilityStartValue(0);
    setRequiredAvailabilityEndValue(0);
    setRequiredAvailabilityOption("");
    setLocations([]);

    const res = await axios.get(url + "/teammate?page=1", {
      headers: {
        "Content-Type": "application/json",
      },
    });

    setTeammates(res.data.teammates);
  };

  return (
    <div className="projects__body__container__left__card">
      
      <div className='filter-container'>
  <div className="search-bar-container">
    <input
      type="text"
      value={inputTextSearch}
      onChange={(e) => setInputTextSearch(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") handleSearch();  // Trigger search on Enter
      }}
      placeholder="Write what you want to build, who do you need, what skills, whatever..."
    />
    <button onClick={handleSearch}>Adjust Filters</button>
  </div>
</div>

      <div className="filter-container">
      <div className={`filter-box-container ${toolsExperience.length > 0 ? 'selected' : ''}`}>
          <ToolsExperienceFilter
            pickedOptions={toolsExperience}
            setPickedOptions={setToolsExperience}
            optionViews={toolsExperienceOptionViews}
            setOptionViews={setToolsExperienceOptionViews}
            show={openFilters.toolsExperience}
            setShow={enableAndResetFilters}
            displayColumn={true}
          />
        </div>

        <div className={`filter-box-container ${requiredAreaOfExpertise.length > 0 ? 'selected' : ''}`}>
          <AreaOfExperties
            pickedOptions={requiredAreaOfExpertise}
            setPickedOptions={setRequiredAreaOfExpertise}
            show={openFilters.requiredAreaOfExpertise}
            setShow={enableAndResetFilters}
            displayColumn={true}

          />
        </div>

        <div className={`filter-box-container ${projectType.length > 0 ? 'selected' : ''}`}>
          <ProjectType
            pickedOptions={projectType}
            setPickedOptions={setProjectType}
            show={openFilters.projectType}
            setShow={enableAndResetFilters}
            displayColumn={true}
          />
        </div>

        <div className={`filter-box-container ${hardSkills.length > 0 ? 'selected' : ''}`}>
          <HardSkillsFilter
            pickedOptions={hardSkills}
            setPickedOptions={setHardSkills}
            show={openFilters.hardSkills}
            setShow={enableAndResetFilters}
            displayColumn={true}
          />
        </div>

        <div className={`filter-box-container ${offeredGratification.length > 0 ? 'selected' : ''}`}>
          <OfferredGratification
            pickedOptions={offeredGratification}
            setPickedOptions={setOfferedGratification}
            show={openFilters.offeredGratification}
            setShow={enableAndResetFilters}
            displayColumn={true}
          />
        </div>

        <div className={`filter-box-container ${forGratification.length > 0 ? 'selected' : ''}`}>
          <ForGratification
            pickedOptions={forGratification}
            setPickedOptions={setForGratification}
            show={openFilters.forGratification}
            setShow={enableAndResetFilters}
            displayColumn={true}
          />
        </div>

        <div className={`filter-box-container ${projectDeadline.length > 0 ? 'selected' : ''}`}>
          <ProjectDeadline
            dateState={projectDeadline}
            setDateState={setProjectDeadline}
            open={openFilters.projectDeadline}
            setOpen={enableAndResetFilters}
          />
        </div>

        <div className={`filter-box-container ${softSkills.length > 0 ? 'selected' : ''}`}>
          <SoftSkillsFilter
            pickedOptions={softSkills}
            setPickedOptions={setSoftSkills}
            show={openFilters.softSkills}
            setShow={enableAndResetFilters}
            displayColumn={true}
          />
        </div>

        <div className={`filter-box-container ${bizOrTechRoleAtomLeftValue.length > 0 ? 'selected' : ''}`}>
          <BizOrTechRole
            leftValue={bizOrTechRoleLeftValue}
            rightValue={bizOrTechRoleRightValue}
            setLeftValue={setBizOrTechRoleLeftValue}
            setRightValue={setBizOrTechRoleRightValue}
            open={openFilters.bizOrTech}
            setOpen={enableAndResetFilters}
          />
        </div>

        <div className={`filter-box-container ${requiredAvailabilityStartValue || requiredAvailabilityEndValue ? 'selected' : ''}`}>
          <Availability
            startValue={requiredAvailabilityStartValue}
            setStartValue={setRequiredAvailabilityStartValue}
            endValue={requiredAvailabilityEndValue}
            setEndValue={setRequiredAvailabilityEndValue}
            selectedOption={requiredAvailabilityOption}
            setSelectedOption={setRequiredAvailabilityOption}
            open={openFilters.requiredAvailability}
            setOpen={enableAndResetFilters}
          />
        </div>

        <div className={`filter-box-container ${offeredWorkingTypeNewArray.length > 0 ? 'selected' : ''}`}>
          <OfferedWorkingType
            activeOptions={offeredWorkingTypeActiveOptions}
            setActiveOptions={setOfferedWorkingTypeActiveOptions}
            newArray={offeredWorkingTypeNewArray}
            setNewArray={setOfferedWorkingTypeNewArray}
            show={openFilters.offeredWorkingType}
            setShow={enableAndResetFilters}
          />
        </div>

        <div className={`filter-box-container ${locations.length>0 ? 'selected' : ''}`}>
          <Location 
            pickedOptions={locations} 
            setPickedOptions={setLocations} 
            show={openFilters.locations}
            setShow={enableAndResetFilters}
            displayColumn={true}
          />
        </div>
        
        <div className={`filter-box-container ${usedLanguages.length > 0 ? 'selected' : ''}`}>
          <UsedLanguages
            pickedOptions={usedLanguages}
            setPickedOptions={setUsedLanguages}
            show={openFilters.usedLanguages}
            setShow={enableAndResetFilters}
            displayColumn={true}
          />
        </div>
        
      </div>
      <div className="filter_buttons">
          <button onClick={() => {applyFilters()}} className="apply_button">Apply</button>
          <button onClick={clearFilters} className="clear_filters">Clear Filters</button>
      </div>
    </div>
  );
};

export default TeammateFilters;
