import { atom } from "jotai";

const projectDeadlineAtom = atom(new Date());
projectDeadlineAtom.debugLabel = "Project Deadline";

const hardSkillsAtom = atom([]);
hardSkillsAtom.debugLabel = "Project Branch";

const offeredWorkingTypeAtomNewArray = atom([]);
offeredWorkingTypeAtomNewArray.debugLabel = "Offered Working Type";

const offeredWorkingTypeActiveOptionsAtom = atom([]);
offeredWorkingTypeActiveOptionsAtom.debugLabel =
  "Offered Working Type Active Options";

const descriptionAtom = atom("");
descriptionAtom.debugLabel = "Description";

export {
  projectDeadlineAtom,
  hardSkillsAtom,
  offeredWorkingTypeAtomNewArray,
  offeredWorkingTypeActiveOptionsAtom,
  descriptionAtom
};
