import React, { useState } from "react";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import PlaylistAddTwoToneIcon from "@mui/icons-material/PlaylistAddTwoTone";
import { useNavigate } from "react-router";

const SkillCard = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const [cardColor, setCardColor] = useState(false);
  const navigate = useNavigate();
  const handleCardColorChange = () => {
    setCardColor(true);
  };

  const handleCardColorRemove = () => {
    setCardColor(false);
  };

  const handleNaigation = () => {
    const path =
      title === "Add Skill" ? "/skillers/add/addSkiller" : "/skillers";
    navigate(path);
  };

  return (
    <div
      onClick={handleNaigation}
      className={`homeCardsView__container__projectCards__card ${cardColor ? `homeCardsView__container__projectCards__cardSkill` : ""}`}
      onMouseEnter={handleCardColorChange}
      onMouseLeave={handleCardColorRemove}
    >
      <div className="homeCardsView__container__projectCards__card__iconBox">
        {title === "Skills" ? (
          <PlaylistAddTwoToneIcon className="homeCardsView__container__projectCards__cardSkill__iconBox__iconSkill" />
        ) : (
          <PlaylistAddTwoToneIcon
            className={`homeCardsView__container__projectCards__card__iconBox__icon ${cardColor ? `homeCardsView__container__projectCards__cardSkill__iconBox__iconSkill` : ""}`}
          />
        )}
      </div>
      <p>{title}</p>
      <span>{description}</span>
    </div>
  );
};

export default SkillCard;
