import { Button } from "@mui/material";
import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import Sidebar from "../../components/Sidebar";
import SidebarDrawer from "../../components/SidebarDrawer";
import { url } from "../../url";
import internal from "stream";

const Settings = () => {
  interface UserData {
    name: string;
    surname: string;
    email: string;
    phoneNumber: number;
  }
  const [userData, setUserData] = useState<UserData | null>(null);
  const get_user_data = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(url + "/user/settings", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setUserData(data.user);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    get_user_data();
  }, []);

  const update_user_credentials = () => {
    const token = localStorage.getItem("token");
    console.log(userData);
    try {
      const response = fetch(url + "/user/settings", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: userData?.name,
          surname: userData?.surname,
          email: userData?.email,
          phoneNumber: userData?.phoneNumber,
          // phone_number: userData?.phone_number
        }),
      });
      response.then((res) => res.json());
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData(
      (prevData) =>
        ({
          ...prevData,
          [name]: value,
        }) as UserData,
    );
  };
  const navigate = useNavigate();
  return (
    <Page>
      <div className="settings">
        <header className="settings__header">
          <div className="settings__header__sidebar">
            <SidebarDrawer />
          </div>
        </header>
        <div className="settings__container">
          <div className="settings__container__sidebar">
            <Sidebar />
          </div>
          <div className="settings__container__description">
            <div className="settings__container__description__box">
              <div className="settings__container__description__box__details">
                <div className="settings__container__description__box__details__input">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="name"
                    value={userData ? userData.name : ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="settings__container__description__box__details__input">
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="surname"
                    value={userData ? userData.surname : ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="settings__container__description__box__details__input">
                  <label>Mail</label>
                  <input
                    type="text"
                    name="email"
                    value={userData ? userData.email : ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="settings__container__description__box__details__input">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={userData ? userData.phoneNumber : ""}
                    placeholder="Text here"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="settings__container__description__box__saveButton">
                <Button
                  variant="contained"
                  onClick={() => {
                    update_user_credentials();
                    navigate("/settings/message");
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Settings;
