import { atom } from "jotai";

const usersAtom = atom([]);
usersAtom.debugLabel = "List of users for conversation";

const chosenUserAtom = atom<any>({});
chosenUserAtom.debugLabel = "User currently chosen for conversation";

const conversationAtom = atom<any>({});
conversationAtom.debugLabel = "Current Conversation";

export {
    usersAtom,
    chosenUserAtom,
    conversationAtom
}