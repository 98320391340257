import React, { useEffect, useState } from "react";
import projects_gratification_for from "../../data/projects/enums/teammate_works_for";
import StarIcon from '@mui/icons-material/Star';
import { url } from "../../url";
import Alert from '@mui/material/Alert';
import { Check } from "@mui/icons-material";
import styles from "./styles/alert.module.scss";
import { useLocation } from "react-router-dom";
import axios from "axios";
// import projects_gratification_for from "../../data/projects/enums/projects_gratification_for";

export default function ForGratification({
  pickedOptions,
  setPickedOptions,
  show,
  setShow,
  displayColumn
}: {
  pickedOptions: string[];
  setPickedOptions: (newState: any) => void;
  show: boolean;
  setShow: (key: string) => void;
  displayColumn: boolean
}) {
  // const [show, setShow] = useState<boolean>(false);
  const location = useLocation();
  const [originalOptions, setOriginalOptions] = useState<string[]>(
    projects_gratification_for,
  );
  const [allOptions, setAllOptions] = useState<string[]>(originalOptions);
  // const [pickedOptions, setPickedOptions] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [adding, setAdding] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (searchText === "") {
      const unpickedOptions = originalOptions.filter(
        (option) => !pickedOptions.includes(option),
      );
      setAllOptions(unpickedOptions);
      if(location.pathname === "/teammates/add"){
        setAdding(false);
      }
    } else {
      const filteredOptions = originalOptions.filter(
        (option) =>
          !pickedOptions.includes(option) &&
          option.toLowerCase().includes(searchText.toLowerCase()),
      );
      setAllOptions(filteredOptions);
      if(location.pathname === "/teammates/add" && filteredOptions.length === 0 && adding === false){
        setAdding(true);
      } else if (location.pathname === "/teammates/add" && filteredOptions.length > 0 && adding === true){
        setAdding(false);
      }
    }
  }, [searchText, pickedOptions, originalOptions]);
  const handleClick = (index: number) => {
    const selectedOption = allOptions[index];
    const updatedOptions = allOptions.filter((_, i) => i !== index);
    setAllOptions(updatedOptions);
    if (!pickedOptions.includes(selectedOption)) {
      setPickedOptions([...pickedOptions, selectedOption]);
    }
  };
  const handleRemove = (index: number) => {
    try {
      const removedOption = pickedOptions[index];
      const updatedPickedOptions = pickedOptions.filter((_, i) => i !== index);
      setPickedOptions(updatedPickedOptions);
      if (!allOptions.includes(removedOption)) {
        const updatedOptions = [...allOptions, removedOption].sort((a, b) => {
          const numA = parseInt(a.match(/\d+/)![0]);
          const numB = parseInt(b.match(/\d+/)![0]);
          return numA - numB;
        });
        setAllOptions(updatedOptions);
      }
    } catch (err) {
      // do nothing
    }
  };

  const onClickProposeButton = async () => {
    const token = localStorage.getItem("token");
    
    try {
      const response = await axios.post(url+ "/proposition", {
        proposition: searchText,
        filterType: "forGratification" 
      },{
        headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }}); 
      setAdding(false); 
      setSearchText("");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3500);
    } catch (err) { // handler error
      console.log(err);
    }
  };


  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText(searchText);
  };
  return (
    <>
      <div className="component">
        {window.location.pathname === "/teammates" || window.location.pathname === "/projects" ? "" : (
          <div style={{ position: "absolute", left: "-0.7em", top: ".5em", color: "red", fontSize: "30px" }}>
            *
          </div>
        )
        }
        <div onClick={() => setShow("forGratification")} className="title">
          <p>
            Gets Paid For&nbsp;
            {pickedOptions.length > 0 && (
              <span className="ml_3">({pickedOptions.length})</span>
            )}
          </p>
          <i
            className={`${show && "rotate"
              } fa-solid fa-chevron-down cursor-pointer`}
          />
        </div>
        {show && (
          <div className="show_component1">
            {adding && (
              <button className="propose-button" onClick={onClickProposeButton}>Propose</button>
            )}
            <input
              type="text"
              className="search_input"
              onChange={handleSearch}
              value={searchText}
              onKeyDown={(e) => {
                if (e.key === "Enter" && searchText.length) {
                  handleClick(0);
                  setSearchText("");
                }
              }}
            />
            <div
              className="mainContentBox"
              style={{ flexDirection: displayColumn ? "column" : "row" }}
            >
              <div className="picked">
                <h6>Picked</h6>
                {pickedOptions.map((option, index) => (
                  <div
                    key={index}
                    className="option"
                    onClick={() => handleRemove(index)}
                  >
                    <div className="checkbox">
                      <div className="inner_box" />
                    </div>
                    <p>{option}</p>
                  </div>
                ))}
              </div>
              <div className="all">
                <h6>All</h6>
                <div className="all_options_scroll">
                  {allOptions.map((option, index) => (
                    <div
                      onClick={() => handleClick(index)}
                      key={index}
                      className="option"
                    >
                      <div className="checkbox">
                        {" "}
                        <div className="inner_box" />
                      </div>
                      <p>{option}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showAlert && (
        <div className={styles["alert-container"]}>
          <Alert icon={<Check fontSize="inherit" />} severity="success">
            <h4>Succesfully propose new filter</h4>
          </Alert>
        </div>
    )}
    </>
  );
}
