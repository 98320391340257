import axios from "axios";
import { useEffect } from "react";
import AreaOfExperties from "../../../components/Filters_Projects/AreaOfExpertiesFilter";
import ForGratification from "../../../components/Filters_Projects/ForGratificationFilter";
import OfferedGratification from "../../../components/Filters_Projects/OfferedGratificationFilter";
import ProjectName from "../../../components/Filters_Projects/ProjectNameFilter";
import ProjectType from "../../../components/Filters_Projects/ProjectTypeFilter";
import SoftSkills from "../../../components/Filters_Projects/SoftSkillsFilter";
import ToolsExperienceFilter from "../../../components/Filters_Projects/ToolsExperienceFilter";
import { url } from "../../../url";
// import ExperienceTools from "../../../components/InputFields/ExperienceTools";
import { TextField } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { useNavigate, useParams } from "react-router-dom";
import HardSkills from "../../../components/Filters_Projects/HardSkillsFilter";
import Location from "../../../components/Filters_Projects/LocationFilter";
import OfferedWorkingType from "../../../components/Filters_Projects/OfferedWorkingTypeFilter";
import ProjectDeadline from "../../../components/Filters_Projects/ProjectDeadlineFilter";
import Availability from "../../../components/Filters_Projects/RequiredAvailabilityFilter";
import UsedLanguages from "../../../components/Filters_Projects/UsedLanguagesFilter";
import Page from "../../../components/Page";
import "../../../sass/components/newInputForm/CarouselPage.scss";
import { useDataFromEditProjects } from "../../../utils/useDataFromEditProjects";
import { useOpenCloseFiltersEditProject } from "../../../utils/useOpenCloseFiltersEditProject";
import { pageAtom } from "../atoms/AddProjectAtomGlobal";
import {
  bizOrTechRoleAtomLeftValue,
  bizOrTechRoleAtomRightValue,
  descriptionAtom,
  forGratificationAtom,
  hardSkillsAtom,
  locationsAtom,
  offeredGratificationAtom,
  offeredWorkingTypeActiveOptionsAtom,
  offeredWorkingTypeAtomNewArray,
  openAtom,
  projectDeadlineAtom,
  projectNameAtom,
  projectTypeAtom,
  requiredAreaOfExpertiseAtom,
  requiredAvailabilityAtomEndValue,
  requiredAvailabilityAtomOption,
  requiredAvailabilityAtomStartValue,
  softSkillsAtom,
  toolsExperienceAtom,
  toolsExperienceOptionViewsAtom,
  usedLanguagesAtom,
} from "../atoms/EditProjectAtomGlobal";

const EditProject = () => {
  const [page, setPage] = useAtom(pageAtom);

  // Page 1
  const [projectName, setProjectName] = useAtom(projectNameAtom);
  const [toolsExperience, setToolsExperience] = useAtom(toolsExperienceAtom);
  const [toolsExperienceOptionViews, setToolsExperienceOptionViews] = useAtom(
    toolsExperienceOptionViewsAtom
  );
  const [projectType, setProjectType] = useAtom(projectTypeAtom);
  const [offeredGratification, setOfferedGratification] = useAtom(
    offeredGratificationAtom
  );
  const [softSkills, setSoftSkills] = useAtom(softSkillsAtom);
  const [requiredAreaOfExpertise, setRequiredAreaOfExpertise] = useAtom(
    requiredAreaOfExpertiseAtom
  );
  const [forGratification, setForGratification] = useAtom(forGratificationAtom);

  // Page 2
  const [projectDeadline, setProjectDeadline] = useAtom(projectDeadlineAtom);
  const [hardSkills, setHardSkills] = useAtom(hardSkillsAtom);
  const [offeredWorkingTypeNewArray, setOfferedWorkingTypeNewArray] = useAtom(
    offeredWorkingTypeAtomNewArray
  );
  const [offeredWorkingTypeActiveOptions, setOfferedWorkingTypeActiveOptions] =
    useAtom(offeredWorkingTypeActiveOptionsAtom);
  const [description, setDescription] = useAtom(descriptionAtom);

  // Page 3
  const [usedLanguages, setUsedLanguages] = useAtom(usedLanguagesAtom);
  const [bizOrTechRoleLeftValue, setBizOrTechRoleLeftValue] = useAtom(
    bizOrTechRoleAtomLeftValue
  );
  const [bizOrTechRoleRightValue, setBizOrTechRoleRightValue] = useAtom(
    bizOrTechRoleAtomRightValue
  );
  const [requiredAvailabilityStartValue, setRequiredAvailabilityStartValue] =
    useAtom(requiredAvailabilityAtomStartValue);
  const [requiredAvailabilityEndValue, setRequiredAvailabilityEndValue] =
    useAtom(requiredAvailabilityAtomEndValue);
  const [requiredAvailabilityOption, setRequiredAvailabilityOption] = useAtom(
    requiredAvailabilityAtomOption
  );
  const [locations, setLocations] = useAtom(locationsAtom);

  const { projectId } = useParams();
  const openFilters = useAtomValue(openAtom);
  const enableAndResetFilters = useOpenCloseFiltersEditProject();
  const data = useDataFromEditProjects();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const project = await axios.get(url + `/project/${projectId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // Page 1 set
      setProjectName(project.data.project.projectName);
      const toolsExperienceArr = project.data.project.toolsExperience;
      const stringArray =
        toolsExperienceArr.length > 0
          ? toolsExperienceArr.map((obj: any) => obj.tool)
          : [];
      setToolsExperience(stringArray);
      setProjectType(project.data.project.projectType);
      setOfferedGratification(project.data.project.offeredGratification);
      setSoftSkills(project.data.project.softSkills);
      setRequiredAreaOfExpertise(project.data.project.areaOfExpertise);
      setForGratification(project.data.project.forGratification);

      // Page 2 set
      setProjectDeadline(project.data.project.deadline);
      setHardSkills(project.data.project.hardSkills);
      var newArray = [];
      var activeOptions = [];
      if (project.data.project.workingTypeRemote) {
        newArray.push("Remote");
        activeOptions.push(true);
      } else {
        activeOptions.push(false);
      }
      if (project.data.project.workingTypeOffice) {
        newArray.push("Office");
        activeOptions.push(true);
      } else {
        activeOptions.push(false);
      }
      setOfferedWorkingTypeNewArray(newArray);
      setOfferedWorkingTypeActiveOptions(activeOptions);

      // Page 3 set
      setUsedLanguages(project.data.project.usedLanguages);
      setBizOrTechRoleLeftValue(project.data.project.bizRole);
      setBizOrTechRoleRightValue(project.data.project.techRole);
      setRequiredAvailabilityStartValue(project.data.project.availabilityFrom);
      setRequiredAvailabilityEndValue(project.data.project.availabilityTo);
      setRequiredAvailabilityOption(project.data.project.availabilityType);
      setLocations(project.data.project.locations);
      setDescription(project.data.project.description);
    };
    fetchData();
  }, []);

  // logic for choosing page
  const onClickSubmitButton = async () => {
    const token = localStorage.getItem("token");
    if (!data.workingTypeRemote) {
      data.workingTypeRemote = false;
    }
    if (!data.workingTypeOffice) {
      data.workingTypeOffice = false;
    }
    try {
      if (
        projectName.length &&
        toolsExperience.length &&
        projectType.length &&
        offeredGratification.length &&
        forGratification.length
      ) {
        const res = await axios.put(
          url + `/project/editProject/${projectId}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(res.data);
        navigate(`/projects/my`);
      } else {
        alert("You need to fill required fields");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Page>
      <div style={{ width: "100%", padding: "20px" }}>
        <div className="page1_component" style={{ maxWidth: "100%" }}>
          <div
            className="columns_container"
            style={{
              display: "flex",
              // flexDirection: "row",
              gap: "25px",
              width: "70%", // Make the container take the full width
            }}
          >
            <div
              className="column"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                flex: 1, // Both columns take equal space
              }}
            >
              <ProjectName
                projectName={projectName}
                setProjectName={setProjectName}
              />
              <ToolsExperienceFilter
                pickedOptions={toolsExperience}
                setPickedOptions={setToolsExperience}
                optionViews={toolsExperienceOptionViews}
                setOptionViews={setToolsExperienceOptionViews}
                show={openFilters.toolsExperience}
                setShow={enableAndResetFilters}
              />
              <ProjectType
                pickedOptions={projectType}
                setPickedOptions={setProjectType}
                show={openFilters.projectType}
                setShow={enableAndResetFilters}
              />
              <OfferedGratification
                pickedOptions={offeredGratification}
                setPickedOptions={setOfferedGratification}
                show={openFilters.offeredGratification}
                setShow={enableAndResetFilters}
              />
              <ForGratification
                pickedOptions={forGratification}
                setPickedOptions={setForGratification}
                show={openFilters.forGratification}
                setShow={enableAndResetFilters}
              />
              <HardSkills
                pickedOptions={hardSkills}
                setPickedOptions={setHardSkills}
                show={openFilters.hardSkills}
                setShow={enableAndResetFilters}
              />
              <AreaOfExperties
                pickedOptions={requiredAreaOfExpertise}
                setPickedOptions={setRequiredAreaOfExpertise}
                show={openFilters.requiredAreaOfExpertise}
                setShow={enableAndResetFilters}
              />
              <ProjectDeadline
                dateState={projectDeadline}
                setDateState={setProjectDeadline}
                open={openFilters.projectDeadline}
                setOpen={enableAndResetFilters}
              />
            </div>

            <div
              className="column"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                flex: 1,
              }}
            >
              <SoftSkills
                pickedOptions={softSkills}
                setPickedOptions={setSoftSkills}
                show={openFilters.softSkills}
                setShow={enableAndResetFilters}
              />

              <Availability
                startValue={requiredAvailabilityStartValue}
                setStartValue={setRequiredAvailabilityStartValue}
                endValue={requiredAvailabilityEndValue}
                setEndValue={setRequiredAvailabilityEndValue}
                selectedOption={requiredAvailabilityOption}
                setSelectedOption={setRequiredAvailabilityOption}
                open={openFilters.requiredAvailability}
                setOpen={enableAndResetFilters}
              />
              <OfferedWorkingType
                newArray={offeredWorkingTypeNewArray}
                setNewArray={setOfferedWorkingTypeNewArray}
                activeOptions={offeredWorkingTypeActiveOptions}
                setActiveOptions={setOfferedWorkingTypeActiveOptions}
                show={openFilters.offeredWorkingType}
                setShow={enableAndResetFilters}
              />
              <Location
                pickedOptions={locations}
                setPickedOptions={setLocations}
                show={openFilters.locations}
                setShow={enableAndResetFilters}
              />
              <UsedLanguages
                pickedOptions={usedLanguages}
                setPickedOptions={setUsedLanguages}
                show={openFilters.usedLanguages}
                setShow={enableAndResetFilters}
              />
              <TextField
                placeholder="Description"
                multiline
                rows={8}
                maxRows={10}
                style={{ backgroundColor: "white" }}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />

              {/*           
          <BizOrTechRole
            leftValue={bizOrTechRoleLeftValue}
            setLeftValue={setBizOrTechRoleLeftValue}
            rightValue={bizOrTechRoleRightValue}
            setRightValue={setBizOrTechRoleRightValue}
            open={openFilters.bizOrTech}
            setOpen={enableAndResetFilters}
          /> */}
            </div>
          </div>
        </div>
        <div className="buttons_container">
          <div>
            <button className="submit_btn" onClick={onClickSubmitButton}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default EditProject;
