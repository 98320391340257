import { useState, useEffect } from "react";
import "../../sass/components/Boxs/ProjectBox.scss";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import flag from "../../icons/flag.jpg";
import question from "../../icons/question.png";
import dots from "../../icons/dots.png";
import user from "../../icons/user.png";

var displayOptions = 3;

const ProjectBox = ({
  project,
  tokenAvailable,
  addProject,
  deleteProject,
  navigateToEdit,
  hidingBox,
  projectAdded,
  loading,
  myProject,
}: {
  project: any;
  tokenAvailable: boolean;
  addProject?: () => Promise<void>;
  deleteProject?: () => Promise<void>;
  navigateToEdit?: () => void;
  hidingBox?: boolean;
  projectAdded?: boolean;
  loading?: boolean;
  myProject: boolean;
}) => {
  const [showSkills, setShowSkills] = useState(false);
  const [hideBox, setHideBox] = useState(false);

  const handleShowSkills = () => {
    if (showSkills === false) {
      setShowSkills(true);
    } else {
      setShowSkills(false);
    }
  };


  const handleDeleteProject = () => {
    if (hidingBox) {
      setHideBox(true);
    }
  };
  const navigate = useNavigate();
  if (!hideBox) {
    if (!loading) {
      return (
        <div
          className="container projects__body__container__left__card"
        >
          <div className="projects__body__container__left__card__top">
            <div className="box_title_wrapper">
              <div>
                <img src={user} alt="User"/>
                <h3>{project.projectName}</h3>
              </div>
              <div>
                {tokenAvailable && (
                  <>
                    {!projectAdded ? (
                      <button  className="save_button" onClick={addProject}>
                        Save
                      </button>
                    ) : (
                      <button
                        className="forgetButton"
                        onClick={() => {
                          if (deleteProject) {
                            deleteProject();
                            handleDeleteProject();
                          }
                        }}
                      >
                        Forget
                      </button>
                    )}
                  </>
                )}
                <button className="save_button" style={{margin:"0 0.4em"}} onClick={()=>navigate(`/projects/${project._id}`)}>See more</button>
                {navigateToEdit != null && (
                  <button className="save_button" style={{margin:"0 0.4em"}} onClick={navigateToEdit}>Edit</button>
                )}
              </div>
            </div>
            <div className="projects__body__container__left__card__top__iconsBox">
              {/* {title === "My Projects" ? (
                <></>
              ) : (
                <Button
                  className="projects__body__container__left__card__top__iconsBox__write"
                  onClick={() => navigate("/conversations")}
                >
                  Message
                  <ModeEditOutlineOutlinedIcon className="projects__body__container__left__card__top__iconsBox__write__icon" />
                </Button>
              )}
    
              <Button className="projects__body__container__left__card__top__iconsBox__add">
                Save
                <AddOutlinedIcon className="projects__body__container__left__card__top__iconsBox__add__icon" />
              </Button>
    
              {title === "My Projects" && (
                <>
                  <Button className="projects__body__container__left__card__top__iconsBox__edit">
                    Edit
                    <BorderColorIcon className="projects__body__container__left__card__top__iconsBox__add__icon" />
                  </Button>
                  <Button className="projects__body__container__left__card__top__iconsBox__delete">Delete</Button>
                </>
              )}
              {title === "Found Projects" && (
                <Button className="projects__body__container__left__card__top__iconsBox__add">
                  Added
                  <DoneIcon className="projects__body__container__left__card__top__iconsBox__add__icon" />
                </Button>
              )}
              {title === "Skillers" && (
                <Button className="projects__body__container__left__card__top__iconsBox__add">
                  Add
                  <AddOutlinedIcon className="projects__body__container__left__card__top__iconsBox__add__icon" />
                </Button>
              )}
              {title === "Found Skillers" && (
                <Button className="projects__body__container__left__card__top__iconsBox__add">
                  Added
                  <DoneIcon className="projects__body__container__left__card__top__iconsBox__add__icon" />
                </Button>
              )} */}
            </div>
          </div>
          <div className="projects__body__container__left__card__body">

            {/* used tools */}
            {project.toolsExperience.length !== 0 && (
              <div className="projects__body__container__left__card__body__specs yellow_parent feature_box_tools small_none">
                <div className="question_parent">
                  <p>Used Tools</p>
                  {/* <div className="question_hover">
                    <img className="question_image" src={question} alt="" />
                    <div className="yellow_text">
                      <p>Minimum Experience Period</p>
                    </div>
                  </div> */}
                </div>
                <div className="projects__body__container__left__card__body__specs__desc">
                  {/* {project.toolsExperience
                    .sort()
                    .slice(0, displayOptions)
                    ?.map(
                      (obj: { tool: string; mep: number; _id: string }, index: number) => {
                        return (
                          <div key={obj._id} className="flag_design">
                            <div>
                              <small key={index}>
                              +{obj.mep}M {obj.tool}
                              </small>
                            </div>
                            {/* <div className="border_right" />
                            <div className="red_hover_tool">
                              <img src={flag} alt="" />
                              <small className="hover_red_text">
                                Report Tool
                              </small>
                            </div> */}
                          {/* </div>
                        );
                      }
                    )} */}
                  {project.toolsExperience
                    .sort()
                    .slice(0, displayOptions)
                    ?.map((obj: { tool: string; mep: number; _id: string }, index: number) => (
                      <span className="flag_design_small" key={index}>+{obj.mep}M {obj.tool}</span>
                    ))}
                    
                </div>
                {project.toolsExperience.length > 3 && (
                <div className="dot_container">
                  <div className="dot_hover">
                    <div className="dot_image">
                      <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                    </div>
                    <div className="dot_hover_element">
                      {project.toolsExperience
                        .sort()
                        .slice(displayOptions, project.toolsExperience.length)
                        ?.map(
                          (
                            obj: { tool: string; mep: number },
                            index: number
                          ) => (
                            <small key={index}>
                              +{obj.mep}M {obj.tool} 
                            </small>
                          )
                        )}
                    </div>
                  </div>
                </div>)}
              </div>
            )}
            {project.productType !== 0 && (
              <div className="projects__body__container__left__card__body__specs yellow_parent feature_box_project_type small_none">
                <div className="question_parent1">
                  <p>Product Type</p>
                  {/* <div className="question_hover">
                    <img className="question_image1" src={question} alt="" />
                    <div className="yellow_text1">
                      <p>Project Type Experience</p>
                    </div>
                  </div> */}
                </div>
                <div className="projects__body__container__left__card__body__specs__desc">
                  {project.productType
                    .sort()
                    .slice(0, displayOptions)
                    ?.map((val: string, index: number) => (
                      <span className="flag_design_small" key={index}>{val}</span>
                    ))}
                </div>
                {project.productType.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {project.productType
                          .slice(displayOptions, project.productType.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                )}

              </div>
            )}
            {/* project type */}
            {project.projectType !== 0 && (
              <div className="projects__body__container__left__card__body__specs yellow_parent feature_box_project_type small_none">
                <div className="question_parent1">
                  <p>Project Type</p>
                  {/* <div className="question_hover">
                    <img className="question_image1" src={question} alt="" />
                    <div className="yellow_text1">
                      <p>Project Type Experience</p>
                    </div>
                  </div> */}
                </div>
                <div className="projects__body__container__left__card__body__specs__desc">
                  {project.projectType
                    .sort()
                    .slice(0, displayOptions)
                    ?.map((val: string, index: number) => (
                      <span className="flag_design_small" key={index}>{val}</span>
                    ))}
                </div>
                {project.projectType.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {project.projectType
                          .slice(displayOptions, project.projectType.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                )}

              </div>
            )}
             {/* Skills Orientation */}
            {project.softSkills.length !== 0 && (
                <div className="projects__body__container__left__card__body__specs feature_box_soft_skills">
                  <p>Skills Orientation</p>
                  <div className="projects__body__container__left__card__body__specs__desc">
                    {/* <div className="projects__body__container__left__card__body__specs__desc"> */}
                      {project.hardSkills
                        .sort()
                        .slice(0, displayOptions)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className = "flag_design_small" key={index}>{i}</span>
                          </>
                        ))}
                    {/* </div> */}
                  </div>
                  {project.hardSkills.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {project.hardSkills
                          .slice(displayOptions, project.hardSkills.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                  )}
                </div>
            )}
            {/* industry */}
            {project.areaOfExpertise.length !== 0 && (
              <div className="projects__body__container__left__card__body__specs feature_box_industry">
                <div className="red_text_parent">
                  <p>Industry</p>
                </div>
                <div className="projects__body__container__left__card__body__specs__desc">
                    {/* <div className="projects__body__container__left__card__body__specs__desc"> */}
                      {project.areaOfExpertise
                        .sort()
                        .slice(0, displayOptions)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className="flag_design_small" key={index}>{i}</span>
                          </>
                        ))}
                    {/* </div> */}
                  </div>
                
                {project.areaOfExpertise.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {project.areaOfExpertise
                          .slice(displayOptions, project.areaOfExpertise.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
              )}
              </div>
            )}
            {/* pays with */}
            {project.offeredGratification.length !== 0 && (
              <div className="projects__body__container__left__card__body__specs feature_box_paid_with small_none">
                <p>Pays With</p>
                <div
                  className="projects__body__container__left__card__body__specs__desc"
                  // style={{ display: "inline-block" }}
                >
                  {project.offeredGratification?.length ? (
                    project.offeredGratification?.sort().slice(0, displayOptions).map(
                      (gratifcation: string) => {
                        return (
                          <span className="flag_design_small" key={gratifcation} style={{ display: "inline-block" }}>
                            {gratifcation}
                          </span>
                        );
                      }
                    )
                  ) : (
                    <span style={{ display: "inline-block" }}>Nothing</span>
                  )}
                </div>
                {project.offeredGratification.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {project.offeredGratification
                          .slice(displayOptions, project.offeredGratification.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
                
              </div>
            )}
            {/* pays for */}
            {project.forGratification.length !== 0 && (
              <div className="projects__body__container__left__card__body__specs feature_box_paid_for small_none">
                <p>Pays For</p>
                <div
                  className="projects__body__container__left__card__body__specs__desc"
                >
                  {
                    project.forGratification?.sort().slice(0, displayOptions).map((gratification: string) => (
                      <span className="flag_design_small" key={gratification} style={{ display: "inline-block" }}>
                        {gratification}
                      </span>
                    ))
                  }
                </div>
            {project.forGratification.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {project.forGratification
                          .slice(displayOptions, project.forGratification.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
              )}
              </div>
            )}
            

          </div>

          {showSkills === true && (
            <div className="projects__body__container__left__card__body2">
              {/* estimated deadline */}
              {project.deadline.length !== 0 && (
              <div className="projects__body__container__left__card__body__specs feature_box_work_duration">
                <p>Estimated Deadline</p>
                <div className="projects__body__container__left__card__body__specs__desc">
                  <span className="flag_design_small" style={{ display: "inline-block" }}>
                    {new Date(project.deadline).toLocaleDateString()}
                  </span>
                  {/* <span style={{ display: "inline-block" }}>{1}</span>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      display: "inline-block"
                    }}
                  >
                    to
                  </span>
                  <span style={{ display: "inline-block" }}>{acceptedDeadline[1]}</span>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      display: "inline-block"
                    }}
                  >
                    months
                  </span> */}
                </div>
              </div>
              )}    
               {/* Essential soft skills */}
               {project.softSkills.length !== 0 && (
                <div className="projects__body__container__left__card__body__specs feature_box_soft_skills">
                  <p>Soft Skills</p>
                  <div className="projects__body__container__left__card__body__specs__desc">
                    {/* <div className="projects__body__container__left__card__body__specs__desc"> */}
                      {project.softSkills
                        .sort()
                        .slice(0, displayOptions)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className = "flag_design_small" key={index}>{i}</span>
                          </>
                        ))}
                    {/* </div> */}
                  </div>
                  {project.softSkills.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {project.softSkills
                          .slice(displayOptions, project.softSkills.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                  )}
                </div>
              )}
              {/* standard weekly engagement */}
              <div className="projects__body__container__left__card__body2__specs feature_box_weekly_availability">
                <p>Wkly Engagement</p>
                <div className="projects__body__container__left__card__body2__specs__desc">
                  <span style={{ display: "inline-block"}}>
                    {project.availabilityFrom} h
                  </span>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      display: "inline-block",
                      // margin: "0",
                    }}
                  >
                    to
                  </span>
                  <span style={{ display: "inline-block" }}>
                    {project.availabilityTo} h
                  </span>
                  {/* <span
                    style={{
                      backgroundColor: "transparent",
                      display: "inline-block",
                    }}
                  >
                    {/* h/{project.availabilityType} */}
                  {/* </span> */}
                </div>
              </div>
              {/* working type  */}
              {project.workingTypeOffice && project.workingTypeRemote && (
              <div className="projects__body__container__left__card__body__specs feature_box_working_type small_none">
                <p>Working Type</p>
                <div
                  className="projects__body__container__left__card__body__specs__desc"
                  // style={{ display: "inline-block" }}
                >
                  {project.workingTypeOffice ? (
                    <span className = "flag_design_small" style={{ display: "inline-block" }}>Office</span>
                  ) : null}
                  {project.workingTypeRemote ? (
                    <span className = "flag_design_small" style={{ display: "inline-block" }}>Remote</span>
                  ) : null}
                  {/* <span style={{ display: "inline-block" }}>Office</span>
                <span style={{ display: "inline-block" }}>Remote</span> */}
                </div>
              </div>
              )}
              {/* Team locations */}
              {project.locations.length !== 0 && (
                <div className="projects__body__container__left__card__body__specs feature_box_location">
                  <p>Team Locations</p>
                  <div className="projects__body__container__left__card__body__specs__desc">
                    {/* <span>
                      {
                      project.locations?.sort().slice(0, displayOptions).map((location: string) => {
                        return location + ", ";
                      })}
                      {/* {location[0]}, {location[1]} */}
                    {/* </span> */}
                    {/* <div className="projects__body__container__left__card__body__specs__desc"> */}
                      {project.locations
                        .sort()
                        .slice(0, displayOptions)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className = "flag_design_small" key={index}>{i}</span>
                          </>
                        ))}
                    {/* </div> */}
                  </div>
                  {project.locations.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {project.locations
                          .slice(displayOptions, project.locations.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                  )}
                </div>
              )}
              {/* Used Languages */}
              {project.usedLanguages.length !== 0 && (
              <div className="projects__body__container__left__card__body__specs feature_box_languages">
                <p>Used Languages</p>
                <div
                  className="projects__body__container__left__card__body__specs__desc"
                  // style={{ display: "inline-block" }}
                >
                  {project.usedLanguages
                        .sort()
                        .slice(0, displayOptions)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className="flag_design_small" key={index}>{i}</span>
                          </>
                        ))}
                </div>
              </div>
              )}
              
             
             
              

              {/* Biz or Tech - different values arrangement*/}
              {/* <div className="projects__body__container__left__card__body__specs feature_box_biz_or_tech">
                  <p>Biz / Tech Role</p>
                  <div className="projects__body__container__left__card__body__specs__desc" style={{ display: "grid", gap: "4px" }}>
                    <span style={{ display: "inline-block" }}>
                      BizLike {project.bizRole ? project.bizRole : 0}%
                    </span>
                    {" "}
                    <span className="flag_design_small" style={{ display: "inline-block" }}>
                      TechLike {project.techRole}%
                    </span>
                  </div>
              </div> */}

            </div>
          )}
          <div className="projects__body__container__left__card__bottom">
            <div className="projects__body__container__left__card__bottom__left">
              {/* <span className="projects__body__container__left__card__bottom__left__dot">
                .
              </span> */}
              {/* <span className="projects__body__container__left__card__bottom__left__time">
                Posted 4 hours ago
              </span> */}
            </div>
            <button onClick={handleShowSkills}>
              {showSkills === false ? (
                <KeyboardArrowDownOutlinedIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </button>
          </div>
        </div>
      );
    }
  }
};

export default ProjectBox;
