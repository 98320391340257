import React, { useRef } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MoodIcon from "@mui/icons-material/Mood";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SendIcon from "@mui/icons-material/Send";
import Message from "../../../components/Message/Message";
import { Button } from "@mui/material";
import { conversationAtom,chosenUserAtom } from "../atoms/ChatAtom";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { usersAtom } from "../atoms/ChatAtom";
import axios from "axios";
import { url } from "../../../url";
import { socket } from "../../../socket/socket";

socket.connect();
const ChatView = () => {
  const [updater,setUpdater] = useState(false);
  const [input,setInput] = useState("");
  const [conversation,setConversation] = useAtom(conversationAtom); 
  const [chosenUser,setChosenUser] = useAtom(chosenUserAtom);
  const listRef = useRef<null | HTMLDivElement>(null);

  useEffect(()=>{
    scrollToBottom();
    socket.emit("join",conversation._id);
    return ()=>{
      socket.off("join");
    }
  },[conversation._id]);
   
  useEffect(()=>{
    socket.on("message",(data)=>{
        setConversation((prevState: any)=>{
          return {
            ...prevState,
            messages: [...prevState.messages,{
              conversationId: conversation._id,
              senderId: chosenUser._id, 
              message: data.text,
              date: new Date().toISOString(),
            }]
          }
        });
        setUpdater(prevState => !prevState);
        scrollToBottom();
    });

    return () => {
      socket.off("message");
    };
  },[chosenUser._id, conversation._id ]);

  const scrollToBottom = ()=>{
    listRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const onChangeInput =(event :  React.ChangeEvent<HTMLInputElement>)=>{
    setInput(event.target.value);
  };

  const sendMessage = async()=>{
    if(chosenUser?._id && input.length != 0){
      socket.emit("chat",{
        room: conversation._id,
        user: chosenUser._id,
        text: input
      });
      const response = await axios.post(url + `/chat/sendMessage`,{
        id: chosenUser._id,
        message: input
      },{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ localStorage.getItem("token")
        }
      });
      const tempConversation = conversation;
      tempConversation.messages.push(response.data.message);
      setConversation(tempConversation);
      setInput("")
      scrollToBottom();
    }
  };
  
  if(!conversation.messages) return null;

  return (
    <div className="conversations__container__chat">
      <div className="conversations__container__chat__box"  style={{overflowY: 'scroll',height: '100%'}}>
        {
          conversation.messages?.length ?
          conversation.messages?.map((message: any)=>{
            return(
              <Message key={message?._id}  myMessage={message.senderId !== chosenUser._id} content={message.message} time={message.date}/>
            )
          }) : "No messages"
        }
        <div ref={listRef}/>
      </div>

      <div className="conversations__container__chat__typeBox">
        <div className="conversations__container__chat__typeBox__type">
          <AttachFileIcon className="conversations__container__chat__typeBox__type__file" />
          <input
            type="text"
            placeholder="Type your message here..."
            className="conversations__container__chat__typeBox__type__input"
            onChange={onChangeInput}
            value={input}
          />
          <MoodIcon className="conversations__container__chat__typeBox__type__modeIcon" />
          <CameraAltIcon className="conversations__container__chat__typeBox__type__cameraIcon" />
        </div>
        <Button
          variant="contained"
          size="small"
          className="conversations__container__chat__typeBox__sendButton"
          onClick={sendMessage}
        >
          <SendIcon className="conversations__container__chat__typeBox__sendButton__sendIcon" />
        </Button>
      </div>
      {updater}
    </div>
  );
};

export default ChatView;
