import FoundSkillers from "../pages/FoundSkillers/index";
import Home from "../pages/Home/index";
import Login from "../pages/Login";
import Register from "../pages/Register";
import HomeCardsView from "../pages/Home/HomeCardsView/index";
import Conversations from "../pages/Conversations/index";
import Projects from "../pages/Projects/index";
// import AddProject from "../pages/Projects/AddProject/index";
import AddProject from "../pages/Projects/AddProject/index";
import Teammates from "../pages/Teammate/index";
import AddTeammate from "../pages/Teammate/AddTeammate/index";
import FoundProjects from "../pages/FoundProjects/index";
import Settings from "../pages/Settings/index";
import SettingsMessage from "../pages/Settings/SettingMessage/index";
import MyProject from "../pages/MyProjects/Index";
import MySkillers from "../pages/MySkills/Index";
import SendMoreOffers from "../pages/SendMoreOffers/index";
import EditTeammate from "../pages/Teammate/EditTeammate/index";
import EditProject from "../pages/Projects/EditProject/index";
import SingleProject from "../pages/SingleProject";
import SingleTeammate from "../pages/SingleTeammate";
import Feedback from "../pages/Feedback";
import NotFound from "../pages/NotFound";
import ErrorPage from "../pages/ErrorPage";

export const routes = [
  {
    path: "/",
    component: Home,
    isPrivate: false,
  },
  {
    path: "/login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "signUp",
    component: Register,
    isPrivate: false,
  },
  {
    path: "/home/card/:option",
    component: HomeCardsView,
    isPrivate: false,
  },
  {
    path: "/projects",
    component: Projects,
    isPrivate: false,
  },
  {
    path: "/projects/:id",
    component: SingleProject,
    isPrivate:false
  },
  {
    path: "/project/add",
    component: AddProject,
    isPrivate: false,
  },
  {
    path: "/project/editProject/:projectId",
    component: EditProject,
    isPrivate: false,
  },
  {
    path: "/teammates",
    component: Teammates,
    isPrivate: false,
  },
  {
    path: "/teammates/:id",
    component: SingleTeammate,
    isPrivate:false
  },
  {
    path: "/teammates/add",
    component: AddTeammate,
    isPrivate: false,
  },
  {
    path: "/teammates/editTeammate/:teammateId",
    component: EditTeammate,
    isPrivate: false,
  },
  {
    path: "/conversations",
    component: Conversations,
    isPrivate: false,
  },
  {
    path: "/teammates/found",
    component: FoundSkillers,
    isPrivate: false,
  },
  {
    path: "/projects/found",
    component: FoundProjects,
    isPrivate: false,
  },
  {
    path: "/user/projects/found",
    component: Projects,
    isPrivate: false,
  },
  {
    path: "/settings",
    component: Settings,
    isPrivate: false,
  },
  {
    path: "/settings/message",
    component: SettingsMessage,
    isPrivate: false,
  },
  {
    path: "/projects/my",
    component: MyProject,
    isPrivate: false,
  },
  {
    path: "/skillers/my",
    component: MySkillers,
    isPrivate: false,
  },
  {
    path: "/sendMoreOffers",
    component: SendMoreOffers,
    isPrivate: false,
  },
  {
    path: "/feedback",
    component: Feedback,
    isPrivate: false
  },
  {
    path: '/error',
    component: ErrorPage,
    isPrivate: false
  },
  {
    path: "*",
    component: NotFound,
    isPrivate: false,
  },
];
