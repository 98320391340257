import AreaOfExperties from "../../../components/Filters_Teammates/AreaOfExpertiesFilter";
import ForGratification from "../../../components/Filters_Teammates/ForGratificationFilter";
import OfferedGratification from "../../../components/Filters_Teammates/OfferedGratificationFilter";
import ProductType from "../../../components/Filters_Teammates/ProductTypeFilter";
import ProjectType from "../../../components/Filters_Teammates/ProjectTypeFilter";
import SoftSkills from "../../../components/Filters_Teammates/SoftSkillsFilter";
import TeammateNickname from "../../../components/Filters_Teammates/TeammateNicknameFilter";
import ToolsExperienceFilter from "../../../components/Filters_Teammates/ToolsExperienceFilter";
// import ExperienceTools from "../../../components/InputFields/ExperienceTools";
import axios from "axios";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import ProjectDeadline from "../../../components/Filters_Teammates/ProjectDeadlineFilter";
import { url } from "../../../url";
import { useDataFromAddTeammate } from "../../../utils/useDataFromAddTeammate";
import { useOpenCloseFiltersAddTeammate } from "../../../utils/useOpenCloseFiltersAddTeammate";
import { openAtom, pageAtom } from "../atoms/AddTeammateAtomGlobal";
import {
  forGratificationAtom,
  nicknameAtom,
  offeredGratificationAtom,
  projectDeadlineAtom,
  productTypeAtom,
  projectTypeAtom,
  requiredAreaOfExpertiseAtom,
  softSkillsAtom,
  toolsExperienceAtom,
  toolsExperienceOptionViewsAtom,
} from "../atoms/AddTeammateAtomPage1";

import HardSkills from "../../../components/Filters_Teammates/HardSkillsFilter";
import OfferedWorkingType from "../../../components/Filters_Teammates/OfferedWorkingTypeFilter";

import {
  descriptionAtom,
  hardSkillsAtom,
  offeredWorkingTypeActiveOptionsAtom,
  offeredWorkingTypeAtomNewArray,
} from "../atoms/AddTeammateAtomPage2";

import { TextField } from "@mui/material";

import Location from "../../../components/Filters_Teammates/LocationFilter";
import Availability from "../../../components/Filters_Teammates/RequiredAvailabilityFilter";
import BizOrTechRole from "../../../components/Filters_Teammates/RequiredBizOrTechRoleFilter";
import UsedLanguages from "../../../components/Filters_Teammates/UsedLanguagesFilter";
import {
  bizOrTechRoleAtomLeftValue,
  bizOrTechRoleAtomRightValue,
  locationsAtom,
  requiredAvailabilityAtomEndValue,
  requiredAvailabilityAtomOption,
  requiredAvailabilityAtomStartValue,
  usedLanguagesAtom,
} from "../atoms/AddTeammateAtomPage3";

const Page1 = () => {
  const setPage = useSetAtom(pageAtom);
  const [nickname, setNickname] = useAtom(nicknameAtom);
  const [toolsExperience, setToolsExperience] =
    useAtom<string[]>(toolsExperienceAtom);
  const [toolsExperienceOptionViews, setToolsExperienceOptionViews] = useAtom(
    toolsExperienceOptionViewsAtom
  );
  const [projectType, setProjectType] = useAtom<string[]>(projectTypeAtom);
  const [productType, setProductType] = useAtom<string[]>(productTypeAtom);
  const [offeredGratification, setOfferedGratification] = useAtom<string[]>(
    offeredGratificationAtom
  );
  const [projectDeadline, setProjectDeadline] = useAtom(projectDeadlineAtom);
  const [softSkills, setSoftSkills] = useAtom<string[]>(softSkillsAtom);
  const [requiredAreaOfExpertise, setRequiredAreaOfExpertise] = useAtom<
    string[]
  >(requiredAreaOfExpertiseAtom);
  const [forGratification, setForGratification] =
    useAtom<string[]>(forGratificationAtom);
  const data = useDataFromAddTeammate();
  const navigate = useNavigate();

  const openFilters = useAtomValue(openAtom);
  const enableAndResetFilters = useOpenCloseFiltersAddTeammate();

  // Page2
  const [hardSkills, setHardSkills] = useAtom<string[]>(hardSkillsAtom);
  const [offeredWorkingTypeNewArray, setOfferedWorkingTypeNewArray] = useAtom<
    string[]
  >(offeredWorkingTypeAtomNewArray);
  const [offeredWorkingTypeActiveOptions, setOfferedWorkingTypeActiveOptions] =
    useAtom<boolean[]>(offeredWorkingTypeActiveOptionsAtom);
  const [description, setDescription] = useAtom(descriptionAtom);

  const onClickSubmitButton = async () => {
    const token = localStorage.getItem("token");
    try {
      if (
        nickname.length &&
        toolsExperience.length &&
        projectType.length &&
        productType.length &&
        offeredGratification.length &&
        forGratification.length
      ) {
        console.log(data);
        const res = await axios.post(url + "/teammate", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(res.data);
        navigate(`/teammates`);
      } else {
        alert("You need to fill required fields");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [usedLanguages, setUsedLanguages] =
    useAtom<string[]>(usedLanguagesAtom);
  const [bizOrTechRoleLeftValue, setBizOrTechRoleLeftValue] = useAtom(
    bizOrTechRoleAtomLeftValue
  );
  const [bizOrTechRoleRightValue, setBizOrTechRoleRightValue] = useAtom(
    bizOrTechRoleAtomRightValue
  );
  const [requiredAvailabilityStartValue, setRequiredAvailabilityStartValue] =
    useAtom(requiredAvailabilityAtomStartValue);
  const [requiredAvailabilityEndValue, setRequiredAvailabilityEndValue] =
    useAtom(requiredAvailabilityAtomEndValue);
  const [requiredAvailabilityOption, setRequiredAvailabilityOption] = useAtom(
    requiredAvailabilityAtomOption
  );
  const [locations, setLocations] = useAtom<string[]>(locationsAtom);

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div className="page1_component" style={{ maxWidth: "100%" }}>
        <div
          className="columns_container"
          style={{
            display: "flex",
            // flexDirection: "row",
            gap: "25px",
            width: "70%", // Make the container take the full width
          }}
        >
          <div
            className="column"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              flex: 1, // Both columns take equal space
            }}
          >
            {/* OBLIGATORY FIELDS*/}
            <TeammateNickname
              teammateNickname={nickname}
              setTeammateNickname={setNickname}
            />
            <ToolsExperienceFilter
              pickedOptions={toolsExperience}
              setPickedOptions={setToolsExperience}
              optionViews={toolsExperienceOptionViews}
              setOptionViews={setToolsExperienceOptionViews}
              show={openFilters.toolsExperience}
              setShow={enableAndResetFilters}
            />
            <ProductType
              pickedOptions={productType}
              setPickedOptions={setProductType}
              show={openFilters.productType}
              setShow={enableAndResetFilters}
            />
            <ProjectType
              pickedOptions={projectType}
              setPickedOptions={setProjectType}
              show={openFilters.projectType}
              setShow={enableAndResetFilters}
            />
            <OfferedGratification
              pickedOptions={offeredGratification}
              setPickedOptions={setOfferedGratification}
              show={openFilters.offeredGratification}
              setShow={enableAndResetFilters}
            />
            <ForGratification
              pickedOptions={forGratification}
              setPickedOptions={setForGratification}
              show={openFilters.forGratification}
              setShow={enableAndResetFilters}
            />
            {/* OPTIONAL */}
            <AreaOfExperties
              pickedOptions={requiredAreaOfExpertise}
              setPickedOptions={setRequiredAreaOfExpertise}
              show={openFilters.requiredAreaOfExpertise}
              setShow={enableAndResetFilters}
            />
            <HardSkills
              pickedOptions={hardSkills}
              setPickedOptions={setHardSkills}
              show={openFilters.hardSkills}
              setShow={enableAndResetFilters}
            />
            {/* Willing to Collab Until */}

            <SoftSkills
              pickedOptions={softSkills}
              setPickedOptions={setSoftSkills}
              show={openFilters.softSkills}
              setShow={enableAndResetFilters}
            />

          </div>

          <div
            className="column"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              flex: 1,
            }}
          >
            <ProjectDeadline
              dateState={projectDeadline}
              setDateState={setProjectDeadline}
              open={openFilters.projectDeadline}
              setOpen={enableAndResetFilters}
            />
            <BizOrTechRole
              leftValue={bizOrTechRoleLeftValue}
              setLeftValue={setBizOrTechRoleLeftValue}
              rightValue={bizOrTechRoleRightValue}
              setRightValue={setBizOrTechRoleRightValue}
              open={openFilters.bizOrTech}
              setOpen={enableAndResetFilters}
            />

            <Availability
              startValue={requiredAvailabilityStartValue}
              setStartValue={setRequiredAvailabilityStartValue}
              endValue={requiredAvailabilityEndValue}
              setEndValue={setRequiredAvailabilityEndValue}
              selectedOption={requiredAvailabilityOption}
              setSelectedOption={setRequiredAvailabilityOption}
              open={openFilters.requiredAvailability}
              setOpen={enableAndResetFilters}
            />

            <OfferedWorkingType
              newArray={offeredWorkingTypeNewArray}
              setNewArray={setOfferedWorkingTypeNewArray}
              activeOptions={offeredWorkingTypeActiveOptions}
              setActiveOptions={setOfferedWorkingTypeActiveOptions}
              show={openFilters.offeredWorkingType}
              setShow={enableAndResetFilters}
            />

            <Location
              pickedOptions={locations}
              setPickedOptions={setLocations}
              show={openFilters.locations}
              setShow={enableAndResetFilters}
            />

            <UsedLanguages
              pickedOptions={usedLanguages}
              setPickedOptions={setUsedLanguages}
              show={openFilters.usedLanguages}
              setShow={enableAndResetFilters}
            />

            <TextField
              name="description"
              placeholder="Description"
              multiline
              rows={8}
              maxRows={10}
              style={{ backgroundColor: "white" }}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="buttons_container">
        <div>
          <button className="submit_btn" onClick={onClickSubmitButton}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
export default Page1;
