import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import People from "./People";

interface AllMessageDrawerInt {
  top: boolean;
  left: boolean;
  bottom: boolean;
  right: boolean;
}

export default function AllMessageDrawer() {
  const [state, setState]: [
    AllMessageDrawerInt,
    React.Dispatch<React.SetStateAction<AllMessageDrawerInt>>,
  ] = React.useState<AllMessageDrawerInt>({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    <K extends keyof AllMessageDrawerInt>(anchor: K, open: boolean) =>
    (event: any) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = <K extends keyof AllMessageDrawerInt>(anchor: K) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <People />
    </Box>
  );

  return (
    <div className="addedSkillerSidebar">
      <React.Fragment key={"right"}>
        <Button
          style={{ backgroundColor: "#3380FF", textTransform: "capitalize" }}
          variant="contained"
          size="small"
          onClick={toggleDrawer("right", true)}
        >
          All Message
        </Button>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
      {/* {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button style={{ backgroundColor: "#3380FF", textTransform: "capitalize" }} variant='contained' size='small' onClick={toggleDrawer(anchor, true)}>All Message</Button>
          <Drawer
            anchor={anchor as "bottom" | "left" | "right" | "top" | undefined}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))} */}
    </div>
  );
}
