import React, { useEffect } from "react";
import PeopleCard from "./PeopleCard";
import axios from 'axios';
import { useAtom } from "jotai";
import { usersAtom } from "../atoms/ChatAtom";
import { url } from "../../../url";

const People = () => {
  const [users,setUsers] = useAtom(usersAtom);
  useEffect(()=>{
    const fetchUsers = async()=>{
      const response = await axios.get(url + "/user/users",{
        headers: {
          "Content-Type": "application/json", 
          "Authorization": "Bearer "+ localStorage.getItem("token")
        }
      });

      setUsers(response.data.users);
    };

    fetchUsers();
  },[]);
  
  return (
    <div className="conversations__container__people">
      <p className="conversations__container__people__head">All Message</p>
      {
        users.map((user: any)=> <PeopleCard user={user}/>)
      }
    </div>
  );
};

export default People;
