import { useState, useEffect } from "react";
import "../../sass/components/Boxs/ProjectBox.scss";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DoneIcon from "@mui/icons-material/Done";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import flag from "../../icons/flag.jpg";
import question from "../../icons/question.png";
import dots from "../../icons/dots.png";
import projects_gratification_for from "../../data/projects/enums/projects_gratification_for";
import { userTeammatesAtom } from "../../pages/Teammate/atoms/UserTeammatesAtomGlobal";
import { useAtom, useAtomValue } from "jotai";

var displayOptions = 3;

var specialization = "Back-end developer";
var skillsList = [
  "Python +2y",
  "C++ +6m",
  "Python",
  "Electronics",
  "Management",
];
var pteList = ["Hackathon", "Student"];
var pteDisplayNumber = 2;
var gratificationAcceptList = ["Money", "Shares", "Learning"];
var gratificationForList = ["Time", "Milestone"];
var sectorExperienceList = ["Finance", "Banking", "FMCG", "Consulting"];
var projectSoftSkillsDisplayNumber = 2;
var personalAssetsList = ["Public Speaking", "Creativity"];
var personalAssetsDisplayNumber = 2;
var acceptedDeadline = [20, 26];
var location = ["Japan", ""];
var bizOrTech = [20, 80];
var availability = [8, 16];

const TeammateBox = ({
  teammate,
  tokenAvailable,
  addTeammate,
  deleteTeammate,
  hidingBox,
  teammateAdded,
  loading,
}: {
  teammate: any;
  tokenAvailable: boolean;
  addTeammate?: () => Promise<void>;
  deleteTeammate?: () => Promise<void>;
  hidingBox?: boolean;
  teammateAdded?: boolean;
  loading?: boolean;
}) => {
  const [showSkills, setShowSkills] = useState(false);
  const [hideBox, setHideBox] = useState(false);

  const navigate = useNavigate();

  const handleShowSkills = () => {
    if (showSkills === false) {
      setShowSkills(true);
    } else {
      setShowSkills(false);
    }
  };

  const handleHiding = () => {
    if (hidingBox) {
      setHideBox(false);
    }
  };

  if (!hideBox) {
    if (!loading) {
      return (
        <div className="container projects__body__container__left__card">
          <div className="projects__body__container__left__card__top">
            <div className="box_title_wrapper">
              <h3>{teammate.nickname}</h3>
              {tokenAvailable && (
                <>
                  {!teammateAdded ? (
                    <button className="save_button" onClick={addTeammate}>
                      Save
                    </button>
                  ) : (
                    <button
                      className="forgetButton"
                      onClick={() => {
                        if (deleteTeammate) {
                          deleteTeammate();
                          handleHiding();
                        }
                      }}
                    >
                      Forget
                    </button>
                  )}
                </>
              )}
            </div>
            <button className="save_button" style={{margin:"0 0.4em"}} onClick={()=>navigate(`/teammates/${teammate._id}`)}>see more</button>
            <div className="projects__body__container__left__card__top__iconsBox">
              {/* {title === "My Projects" ? (
                <></>
              ) : (
                <Button
                  className="projects__body__container__left__card__top__iconsBox__write"
                  onClick={() => navigate("/conversations")}
                >
                  Message
                  <ModeEditOutlineOutlinedIcon className="projects__body__container__left__card__top__iconsBox__write__icon" />
                </Button>
              )}

              <Button className="projects__body__container__left__card__top__iconsBox__add">
                Save
                <AddOutlinedIcon className="projects__body__container__left__card__top__iconsBox__add__icon" />
              </Button>

              {title === "My Projects" && (
                <>
                  <Button className="projects__body__container__left__card__top__iconsBox__edit">
                    Edit
                    <BorderColorIcon className="projects__body__container__left__card__top__iconsBox__add__icon" />
                  </Button>
                  <Button className="projects__body__container__left__card__top__iconsBox__delete">Delete</Button>
                </>
              )}
              {title === "Found Projects" && (
                <Button className="projects__body__container__left__card__top__iconsBox__add">
                  Added
                  <DoneIcon className="projects__body__container__left__card__top__iconsBox__add__icon" />
                </Button>
              )}
              {title === "Skillers" && (
                <Button className="projects__body__container__left__card__top__iconsBox__add">
                  Add
                  <AddOutlinedIcon className="projects__body__container__left__card__top__iconsBox__add__icon" />
                </Button>
              )}
              {title === "Found Skillers" && (
                <Button className="projects__body__container__left__card__top__iconsBox__add">
                  Added
                  <DoneIcon className="projects__body__container__left__card__top__iconsBox__add__icon" />
                </Button>
              )} */}
            </div>
          </div>

          <div className="projects__body__container__left__card__body team_mate_boxes">

            {/* NEW  */}
            {/* Tools Fluency */}
            {teammate.toolsExperience.length !== 0 && (
              <div className="projects__body__container__left__card__body__specs yellow_parent feature_box_tools small_none">
                
                <div className="question_parent">
                  <p>Tools Fluency</p>
                  {/* <div className="question_hover">
                    <img className="question_image" src={question} alt="" />
                    <div className="yellow_text">
                      <p>Minimum Experience Period</p>
                    </div>
                  </div> */}
                </div>
                <div className="projects__body__container__left__card__body__specs__desc">
                  {/* {project.toolsExperience
                    .sort()
                    .slice(0, displayOptions)
                    ?.map(
                      (obj: { tool: string; mep: number; _id: string }, index: number) => {
                        return (
                          <div key={obj._id} className="flag_design">
                            <div>
                              <small key={index}>
                              +{obj.mep}M {obj.tool}
                              </small>
                            </div>
                            {/* <div className="border_right" />
                            <div className="red_hover_tool">
                              <img src={flag} alt="" />
                              <small className="hover_red_text">
                                Report Tool
                              </small>
                            </div> */}
                          {/* </div>
                        );
                      }
                    )} */}
                  {teammate.toolsExperience
                    .sort()
                    .slice(0, displayOptions)
                    ?.map((obj: { tool: string; mep: number; _id: string }, index: number) => (
                      <span className="flag_design_small" key={index}>+{obj.mep}M {obj.tool}</span>
                    ))}
                    
                </div>
              {teammate.toolsExperience.length > 3 && (
                <div className="dot_container">
                  <div className="dot_hover">
                    <div className="dot_image">
                      <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                    </div>
                    <div className="dot_hover_element">
                      {teammate.toolsExperience
                        .sort()
                        .slice(displayOptions, teammate.toolsExperience.length)
                        ?.map(
                          (
                            obj: { tool: string; mep: number },
                            index: number
                          ) => (
                            <small key={index}>
                              +{obj.mep}M {obj.tool} 
                            </small>
                          )
                        )}
                    </div>
                  </div>
                </div>)}
              </div>
            )}

            {/* Project Types Exp. */}
            {teammate.productType !== 0 && (
              <div className="projects__body__container__left__card__body__specs yellow_parent feature_box_project_type small_none">
                <div className="question_parent1">
                  <p>Product Types Exp.</p>
                  {/* <div className="question_hover">
                    <img className="question_image1" src={question} alt="" />
                    <div className="yellow_text1">
                      <p>Project Type Experience</p>
                    </div>
                  </div> */}
                </div>
                <div className="projects__body__container__left__card__body__specs__desc">
                  {teammate.productType
                    .sort()
                    .slice(0, displayOptions)
                    ?.map((val: string, index: number) => (
                      <span className="flag_design_small" key={index}>{val}</span>
                    ))}
                </div>
                {teammate.productType.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {teammate.productType
                          .slice(displayOptions, teammate.productType.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                )}

              </div>
            )}
            {/* Project Types Exp. */}
            {teammate.projectType !== 0 && (
              <div className="projects__body__container__left__card__body__specs yellow_parent feature_box_project_type small_none">
                <div className="question_parent1">
                  <p>Project Types Exp.</p>
                  {/* <div className="question_hover">
                    <img className="question_image1" src={question} alt="" />
                    <div className="yellow_text1">
                      <p>Project Type Experience</p>
                    </div>
                  </div> */}
                </div>
                <div className="projects__body__container__left__card__body__specs__desc">
                  {teammate.projectType
                    .sort()
                    .slice(0, displayOptions)
                    ?.map((val: string, index: number) => (
                      <span className="flag_design_small" key={index}>{val}</span>
                    ))}
                </div>
                {teammate.projectType.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {teammate.projectType
                          .slice(displayOptions, teammate.projectType.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                )}

              </div>
            )}
            {/* Industries Exp. */}
            {teammate.areaOfExpertise.length !== 0 && (
              <div className="projects__body__container__left__card__body__specs feature_box_industry">
                <div className="red_text_parent">
                  <p>Industries Exp.</p>
                </div>
                <div className="projects__body__container__left__card__body__specs__desc">
                    {/* <div className="projects__body__container__left__card__body__specs__desc"> */}
                      {teammate.areaOfExpertise
                        .sort()
                        .slice(0, displayOptions)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className="flag_design_small" key={index}>{i}</span>
                          </>
                        ))}
                    {/* </div> */}
                  </div>
                
                {teammate.areaOfExpertise.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {teammate.areaOfExpertise
                          .slice(displayOptions, teammate.areaOfExpertise.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
              )}
              </div>
            )}
            {/* Skills Orientation */}
            {teammate.hardSkills.length !== 0 && (
                <div className="projects__body__container__left__card__body__specs feature_box_soft_skills">
                  <p>Skills Orientation</p>
                  <div className="projects__body__container__left__card__body__specs__desc">
                    {/* <div className="projects__body__container__left__card__body__specs__desc"> */}
                      {teammate.hardSkills
                        .sort()
                        .slice(0, displayOptions)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className = "flag_design_small" key={index}>{i}</span>
                          </>
                        ))}
                    {/* </div> */}
                  </div>
                  {teammate.hardSkills.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {teammate.hardSkills
                          .slice(displayOptions, teammate.hardSkills.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                  )}
                </div>
              )}
            {/* Works For */}
            {teammate.offeredGratification.length !== 0 && (
              <div className="projects__body__container__left__card__body__specs feature_box_paid_with small_none">
                <p>Works For</p>
                <div
                  className="projects__body__container__left__card__body__specs__desc"
                  // style={{ display: "inline-block" }}
                >
                  {teammate.offeredGratification?.length ? (
                    teammate.offeredGratification?.sort().slice(0, displayOptions).map(
                      (gratifcation: string) => {
                        return (
                          <span className="flag_design_small" key={gratifcation} style={{ display: "inline-block" }}>
                            {gratifcation}
                          </span>
                        );
                      }
                    )
                  ) : (
                    <span style={{ display: "inline-block" }}>Nothing</span>
                  )}
                </div>
                {teammate.offeredGratification.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {teammate.offeredGratification
                          .slice(displayOptions, teammate.offeredGratification.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
                
              </div>
            )}
            {/* Gets Paid For */}
            {teammate.forGratification.length !== 0 && (
              <div className="projects__body__container__left__card__body__specs feature_box_paid_for small_none">
                <p>Gets Paid For</p>
                <div
                  className="projects__body__container__left__card__body__specs__desc"
                >
                  {
                    teammate.forGratification?.sort().slice(0, displayOptions).map((gratification: string) => (
                      <span className="flag_design_small" key={gratification} style={{ display: "inline-block" }}>
                        {gratification}
                      </span>
                    ))
                  }
                </div>
            {teammate.forGratification.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {teammate.forGratification
                          .slice(displayOptions, teammate.forGratification.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
              )}
              </div>
            )}
 

          </div>
          {showSkills === true && (
            <div className="projects__body__container__left__card__body2">
              {/* Colab Until  */}
              {teammate.deadline && (
              <div className="projects__body__container__left__card__body__specs feature_box_work_duration">
                <p>Collab Until</p>
                <div className="projects__body__container__left__card__body__specs__desc">
                  <span className="flag_design_small" style={{ display: "inline-block" }}>
                    {new Date(teammate.deadline).toLocaleDateString()}
                  </span>
                </div>
              </div>
              )}    
              {/* Soft Skills */}
              {teammate.softSkills.length !== 0 && (
                <div className="projects__body__container__left__card__body__specs feature_box_soft_skills">
                  <p>Soft Skills</p>
                  <div className="projects__body__container__left__card__body__specs__desc">
                    {/* <div className="projects__body__container__left__card__body__specs__desc"> */}
                      {teammate.softSkills
                        .sort()
                        .slice(0, displayOptions)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className = "flag_design_small" key={index}>{i}</span>
                          </>
                        ))}
                    {/* </div> */}
                  </div>
                  {teammate.softSkills.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {teammate.softSkills
                          .slice(displayOptions, teammate.softSkills.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                  )}
                </div>
              )}
              {/* Biz or Tech - different values arrangement*/}
              <div className="projects__body__container__left__card__body__specs feature_box_biz_or_tech">
                  <p>Biz/Tech Acumen</p>
                  <div className="projects__body__container__left__card__body__specs__desc" style={{ display: "grid", gap: "4px" }}>
                    <span style={{ display: "inline-block" }}>
                      BizLike {teammate.bizRole ? teammate.bizRole : 0}%
                    </span>
                    {" "}
                    <span className="flag_design_small" style={{ display: "inline-block" }}>
                      TechLike {teammate.techRole}%
                    </span>
                  </div>
              </div>
              {/* Commitment */}
              <div className="projects__body__container__left__card__body2__specs feature_box_weekly_availability">
                <p>Commitment</p>
                <div className="projects__body__container__left__card__body2__specs__desc">
                  <span style={{ display: "inline-block"}}>
                    {teammate.availabilityFrom} h
                  </span>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      display: "inline-block",
                      // margin: "0",
                    }}
                  >
                    to
                  </span>
                  <span style={{ display: "inline-block" }}>
                    {teammate.availabilityTo} h
                  </span>
                </div>
              </div>
              {/* Working Type */}
              {teammate.workingTypeOffice && teammate.workingTypeRemote && (
              <div className="projects__body__container__left__card__body__specs feature_box_working_type small_none">
                <p>Working type</p>
                <div
                  className="projects__body__container__left__card__body__specs__desc"
                  // style={{ display: "inline-block" }}
                >
                  {teammate.workingTypeOffice ? (
                    <span className = "flag_design_small" style={{ display: "inline-block" }}>Office</span>
                  ) : null}
                  {teammate.workingTypeRemote ? (
                    <span className = "flag_design_small" style={{ display: "inline-block" }}>Remote</span>
                  ) : null}
                  {/* <span style={{ display: "inline-block" }}>Office</span>
                <span style={{ display: "inline-block" }}>Remote</span> */}
                </div>
              </div>
              )}
              {/* Location */}
              {teammate.locations.length !== 0 && (
                <div className="projects__body__container__left__card__body__specs feature_box_location">
                  <p>Location</p>
                  <div className="projects__body__container__left__card__body__specs__desc">
                    {/* <span>
                      {
                      project.locations?.sort().slice(0, displayOptions).map((location: string) => {
                        return location + ", ";
                      })}
                      {/* {location[0]}, {location[1]} */}
                    {/* </span> */}
                    {/* <div className="projects__body__container__left__card__body__specs__desc"> */}
                      {teammate.locations
                        .sort()
                        .slice(0, displayOptions)
                        ?.map((i: string, index: number) => (
                          <>
                            <span className = "flag_design_small" key={index}>{i}</span>
                          </>
                        ))}
                    {/* </div> */}
                  </div>
                  {teammate.locations.length > 3 && (
                  <div className="dot_container">
                    <div className="dot_hover">
                      <div className="dot_image">
                        <img src={dots} alt="" style={{ width: '25px', height: '25px' }}/>
                      </div>
                      <div className="dot_hover_element flex_items">
                        {teammate.locations
                          .slice(displayOptions, teammate.locations.length)
                          ?.map((val: string, index: number) => (
                            <small key={index}>{val}</small>
                          ))}
                      </div>
                    </div>
                  </div>
                  )}
                </div>
              )}
              {/* Languages */}
               {teammate.usedLanguages.length !== 0 && (
                <div className="projects__body__container__left__card__body__specs feature_box_languages">
                  <p>Languages</p>
                  <div
                    className="projects__body__container__left__card__body__specs__desc"
                    // style={{ display: "inline-block" }}
                  >
                    {teammate.usedLanguages
                          .sort()
                          .slice(0, displayOptions)
                          ?.map((i: string, index: number) => (
                            <>
                              <span className="flag_design_small" key={index}>{i}</span>
                            </>
                          ))}
                  </div>
                </div>
              )}
              {/* Availability - different values arrangement*/}
              
            </div>
          )}
          <div className="projects__body__container__left__card__bottom">

            
            <div className="projects__body__container__left__card__bottom__left">
              {/* {title === "Skillers" && (
                <p className="projects__body__container__left__card__bottom__left__tip">
                  <Avatar
                    className="projects__body__container__left__card__bottom__left__tip__iconAvatar"
                    alt="Travis Howard"
                    src="/static/images/avatar/2.jpg"
                  />
                  John
                </p>
              )}
              {title === "Found Skillers" && (
                <p className="projects__body__container__left__card__bottom__left__tip">
                  <Avatar
                    className="projects__body__container__left__card__bottom__left__tip__iconAvatar"
                    alt="Travis Howard"z
                    src="/static/images/avatar/2.jpg"
                  />
                  John
                </p>
              )}
              {title === "Projects" && (
                <>
                  <p className="projects__body__container__left__card__bottom__left__tip">
                    <TipsAndUpdatesIcon className="projects__body__container__left__card__bottom__left__tip__icon" />
                  </p>
                  <span className="projects__body__container__left__card__bottom__left__type">Finance</span>
                  <span className="projects__body__container__left__card__bottom__left__type">Student</span>
                </>
              )}
              {title === "Found Projects" && (
                <>
                  <p className="projects__body__container__left__card__bottom__left__tip">
                    <TipsAndUpdatesIcon className="projects__body__container__left__card__bottom__left__tip__icon" />
                    Finance
                  </p>
                  <span className="projects__body__container__left__card__bottom__left__type">Student</span>
                </>
              )} */}
              {/* <span className="projects__body__container__left__card__bottom__left__dot">
                .
              </span>
              <span className="projects__body__container__left__card__bottom__left__time">
                Posted 4 hours ago
              </span> */}
            </div>


            <button onClick={handleShowSkills}>
              {showSkills === false ? (
                <KeyboardArrowDownOutlinedIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </button>
          </div>
        </div>
      );
    }
  }
};

export default TeammateBox;
