import React, { useState, useRef } from "react";
import styles from "./formlabels.module.scss";
const Username = ({
  username,
  setUsername,
}: {
  username: string;
  setUsername: (newState: string) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [text, setText] = useState<string>("");
  return (
    <div>
      <div className={styles.box}>
        <input
          ref={inputRef}
          placeholder=""
          type="text"
          className={styles.input}
          value={username}
          onChange={(event) => {
            setUsername(event.target.value);
            setText(event.target.value);
          }}
        />
        <label
          htmlFor="username"
          className={styles.label}
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
          style={
            text !== ""
              ? { top: "-5px", fontSize: "11px", color: "#2e79f1" }
              : {}
          }
        >
          Username
        </label>
      </div>
    </div>
  );
};
export default Username;
