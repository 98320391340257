import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import TeammateBox from "../../components/Boxs/TeammateBox";
import Page from "../../components/Page";
import Sidebar from "../../components/Sidebar";
import SidebarDrawer from "../../components/SidebarDrawer";
import { url } from "../../url";
import { teammatesAtom } from "../Teammate/atoms/TeammateAtomGlobal";
import { userTeammatesAtom } from "../Teammate/atoms/UserTeammatesAtomGlobal";
import styles from "./teammate.module.scss";
const FoundSkillers = () => {
  const [teammates, setTeammates] = useAtom(teammatesAtom);
  const [userTeammates, setUserTeammates] = useAtom(userTeammatesAtom);
  // const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // Infinite scroll
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(2);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const teammates = await axios.get(url + "/teammate", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setTeammates(teammates.data.teammates);

      const token = localStorage.getItem("token");
      if (token) {
        const userTeammates = await axios.get(
          url + `/user/teammates/found?page=${currentPage}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserTeammates(userTeammates.data.teammates);
        const morePages = userTeammates.data.teammatesPages == 1 ? false : true;
        if (!morePages) {
          setHasMore(false);
        }
      }
    };
    fetchData();
  }, [currentPage, setTeammates, setUserTeammates]);

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  const fetchMoreSkillers = async () => {
    await axios
      .get(url + `/user/teammates/found?page=${currentPage}`)
      .then((res) => {
        const teammatesArr = res.data.teammates;
        setTeammates((prevTeammates: any[]) => [
          ...prevTeammates,
          ...teammatesArr,
        ]);

        teammatesArr.length > 0 ? setHasMore(true) : setHasMore(false);
      })
      .catch((err) => console.error(err));

    setIndex((prevIndex) => prevIndex + 1);
  };

  const tokenAvailable = () => {
    const token = localStorage.getItem("token");
    if (token) {
      return true;
    }
    return false;
  };

  const deleteFoundedTeammate = async (teammate: any) => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await axios.delete(url + `/user/teammates/${teammate._id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setUserTeammates(
          userTeammates.filter(function (t: any) {
            return t !== teammate._id;
          })
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Page>
      <div className="foundSkillers">
        <header className="foundSkillers__header">
          <div className="foundSkillers__header__sidebar">
            <SidebarDrawer />
          </div>
        </header>
        <div className="foundSkillers__container">
          <div className="foundSkillers__container__sidebar">
            <Sidebar />
          </div>
          <div className="foundSkillers__container__skills">
            <InfiniteScroll
              dataLength={teammates.length}
              next={fetchMoreSkillers}
              hasMore={hasMore}
              loader={<CircularProgress />}
            >
              {teammates.map((teammate: any) => {
                if (userTeammates.includes(teammate._id)) {
                  return (
                    <TeammateBox
                      teammate={teammate}
                      tokenAvailable={tokenAvailable()}
                      deleteTeammate={() => deleteFoundedTeammate(teammate)}
                      hidingBox={true}
                      teammateAdded={true}
                    />
                  );
                }
              })}
              {userTeammates.length === 0 && (
                <div className={styles.noContentBox}>
                  <p className={styles.noContentText}>No teammates added...</p>
                  <Button
                    onClick={() => navigate("/teammates")}
                    variant="contained"
                    size="small"
                    style={{ textTransform: "none" }}
                  >
                    Add some
                  </Button>
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
        {/* <Pagination
          count={pages}
          color="primary"
          onChange={(event, page) => handlePageChange(page)}
          showFirstButton
          showLastButton
          style={{width:"fit-content", margin:"0 auto"}}
        /> */}
      </div>
    </Page>
  );
};

export default FoundSkillers;
