import React, { useState } from "react";
// import "./Component2.css";
import "../../sass/components/Filters/OfferedWorkingTypeFilter.scss";
const OfferedWorkingTypeFilter = ({
  activeOptions,
  setActiveOptions,
  newArray,
  setNewArray,
  show,
  setShow
}: {
  activeOptions: any;
  setActiveOptions: (newState: any) => void;
  newArray: string[];
  setNewArray: (newState: any) => void;
  show: boolean;
  setShow: (key: string) => void;
}) => {
  // const [show, setShow] = useState<boolean>(false);

  const [options, setOptions] = useState<string[]>(["Remote", "Office"]);
  // const [activeOptions, setActiveOptions] = useState<boolean[]>(options.map(() => false));
  // const [newArray, setNewArray] = useState<string[]>([]);

  const handleClick2 = (sectionIndex: number, optionIndex: number) => {
    const index = sectionIndex * 5 + optionIndex;
    const selectedOption = options[index];
    const updatedActiveOptions = [...activeOptions];
    updatedActiveOptions[index] = !updatedActiveOptions[index];

    if (!updatedActiveOptions[index]) {
      const updatedNewArray = newArray.filter(
        (option) => option !== selectedOption,
      );
      setNewArray(updatedNewArray);
    } else {
      setNewArray([...newArray, selectedOption]);
    }

    setActiveOptions(updatedActiveOptions);
  };  
  return (
  <>
  <div className="component" >
    <div onClick={() => setShow("offeredWorkingType")} className="title">
      <div className="" style={{display:"flex"}}>
        <span style={{}}>Working Type</span>
        <p className="ml_3" style={{ marginLeft: "4px" }}>
          {newArray.length > 0 && <span>({newArray.length})</span>}
        </p>
      </div>
      <i className={`${show && "rotate"} fa-solid fa-chevron-down`}></i>
    </div>
    {show && (
      <div className="show_component">
        <div className="row" >
          <div className="col-6" style={{display:"flex", justifyContent:"center"}}>
            <div className="all" style={{display:"flex",marginTop:"8px",justifyContent:"center"}}>
              {options.slice(0, 6).map((option, index) => (
                <div
                  onClick={() => handleClick2(0, index)}
                  key={index}
                  className="option" >
                  <div className="checkbox">
                    {" "}
                    <div
                      className={`option ${activeOptions[index]
                        ? "picked"
                        : "inner_box"
                        }`}
                    ></div>
                  </div>
                  <p
                    className="p_option"
                    style={{
                      background: activeOptions[index]
                        ? "rgba(94, 110, 120, 0.20)"
                        : "rgba(94, 110, 120, 0.10)",
                      padding: activeOptions[index]
                        ? "5px 8px"
                        : "5px 8px",
                      color: activeOptions[index]
                        ? "#5E6E78"
                        : "#5E6E78",
                      borderRadius: "40px"
                    }}
                  >
                    {option}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
</>
  );
};

export default OfferedWorkingTypeFilter;
