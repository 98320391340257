import React, { useEffect, useState } from "react";
import { pageAtom } from "../atoms/AddTeammateAtomGlobal";
import { useAtom } from "jotai";
import "../../../sass/components/newInputForm/CarouselPage.scss";
import Page from "../../../components/Page";
import Page1 from "../NewAddTeammate/Page1";
import Page2 from "../NewAddTeammate/Page2";
import Page3 from "../NewAddTeammate/Page3";
import { useNavigate } from "react-router-dom";

const AddTeammate = () => {
  const [page, setPage] = useAtom(pageAtom);
  let element = null;
  const navigate = useNavigate();


  useEffect(()=>{
    if (localStorage.getItem("token") === null) {
      navigate("/login")
    }
  },[]);

  if (localStorage.getItem("token")!== null) {
    element = <Page1 />;
    
    // switch (page) {
    //   case 0: {
    //     element = <Page1 />;
    //     break;
    //   }
    //   case 1: {
    //     element = <Page2 />;
    //     break;
    //   }
    //   case 2: {
    //     element = <Page3 />;
    //     break;
    //   }
    // }
  }


  return <Page>{element}</Page>;
};

export default AddTeammate;
