export default[
    "3D Printing", "AI Model", "API", "Additive Manufacturing", "Animation", "Architecture",
    "Articles", "Audiobooks", "Augmented Reality Application", "Augmented Reality Experiences",
    "Automated Workflow Systems", "Automotive Products", "Biodegradable Products", "Biohacking Products",
    "Biotechnology Products", "Blockchain Products", "Blockchain-based Products", "Blog", "Books", "Books",
    "Browser Extension", "Carbon Capture Technology", "Certification Programs", "Chatbot", "Clothing & Apparel",
    "Cloud Platform", "Cloud Storage Solutions", "Collaboration Tools", "Consulting Services", "Consumer Electronics",
    "Customer Support Platforms", "Cybersecurity Tools", "Database System", "Desktop Application", "Digital Art",
    "Digital Content", "Digital Marketing Tools", "Drones", "E-commerce Platform", "EdTech Platforms",
    "Educational Games", "Electric Vehicles", "Electrical Device", "Fashion Design", "Fitness Products",
    "Food & Beverages", "Footwear", "Freelance Platforms", "Furniture", "Genetic Engineering Tools", "Graphic Design",
    "Hardware", "Health Supplements", "Home Appliances", "Home Décor", "Homework Helpers", "Industrial Equipment",
    "Interactive Tools for Learning", "Interior Design", "IoT Devices", "Job Boards", "Journals", "Learning Apps",
    "Learning Management System", "Machine Learning Model", "Magazines", "Marketing Platforms", "Medical Devices",
    "Microservices", "Mobile Application", "Music", "NFTs", "News Platforms", "Newsletters", "Online Course", "Online Courses",
    "Online Marketplaces", "Operating System", "Packaging Design", "Payment Gateways", "Personal Care Products",
    "Photography", "Podcasts", "Print Materials", "Product Design", "Project Management Tools", "Publications",
    "Quantum Computing Solutions", "Renewable Energy Devices", "Robotic Systems", "Simulation Software",
    "Smart Appliances", "Smart Cities Solutions", "Smart Glasses", "Social Media Platforms", "Software-as-a-Service",
    "Space Exploration Technologies", "Sports Equipment", "Subscription Services", "Synthetic Biology Products",
    "Toys", "Tutorials", "Video Game", "Video Production", "Virtual Assistant", "Virtual Reality Application",
    "Visual Art", "Wearable Technology", "Web Application", "Website", "eBooks"
]