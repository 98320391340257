import AreaOfExperties from "../../../components/Filters_Projects/AreaOfExpertiesFilter";
import ForGratification from "../../../components/Filters_Projects/ForGratificationFilter";
import OfferedGratification from "../../../components/Filters_Projects/OfferedGratificationFilter";
import ProjectName from "../../../components/Filters_Projects/ProjectNameFilter";
import ProjectType from "../../../components/Filters_Projects/ProjectTypeFilter";
import ProductType from "../../../components/Filters_Projects/ProductTypeFilter";
import SoftSkills from "../../../components/Filters_Projects/SoftSkillsFilter";
import ToolsExperienceFilter from "../../../components/Filters_Projects/ToolsExperienceFilter";
// import ExperienceTools from "../../../components/InputFields/ExperienceTools";
import { TextField } from "@mui/material";
import axios from "axios";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import HardSkills from "../../../components/Filters_Projects/HardSkillsFilter";
import Location from "../../../components/Filters_Projects/LocationFilter";
import OfferedWorkingType from "../../../components/Filters_Projects/OfferedWorkingTypeFilter";
import ProjectDeadline from "../../../components/Filters_Projects/ProjectDeadlineFilter";
import Availability from "../../../components/Filters_Projects/RequiredAvailabilityFilter";
import UsedLanguages from "../../../components/Filters_Projects/UsedLanguagesFilter";
import { url } from "../../../url";
import { useDataFromAddProjects } from "../../../utils/useDataFromAddProject";
import { useOpenCloseFiltersAddProject } from "../../../utils/useOpenCloseFiltersAddProject";
import { openAtom, pageAtom } from "../atoms/AddProjectAtomGlobal";
import {
  forGratificationAtom,
  offeredGratificationAtom,
  projectNameAtom,
  productTypeAtom,
  projectTypeAtom,
  requiredAreaOfExpertiseAtom,
  softSkillsAtom,
  toolsExperienceAtom,
  toolsExperienceOptionViewsAtom,
} from "../atoms/AddProjectAtomPage1";
import {
  descriptionAtom,
  hardSkillsAtom,
  offeredWorkingTypeActiveOptionsAtom,
  offeredWorkingTypeAtomNewArray,
  projectDeadlineAtom,
} from "../atoms/AddProjectAtomPage2";
import {
  bizOrTechRoleAtomLeftValue,
  bizOrTechRoleAtomRightValue,
  locationsAtom,
  requiredAvailabilityAtomEndValue,
  requiredAvailabilityAtomOption,
  requiredAvailabilityAtomStartValue,
  usedLanguagesAtom,
} from "../atoms/AddProjectAtomPage3";

const Page1 = () => {
  const setPage = useSetAtom(pageAtom);
  const [projectName, setProjectName] = useAtom(projectNameAtom);
  const [toolsExperience, setToolsExperience] =
    useAtom<string[]>(toolsExperienceAtom);
  const [toolsExperienceOptionViews, setToolsExperienceOptionViews] = useAtom(
    toolsExperienceOptionViewsAtom
  );
  const [productType, setProductType] = useAtom<string[]>(productTypeAtom);
  const [projectType, setProjectType] = useAtom<string[]>(projectTypeAtom);
  const [offeredGratification, setOfferedGratification] = useAtom<string[]>(
    offeredGratificationAtom
  );
  const [softSkills, setSoftSkills] = useAtom<string[]>(softSkillsAtom);
  const [requiredAreaOfExpertise, setRequiredAreaOfExpertise] = useAtom<
    string[]
  >(requiredAreaOfExpertiseAtom);
  const [forGratification, setForGratification] =
    useAtom<string[]>(forGratificationAtom);
  const openFilters = useAtomValue(openAtom);
  const enableAndResetFilters = useOpenCloseFiltersAddProject();
  const data = useDataFromAddProjects();
  const navigate = useNavigate();
  const [projectDeadline, setProjectDeadline] = useAtom(projectDeadlineAtom);
  const [hardSkills, sethardSkills] = useAtom<string[]>(hardSkillsAtom);
  const [offeredWorkingTypeNewArray, setOfferedWorkingTypeNewArray] = useAtom<
    string[]
  >(offeredWorkingTypeAtomNewArray);
  const [offeredWorkingTypeActiveOptions, setOfferedWorkingTypeActiveOptions] =
    useAtom<boolean[]>(offeredWorkingTypeActiveOptionsAtom);
  const [description, setDescription] = useAtom(descriptionAtom);
  const [usedLanguages, setUsedLanguages] =
    useAtom<string[]>(usedLanguagesAtom);
  const [bizOrTechRoleLeftValue, setBizOrTechRoleLeftValue] = useAtom(
    bizOrTechRoleAtomLeftValue
  );
  const [bizOrTechRoleRightValue, setBizOrTechRoleRightValue] = useAtom(
    bizOrTechRoleAtomRightValue
  );
  const [requiredAvailabilityStartValue, setRequiredAvailabilityStartValue] =
    useAtom(requiredAvailabilityAtomStartValue);
  const [requiredAvailabilityEndValue, setRequiredAvailabilityEndValue] =
    useAtom(requiredAvailabilityAtomEndValue);
  const [requiredAvailabilityOption, setRequiredAvailabilityOption] = useAtom(
    requiredAvailabilityAtomOption
  );
  const [locations, setLocations] = useAtom<string[]>(locationsAtom);
  const onClickSubmitButton = async () => {
    const token = localStorage.getItem("token");
    try {
      if (
        projectName.length &&
        toolsExperience.length &&
        projectType.length &&
        productType.length &&
        offeredGratification.length &&
        forGratification.length
      ) {
        const res = await axios.post(url + "/project", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(res.data);
        
        navigate(`/projects/my`);
      } else {
        alert("You need to fill required fields");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div className="page1_component" style={{ maxWidth: "100%" }}>
        <div
          className="columns_container"
          style={{
            display: "flex",
            // flexDirection: "row",
            gap: "25px",
            width: "70%", // Make the container take the full width
          }}
        >
          <div
            className="column"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              flex: 1, // Both columns take equal space
            }}
          >
            <ProjectName
              projectName={projectName}
              setProjectName={setProjectName}
            />
            <ToolsExperienceFilter
              pickedOptions={toolsExperience}
              setPickedOptions={setToolsExperience}
              optionViews={toolsExperienceOptionViews}
              setOptionViews={setToolsExperienceOptionViews}
              show={openFilters.toolsExperience}
              setShow={enableAndResetFilters}
            />
            <ProductType
              pickedOptions={productType}
              setPickedOptions={setProductType}
              show={openFilters.productType}
              setShow={enableAndResetFilters}
            />
            <ProjectType
              pickedOptions={projectType}
              setPickedOptions={setProjectType}
              show={openFilters.projectType}
              setShow={enableAndResetFilters}
            />
            <OfferedGratification
              pickedOptions={offeredGratification}
              setPickedOptions={setOfferedGratification}
              show={openFilters.offeredGratification}
              setShow={enableAndResetFilters}
            />
            <ForGratification
              pickedOptions={forGratification}
              setPickedOptions={setForGratification}
              show={openFilters.forGratification}
              setShow={enableAndResetFilters}
            />
            <HardSkills
              pickedOptions={hardSkills}
              setPickedOptions={sethardSkills}
              show={openFilters.hardSkills}
              setShow={enableAndResetFilters}
            />
            <AreaOfExperties
              pickedOptions={requiredAreaOfExpertise}
              setPickedOptions={setRequiredAreaOfExpertise}
              show={openFilters.requiredAreaOfExpertise}
              setShow={enableAndResetFilters}
            />
            <ProjectDeadline
              dateState={projectDeadline}
              setDateState={setProjectDeadline}
              open={openFilters.projectDeadline}
              setOpen={enableAndResetFilters}
            />
          </div>

          <div
            className="column"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              flex: 1,
            }}
          >
            <SoftSkills
              pickedOptions={softSkills}
              setPickedOptions={setSoftSkills}
              show={openFilters.softSkills}
              setShow={enableAndResetFilters}
            />

            <Availability
              startValue={requiredAvailabilityStartValue}
              setStartValue={setRequiredAvailabilityStartValue}
              endValue={requiredAvailabilityEndValue}
              setEndValue={setRequiredAvailabilityEndValue}
              selectedOption={requiredAvailabilityOption}
              setSelectedOption={setRequiredAvailabilityOption}
              open={openFilters.requiredAvailability}
              setOpen={enableAndResetFilters}
            />
            <OfferedWorkingType
              newArray={offeredWorkingTypeNewArray}
              setNewArray={setOfferedWorkingTypeNewArray}
              activeOptions={offeredWorkingTypeActiveOptions}
              setActiveOptions={setOfferedWorkingTypeActiveOptions}
              show={openFilters.offeredWorkingType}
              setShow={enableAndResetFilters}
            />
            <Location
              pickedOptions={locations}
              setPickedOptions={setLocations}
              show={openFilters.locations}
              setShow={enableAndResetFilters}
            />
            <UsedLanguages
              pickedOptions={usedLanguages}
              setPickedOptions={setUsedLanguages}
              show={openFilters.usedLanguages}
              setShow={enableAndResetFilters}
            />
            <TextField
              placeholder="Description"
              multiline
              rows={8}
              maxRows={10}
              style={{ backgroundColor: "white" }}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />

            {/*           
          <BizOrTechRole
            leftValue={bizOrTechRoleLeftValue}
            setLeftValue={setBizOrTechRoleLeftValue}
            rightValue={bizOrTechRoleRightValue}
            setRightValue={setBizOrTechRoleRightValue}
            open={openFilters.bizOrTech}
            setOpen={enableAndResetFilters}
          /> */}
          </div>
        </div>
      </div>
      <div className="buttons_container">
        <div>
          <button className="submit_btn" onClick={onClickSubmitButton}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
export default Page1;
