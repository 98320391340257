import React, { useEffect } from "react";
import Page from "../../components/Page";
import Sidebar from "../../components/Sidebar";
import SidebarDrawer from "../../components/SidebarDrawer";
import AllMessageDrawer from "./Components/AllMessageDrawer";
import People from "./Components/People";
import ChatView from "./Components/ChatView";
import { chosenUserAtom, conversationAtom, usersAtom } from "./atoms/ChatAtom";
import { useAtom, useAtomValue } from "jotai";
import axios from "axios";
import {url} from "../../url";
 
const Conversations = () =>{
  const [chosenUser, setChosenUser] = useAtom(chosenUserAtom);
  const [conversation,setConversation] = useAtom(conversationAtom);
  const users = useAtomValue(usersAtom)
  
  useEffect(()=>{
    setChosenUser(users[0])
  },[users.length])

  useEffect(()=>{
    const fetchConversation = async()=>{
      if(chosenUser?._id){
        const response = await axios.get(url + `/chat/conversation?friendId=${chosenUser._id}`,{headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ localStorage.getItem("token")
        }});
        const tempConversation = response.data.conversation;
        setConversation(tempConversation);
      }
    };
    fetchConversation();
  },[chosenUser]);


  return (
    <Page>
      <div className="conversations">
        <header className="conversations__header">
          <div className="conversations__header__sidebar">
            <SidebarDrawer />
          </div>
          <div className="conversations__header__people">
            <AllMessageDrawer />
          </div>
        </header>
        <div className="conversations__container">
          <div className="conversations__container__sidebar">
            <Sidebar />
          </div>
          {chosenUser?._id && conversation?._id ? <ChatView/> : null}
          <div className="peopleBox">
            <People />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Conversations;
