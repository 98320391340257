import React from "react";
import Page from "../../components/Page";
import Email from "../../components/InputFields/Email";
import Password from "../../components/InputFields/Password";
import { useState, useEffect } from "react";
import { url } from "../../url";
import { redirect, useNavigate } from 'react-router-dom'
import axios from "axios";
import { NoAccounts } from "@mui/icons-material";
import styles from "./login.module.scss"


const Login = () => {
  
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const navigate = useNavigate()

  const signIn =  async (email: string, password: string) => {
    const loginData = {
      email: email,
      password:  password
    }
    let response;
    try {
      response = await axios.post(
      url + `/auth/login`, 
      loginData,
      {
        headers: {
          "Content-Type": "application/json",
        }
      }
    );
    } catch (error: any) {
      response = error.response
      console.log(response)
    }

    return response.data
  }

  const loginClick = async () => {
    setEmailError('')
    setPasswordError('')
  
    if ('' === email) {
      setEmailError('Please enter your email')
      return
    }
  
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email')
      return
    }
  
    if ('' === password) {
      setPasswordError('Please enter a password')
      return
    }

    const responseData =  await signIn(email, password)
    console.log(responseData)
    if (responseData.message == "Cant find user") {
      setEmailError('Cant find user')
      return
    }
    else if (responseData.message == "Wrong Password!") {
      setPasswordError('Wrong Password!')
      return
    }
    else {
      localStorage.setItem('token', responseData.token)
      navigate("/")
    }

  }
  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <Page>
      <section className={styles.homeBox}>
        <div className={styles.box}>
          <div className={styles.nameBox}>
            <p className={styles.nameBox__welcome}>Welcome to</p>
            <h2 className={styles.nameBox__brandName}>
              Slinge<span>rr</span>
            </h2>
          </div>
          <div className={styles.logSigBox}>
            <Email
            email={email}
            setEmail={setEmail}
            />
            <label className={styles.logSigBox__error}>{emailError}</label>
            <Password
            password={password}
            setPassword={setPassword}
            />
            <label className={styles.logSigBox__error}>{passwordError}</label>
            <div className={styles.logSigBox__btns}>
              <button 
              className={styles.logSigBox__logInBtn}
              onClick={loginClick}
              >
                Login
              </button>
            
              <p className={styles.logSigBox__noAccountText}>Not created account yet?</p>
              <div className={styles.underline}></div>
              <button className={styles.logSigBox__logInBtn}
              onClick={() => navigate('/signUp')}
              >
              Sign Up
              </button>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

export default Login;
