import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutModal from "./LogoutModal";

const Sidebar = () => {
  const [buttonClicked, setButtonClicked] = useState<string | number>(0);

  const handleButtonClicked = (linkId: string | number) => {
    setButtonClicked(linkId);
  };

  useEffect(() => {
    const currentPath = window.location.pathname as string;
    setButtonClicked(currentPath);
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar__links">
        <Link
          to="/conversations"
          className={`sidebar__links__link ${buttonClicked === "/conversations" ? "sidebar-button-clicked" : ""}`}
          onClick={() => handleButtonClicked("/conversations")}
        >
          <QuestionAnswerIcon className="sidebar__links__link__icon" />
          Conversations
        </Link>
        <Link
          to="/teammates/found"
          className={`sidebar__links__link ${buttonClicked === "/teammates/found" ? "sidebar-button-clicked" : ""}`}
          onClick={() => handleButtonClicked("/skillers/found")}
        >
          <DesktopMacIcon className="sidebar__links__link__icon" />
          Found Skillers
        </Link>
        <Link
          to="/projects/found"
          className={`sidebar__links__link ${buttonClicked === "/projects/found" ? "sidebar-button-clicked" : ""}`}
          onClick={() => handleButtonClicked("/projects/found")}
        >
          <LightbulbIcon className="sidebar__links__link__icon" />
          Found Projects
        </Link>
        <Link
          to="/skillers/my"
          className={`sidebar__links__link ${buttonClicked === "/skillers/my" ? "sidebar-button-clicked" : ""}`}
          onClick={() => handleButtonClicked("/skillers/my")}
        >
          <DesktopMacIcon className="sidebar__links__link__icon" />
          My Skills
        </Link>
        <Link
          to="/projects/my"
          className={`sidebar__links__link ${buttonClicked === "/projects/my" ? "sidebar-button-clicked" : ""}`}
          onClick={() => handleButtonClicked("/projects/my")}
        >
          <LightbulbIcon className="sidebar__links__link__icon" />
          My Projects
        </Link>
        <Link
          to="/settings"
          className={`sidebar__links__link ${buttonClicked === "/settings" ? "sidebar-button-clicked" : ""}`}
          onClick={() => handleButtonClicked("/settings")}
        >
          <SettingsIcon className="sidebar__links__link__icon" />
          Settings
        </Link>
      </div>
      <LogoutModal
        buttonClicked={buttonClicked}
        handleButtonClicked={handleButtonClicked}
      />
    </div>
  );
};

export default Sidebar;
