import { useAtom } from "jotai";
// import {
//     nicknameAtom,
//     toolsExperienceAtom,
//     toolsExperienceOptionViewsAtom,
//     projectTypeAtom,
//     offeredGratificationAtom,
//     requiredProjectExperienceAtom,
//     requiredAreaOfExpertiseAtom,
//     forGratificationAtom,
//     usedLanguagesAtom,
//     bizOrTechRoleAtomLeftValue,
//     bizOrTechRoleAtomRightValue,
//     requiredAvailabilityAtomStartValue,
//     requiredAvailabilityAtomEndValue,
//     requiredAvailabilityAtomOption,
//     locationsAtom,
//     projectBranchAtom,
//     offeredWorkingTypeAtomNewArray,
//     offeredWorkingTypeActiveOptionsAtom,
//   } from "../pages/Teammate/atoms/EditTeammateAtomGlobal";

import {
    nicknameAtom,
    toolsExperienceAtom,
    toolsExperienceOptionViewsAtom,
    projectTypeAtom,
    offeredGratificationAtom,
    softSkillsAtom,
    requiredAreaOfExpertiseAtom,
    forGratificationAtom
} from "../pages/Teammate/atoms/AddTeammateAtomPage1";

import {
    hardSkillsAtom,
    offeredWorkingTypeAtomNewArray,
    offeredWorkingTypeActiveOptionsAtom
} from "../pages/Teammate/atoms/AddTeammateAtomPage2";

import {
    usedLanguagesAtom,
    bizOrTechRoleAtomLeftValue,
    bizOrTechRoleAtomRightValue,
    requiredAvailabilityAtomStartValue,
    requiredAvailabilityAtomEndValue,
    requiredAvailabilityAtomOption,
    locationsAtom
} from "../pages/Teammate/atoms/AddTeammateAtomPage3";


export const useResetTeammateAtom = () => {

    // Page 1
    const [nickname, setNickname] = useAtom(nicknameAtom);
    const [toolsExperience, setToolsExperience] =
    useAtom(toolsExperienceAtom);
    const [toolsExperienceOptionViews, setToolsExperienceOptionViews] = useAtom(
    toolsExperienceOptionViewsAtom,
    );
    const [projectType, setProjectType] = useAtom(projectTypeAtom);
    const [offeredGratification, setOfferedGratification] = useAtom(
    offeredGratificationAtom,
    );
    const [softSkills, setSoftSkills] = useAtom(softSkillsAtom);
    const [requiredAreaOfExpertise, setRequiredAreaOfExpertise] = useAtom(requiredAreaOfExpertiseAtom);
    const [forGratification, setForGratification] =
    useAtom(forGratificationAtom);

    // Page 2
    const [hardSkills, setHardSkills] =
    useAtom(hardSkillsAtom);
    const [offeredWorkingTypeNewArray, setOfferedWorkingTypeNewArray] = useAtom(offeredWorkingTypeAtomNewArray);
    const [offeredWorkingTypeActiveOptions, setOfferedWorkingTypeActiveOptions] =
    useAtom(offeredWorkingTypeActiveOptionsAtom);

    // Page 3
    const [usedLanguages, setUsedLanguages] =
    useAtom(usedLanguagesAtom);
    const [bizOrTechRoleLeftValue, setBizOrTechRoleLeftValue] = useAtom(
    bizOrTechRoleAtomLeftValue,
    );
    const [bizOrTechRoleRightValue, setBizOrTechRoleRightValue] = useAtom(
    bizOrTechRoleAtomRightValue,
    );
    const [requiredAvailabilityStartValue, setRequiredAvailabilityStartValue] =
    useAtom(requiredAvailabilityAtomStartValue);
    const [requiredAvailabilityEndValue, setRequiredAvailabilityEndValue] =
    useAtom(requiredAvailabilityAtomEndValue);
    const [requiredAvailabilityOption, setRequiredAvailabilityOption] = useAtom(
    requiredAvailabilityAtomOption,
    );
    const [locations, setLocations] = useAtom(locationsAtom);


    const resetTeammateAddAtom = ()=>{
        setNickname("");
        setToolsExperience([]);
        setProjectType([]);
        setOfferedGratification([]);
        setSoftSkills([]);
        setRequiredAreaOfExpertise([]);
        setForGratification([]);
        setHardSkills([]);
        setOfferedWorkingTypeNewArray([]);
        setOfferedWorkingTypeActiveOptions([]);
        setUsedLanguages([]);
        setBizOrTechRoleLeftValue("");
        setBizOrTechRoleRightValue("");
        setRequiredAvailabilityStartValue(10);
        setRequiredAvailabilityEndValue(15);
        setRequiredAvailabilityOption("Monthly");
        setLocations([]);
    }

    return resetTeammateAddAtom;
}
