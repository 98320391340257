import { useAtomValue } from "jotai";
import {
  nicknameAtom,
  softSkillsAtom,
  toolsExperienceAtom,
  projectTypeAtom,
  offeredGratificationAtom,
  requiredAreaOfExpertiseAtom,
  toolsExperienceOptionViewsAtom,
  forGratificationAtom,
  hardSkillsAtom,
  offeredWorkingTypeActiveOptionsAtom,
  usedLanguagesAtom,
  bizOrTechRoleAtomLeftValue,
  bizOrTechRoleAtomRightValue,
  requiredAvailabilityAtomStartValue,
  requiredAvailabilityAtomEndValue,
  requiredAvailabilityAtomOption,
  locationsAtom,
  descriptionAtom
} from "../pages/Teammate/atoms/EditTeammateAtomGlobal";


interface reqBody {
  softSkills?: string[];
  areaOfExpertise?: string[];
  toolsExperience?: any;
  hardSkills?: string[];
  offeredGratification?: string[];
  forGratification?: string[];
  nickname?: string;
  projectType?: string[];
  bizRole?: number;
  techRole?: number;
  availabilityFrom?: number;
  availabilityTo?: number;
  availabilityType?: string;
  workingTypeOffice?: boolean;
  workingTypeRemote?: boolean;
  usedLanguages?: string[];
  description?: string;
  locations?: string[];
}

const useDataFromEditTeammate = (): reqBody => {
  const nickname = useAtomValue(nicknameAtom);
  const softSkills = useAtomValue(softSkillsAtom);
  const toolsExperience = useAtomValue(toolsExperienceAtom);
  const projectType = useAtomValue(projectTypeAtom);
  const offeredGratification = useAtomValue(offeredGratificationAtom);
  const forGratification = useAtomValue(forGratificationAtom);
  const requiredAreaOfExpertise = useAtomValue(requiredAreaOfExpertiseAtom);
  const toolsExperienceOptionViews = useAtomValue(
    toolsExperienceOptionViewsAtom,
  ); // months!
  const hardSkills = useAtomValue(hardSkillsAtom);
  const offeredWorkingTypeActiveOptions = useAtomValue(
    offeredWorkingTypeActiveOptionsAtom,
  );
  const usedLanguages = useAtomValue(usedLanguagesAtom);
  const bizOrTechRoleLeftValue = useAtomValue(bizOrTechRoleAtomLeftValue);
  const bizOrTechRoleRightValue = useAtomValue(bizOrTechRoleAtomRightValue);
  const requiredAvailabilityStartValue = useAtomValue(
    requiredAvailabilityAtomStartValue,
  );
  const requiredAvailabilityEndValue = useAtomValue(
    requiredAvailabilityAtomEndValue,
  );
  const requiredAvailabilityOption = useAtomValue(
    requiredAvailabilityAtomOption,
  );
  const locations = useAtomValue(locationsAtom);
  const description = useAtomValue(descriptionAtom);

  let body: reqBody = {};
  body.nickname = nickname;
  body.softSkills = softSkills;
  body.toolsExperience = [];
  body.projectType = projectType;
  body.offeredGratification = offeredGratification;
  body.forGratification = forGratification;
  body.areaOfExpertise = requiredAreaOfExpertise;
  body.hardSkills = hardSkills;
  body.workingTypeRemote = offeredWorkingTypeActiveOptions[0];
  body.workingTypeOffice = offeredWorkingTypeActiveOptions[1];
  body.usedLanguages = usedLanguages;
  body.bizRole =
    bizOrTechRoleLeftValue == "" ? 50 : parseInt(bizOrTechRoleLeftValue);
  body.techRole =
    bizOrTechRoleRightValue == "" ? 50 : parseInt(bizOrTechRoleRightValue);
  body.availabilityFrom = requiredAvailabilityStartValue;
  body.availabilityTo = requiredAvailabilityEndValue;
  body.availabilityType = requiredAvailabilityOption;
  body.locations = locations;
  body.description = description;

  const keys = Object.keys(body);
  for (const key of keys) {
    if (!body[key as keyof reqBody]) {
      delete body[key as keyof reqBody];
    }
  } // is array empty we can do sth like that

  for (const tool of toolsExperience) {
    body.toolsExperience.push({
      tool: tool,
      mep: toolsExperienceOptionViews[tool],
    });
  }

  return body;
};

export { useDataFromEditTeammate };
