import React from "react";
import Page from "../../components/Page";
const SendMoreOffers = () => {
  return (
    <Page>
      <div className="sendMoreOffers"></div>
    </Page>
  );
};

export default SendMoreOffers;
