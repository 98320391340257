import React, { useState } from "react";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import FlagIcon from "@mui/icons-material/Flag";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import SchoolIcon from "@mui/icons-material/School";
import { Link } from "react-router-dom";
const HomeCard = ({
  cardNumber,
  title,
  description,
}: {
  cardNumber: number;
  title: string;
  description: string;
}) => {
  const [cardColor, setCardColor] = useState(false);

  const handleCardColorChange = () => {
    setCardColor(true);
  };

  const handleCardColorRemove = () => {
    setCardColor(false);
  };

  return (
    <Link
      to={`/home/card/${title}`}
      className={`home__container__right__card ${cardColor ? `home__container__right__card${cardNumber}` : ""}`}
      onMouseEnter={handleCardColorChange}
      onMouseLeave={handleCardColorRemove}
    >
      <div className="home__container__right__card__iconBox">
        {title === "Teammate" && (
          <TipsAndUpdatesIcon
            className={`home__container__right__card__iconBox__icon ${cardColor ? `home__container__right__card${cardNumber}__iconBox__icon${cardNumber}` : ""}`}
            style={{color: cardColor ? "#3380FF":"", transition: "all 0.3s"}}
          />
        )}
        {title === "Leader" && (
          <FlagIcon
            className={`home__container__right__card__iconBox__icon ${cardColor ? `home__container__right__card${cardNumber}__iconBox__icon${cardNumber}` : ""}`}
            style={{color: cardColor ? "#FFB849":"", transition: "all 0.3s"}}
          />
        )}
        {title === "Skiller" && (
          <DesktopMacIcon
            className={`home__container__right__card__iconBox__icon ${cardColor ? `home__container__right__card${cardNumber}__iconBox__icon${cardNumber}` : ""}`}
            style={{color: cardColor ? "#9181DB":"", transition: "all 0.3s"}}
          />
        )}
        {title === "Intern" && (
          <SchoolIcon
            className={`home__container__right__card__iconBox__icon ${cardColor ? `home__container__right__card${cardNumber}__iconBox__icon${cardNumber}` : ""}`}
            style={{color: cardColor ? "#D06D6D":"", transition: "all 0.3s"}}
          />
        )}
      </div>
      <div className="home__container__right__card__details">
        <div className="home__container__right__card__details__text">
          <p>{title}</p>
          <span>{description}</span>
        </div>
        <div
          className={`home__container__right__card__details__forwardIcon ${cardColor ? `home__container__right__card${cardNumber}__details__forwardIcon${cardNumber}` : ""}`}
          style={{transition: "all 0.3s"}}
        >
          <ArrowForwardOutlinedIcon
            className={`home__container__right__card__details__forwardIcon__icon ${cardColor ? `home__container__right__card${cardNumber}__details__forwardIcon${cardNumber}__icon${cardNumber}` : ""}`}
            style={{transition: "all 0.3s", borderRadius: "50%"}}
          />
        </div>
      </div>
    </Link>
  );
};

export default HomeCard;
