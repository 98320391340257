import React, { useEffect, useState } from "react";
import Page1 from "../NewAddProject/Page1";
import Page from "../../../components/Page";
import Page2 from "../NewAddProject/Page2";
import Page3 from "../NewAddProject/Page3";
import { pageAtom } from "../atoms/AddProjectAtomGlobal";
import { useAtom } from "jotai";
import "../../../sass/components/newInputForm/CarouselPage.scss";
import { useNavigate } from "react-router-dom";

const AddProjects = () => {
  const [page, setPage] = useAtom(pageAtom);  
  let element = null;
  const navigate = useNavigate();
 
  useEffect(()=>{
    if (localStorage.getItem("token") === null) {
      navigate("/login")
    }
  },[]);

  if (localStorage.getItem("token") !== null){
    element = <Page1 />;
    // switch(page) {
    //   case 0: {
    //     element = <Page1 />;
    //     break;
    //   }
    //   case 1: {
    //     element = <Page2 />;
    //     break;
    //   }
    //   case 2: {
    //     element = <Page3 />;
    //     break;
    //   }
    // }
  }

  // if (page == 0) {
  //   element = <Page1 />;
  // } else if (page == 1) {
  //   element = <Page2 />;
  // } else if (page == 2) {
  //   element = <Page3 />;
  // }

  return <Page>{element}</Page>;
};

export default AddProjects;
